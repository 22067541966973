'use strict'

export default {
  namespaced : true,
  state: {
    BussyLoading: true,
    lista: [],
  },
  mutations:{
    SET_LISTA(state, lista) {
      state.lista = lista.listado_papelera
      state.BussyLoading = false
      console.log("entro en papelera mutation");
    },
    SET_REMOVER_ITEM(state, item) {
      console.log('entro en mutation remover item')
      console.log(item)
      let index = 
      state.lista
        .findIndex(
          item_lista => item_lista.id === item.id_trash
          );
          console.log(index)
      if(index > -1){
        state.lista.splice(index, 1);
      }
      state.BussyLoading = false
    },
    SET_BUSSYLOADING(state, estado) {
      state.BussyLoading = estado
    }
  },
  actions: {
    async GET_PAPELERA({ commit }) {
      console.log("entro en papelera action");
      commit('SET_BUSSYLOADING', true)
      const res = await fetch(this._vm.$bambuvet + `/trash-papelera.php`, {
        method: 'GET',
        credentials: 'include'
      })
      const lista = await res.json()
      commit('SET_LISTA', lista)
    },
    async REMOVER_ITEM({ commit}, item){
      console.log('entro en action remover item')
      commit('SET_REMOVER_ITEM', item)
    },
  },
  getters: {
    lista_papelera: state =>{ return state.lista}
 } 
}