<template>
  <div id="app">
    <div id="nav" v-if="user_loged_in">
      <router-link to="/"><b-icon-house></b-icon-house> Inicio</router-link> 
      <router-link to="/animales/1" :class="navAnimalesActivo()" class="r-link"
        >Animales</router-link
      >
      <router-link 
      v-if="usuario.es_admin || puede_cm_animales"
      :to="{
            name: 'Animal nuevo',
            params: { nombre: 'Nuevo', id: '0', es_nuevo_prop: true },
          }" :class="navAnimalNuevoActivo()" class="r-link"
        >Nuevo</router-link
      >
      <router-link to="/tareas" 
        v-if="usuario.es_admin || puede_ver_tareas"
        class="r-link"
        >Tareas</router-link
      >
      
      <router-link to="/Agenda" 
        :class="navAgendaActivo()" class="r-link"
        >Agenda</router-link
      >
      
      <router-link to="/usuarios" v-if="user.cm_usuarios"  class="r-link">Usuarios</router-link>

      <router-link to="/papelera" v-if="user.es_admin"  class="r-link">Papelera</router-link>
      
      <router-link to="/logout" class="r-link">Salir</router-link>
    </div>
    <router-view :key="$route.path" />

    <footer>Bambú-vet</footer>
  </div>
</template>
<script>
import { mapGetters, mapActions, createNamespacedHelpers } from "vuex";
const { mapGetters: userMapGetters, mapActions: userMapActions } =
  createNamespacedHelpers("user");
const { mapGetters: baseMapGetters, mapActions: baseMapActions } =
  createNamespacedHelpers("base");
const { mapGetters: fechaslibMapGetters, mapActions: fechaslibMapActions } =
  createNamespacedHelpers("fechaslib");

  

export default {
  computed: {
    ...mapGetters(["url_site_local"]),
    ...userMapGetters(["usuario", 'user']),
    ...userMapGetters(["user_loged_in"]),
    ...userMapGetters(["nombre_usuario"]),
    ...userMapGetters(["puede_ver_tareas", "puede_aplicar_tareas", 'puede_cm_animales']),
    ...fechaslibMapGetters(["fechas_lib"]),
    ...baseMapGetters(["base"]),
    NavActivo() {
      return this.$route.name;
    },
    loginControl(){
      let kikout = this.user_loged_in ? false : true;
      if(kikout) this.redirigir();
      return kikout ? 'kikout' : 'granted'
    }
  },
  methods: {
    ...mapActions(["GET_GLOBALS"]),
    ...userMapActions(["GET_USER", "LOGIN_USER"]),
    ...fechaslibMapActions(["GET_LIB_FECHAS"]),
    ...baseMapActions(["GET_LISTAS_GENERAL"]),
    navAnimalesActivo() {
      if (
        [
          "animal",
          "Historial completo",
          "Historial Tratamientos",
          "Historial Tratamiento",
          "Historial visita",
        ].includes(this.NavActivo)
      )
        return "activo";
    },
    navAnimalNuevoActivo() {
      if (["Animal nuevo"].includes(this.NavActivo)) return "activo";
    },
    navAgendaActivo() {
      if (["Agenda semana"].includes(this.NavActivo)) return "activo";
    },
    async GetUser() {
      this.$http
        .get(this.$bambuvet + "/auth_user.php", {
          emulateJSON: true,
          withCredentials: true,
        })
        .then((res) => {
          //console.log(res.body);
          if (res.body) {
            this.LOGIN_USER(res.body);
            if(!res.body.user_loged_in){
              this.redirigir();
            }
          }
        });
    },
    redirigir(){
      if (this.user_loged_in != 1 && this.$route.name != "Login"){
        this.$router.push({ name: "Login" });
      }
      //else this.$router.push({ name: "Home" });
    },
    
  },
  mounted() {
    //this.GET_USER();
    //this.GET_SESION_INICIADA()
    this.GetUser();
    this.GET_GLOBALS();
    if (!this.user_loged_in && this.$route.name != "Login") {
      this.GET_GLOBALS();
      //this.redirigir();
    }
    
  },
  watch: {
    /**/
    usuario: function () {
      //console.log("cambia el user");
      if (this.user_loged_in) this.GET_LIB_FECHAS();
      if (this.user_loged_in) this.GET_LISTAS_GENERAL();
    },
    user_loged_in: function () {
      this.redirigir(); 
    },
  },
};
</script>
<style lang="scss">
$cian: #17a2b8;
$cian-hover: #57aebb;
.r-link::before {
  content: " | ";
  font-weight: normal;
}
.btn {
  border-radius: 0 !important;
}
.bg-cian {
  background: $cian !important;
  color: #fff !important;
}
.btn-cian,
.btn-info {
  background: $cian !important;
  color: #fff !important;
  &:hover, &.active {
    background: $cian-hover !important;
    color: #fff !important;
  }
}
.text-cian,
.text-info {
  color: $cian !important;
}
.btn-outline-info.cian {
  border-color: $cian !important;
  color: $cian;
  &:hover {
    background: $cian;
    color: #fff !important;
  }
}
.btn-danger{
  background: $red_danger;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  color: #444;

  footer {
    margin-top: 3rem;
    border-top: 2px solid $cian;
    padding: 1rem;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    &.router-link-exact-active,
    &.activo {
      color: #42b983;
    }
  }
}
</style>
