<template>
  <div v-if="puede_cm_actuaciones">
    <b-modal
      ref="modal-actuacion"
      v-model="show"
      :title="accion_form_prop == 'update' ? 'Modificar actuación' : 'Agregar actuación'"
      :hide-footer="true"
    >
      <ActuacionTipoForm
        v-if="form_actuacion_tipo_mostrar"
        :accion_form_prop="form_actuacion_tipo_accion"
        :id_ac_t_prop="formu.id_ac_t"
        v-on:nuevo_id="cambiarIdAct"
        v-on:cerrar="cerrarFormActuacionTipo()"
      ></ActuacionTipoForm>

      <ActuacionNombreForm
        v-if="form_actuacion_nombre_mostrar"
        :accion_form_prop="form_actuacion_nombre_accion"
        :id_ac_t_prop="formu.id_ac_t"
        :id_ac_prop="formu.id_ac"
        :actuaciones_tipos_prop="actuaciones_tipos_ordenadas"
        v-on:nuevo_id="cambiarIdAc"
        v-on:cerrar="cerrarFormActuacionNombre()"
      ></ActuacionNombreForm>

      <b-form ref="formu" @submit="onSubmit">
        <b-row>
          <b-col cols="auto">
            <b-form-group
              id="formu-fecha_ac-group"
              label="Fecha:"
              label-for="formu-fecha_ac"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-button variant="info" 
                    ><b-icon icon="calendar2-date" font-scale="1"></b-icon
                  ></b-button>
                </b-input-group-prepend>

                <b-form-input
                  id="formu-fecha_ac"
                  type="date"
                  v-model="formu.fecha_ac"
                  required
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <label for="id_visita">Visita</label>
            <b-form-select v-model="formu.id_visita" :options="listaVisitas" id="id_visita"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="formu-actuacion-tipo-group"
              label="Actuación:"
              label-for="form-actuacion-tipo"
              required
            >
              <b-input-group>
                <template #prepend v-if="puede_cm_actuaciones_tipos">
                  <b-dropdown variant="info">
                    <template #button-content>
                      <b-icon
                        icon="gear"
                        font-scale=".8"
                        style="margin-bottom: 0.2rem"
                      ></b-icon>
                    </template>
                    <b-dropdown-item-button
                      @click="abrirFormActuacionTipo('insert')"
                      ><b-icon
                        icon="plus-square"
                        font-scale="1"
                        variant="cian"
                      ></b-icon>
                      Agregar</b-dropdown-item-button
                    >
                    <b-dropdown-item-button
                      v-if="hayActuacionTipoSeleccionada"
                      @click="abrirFormActuacionTipo('update')"
                      ><b-icon
                        icon="pencil-square"
                        font-scale="1"
                        variant="cian"
                      ></b-icon>
                      Modificar</b-dropdown-item-button
                    >
                  </b-dropdown>
                </template>

                <b-form-select
                  id="form-actuacion-tipo"
                  v-if="base_animal_cargado"
                  v-model="formu.id_ac_t"
                  :options="actuaciones_tipos_ordenadas"
                  class="mb-3 custom-select"
                  value-field="id_ac_t"
                  text-field="nombre_ac_t"
                  disabled-field="notEnabled"
                  plain
                  required
                  @change="formu.id_ac = ''"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              v-if="formu.id_ac_t"
              id="formu-actuacion-group"
              label="Nombre:"
              label-for="form-actuacion"
            >
              <b-input-group>
                <template #prepend v-if="puede_cm_actuaciones_nombres">
                  <b-dropdown variant="info">
                    <template #button-content>
                      <b-icon
                        icon="gear"
                        font-scale=".8"
                        style="margin-bottom: 0.2rem"
                      ></b-icon>
                    </template>
                    <b-dropdown-item-button
                      @click="abrirFormActuacionNombre('insert')"
                      ><b-icon
                        icon="plus-square"
                        font-scale="1"
                        variant="cian"
                      ></b-icon>
                      Agregar</b-dropdown-item-button
                    >
                    <b-dropdown-item-button
                      v-if="hayActuacionNombreSeleccionada"
                      @click="abrirFormActuacionNombre('update')"
                      ><b-icon
                        icon="pencil-square"
                        font-scale="1"
                        variant="cian"
                      ></b-icon>
                      Modificar</b-dropdown-item-button
                    >
                  </b-dropdown>
                </template>

                <b-form-select
                  id="form-actuacion"
                  v-if="formu.id_ac_t"
                  v-model="formu.id_ac"
                  :options="filtroListaActuaciones"
                  class="mb-3  custom-select"
                  value-field="id_ac"
                  text-field="nombre_ac"
                  disabled-field="notEnabled"
                  plain
                  required
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col>
            <b-form-group
              id="formu-resultado"
              label="Resultado:"
              label-for="formu-resultado"
            >
              <b-form-input
                id="formu-resultado"
                v-model="formu.resultado"
                placeholder="Resultado"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="formu-observaciones"
              label="Observaciones:"
              label-for="formu-observaciones"
            >
              <b-form-textarea
                id="formu-observaciones"
                v-model="formu.observaciones_ac"
                placeholder="Observaciones"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-3">
            <b-form-group
              :class="formu.realizada == '1' ? 'text-success' : 'text-danger'"
              :label="
                formu.realizada == '1'
                  ? 'Actuación realizada'
                  : 'Actuación pendiente'
              "
              id="form-realizada-group"
            >
              <b-form-radio
                class="d-inline text-danger"
                value="0"
                v-model="formu.realizada"
              >
                &nbsp;Programar actuación</b-form-radio
              >
              &nbsp;
              <b-form-radio
                class="d-inline text-success"
                value="1"
                v-model="formu.realizada"
              >
                &nbsp;Actuación realizada</b-form-radio
              >
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between">
          <div>
            <b-button
              squared
              class="me-2 mt-3 w-auto"
              size="sm"
              type="submit"
              variant="primary"
              >Guardar</b-button
            >
            
            <b-button
              squared
              class="me-2 mt-3 w-auto"
              size="sm"
              @click="cancelar()"
              variant="secondary"
              >Cancelar</b-button
            >
          </div>
          <div>
            <b-button
              v-if="formu.id_ac_rel"
              squared
              class="mr-2  mt-3 w-auto ms-auto me-0"
              size="sm"
              @click="anular()"
              variant="danger"
              >Anular</b-button
            >
          </div>
        </div>


      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: animalMapGetters } =
  createNamespacedHelpers("animal");
const { mapGetters: historialMapGetters, mapActions: historialMapActions } =
  createNamespacedHelpers("historial");
const { mapGetters: baseMapGetters } = createNamespacedHelpers("base");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");

import ActuacionTipoForm from "@/components/ActuacionTipoForm";
import ActuacionNombreForm from "@/components/ActuacionNombreForm";
export default {
  name: "ActuacionForm",
  data() {
    return {
      show: true,
      formu: {
        id_ac_rel: 0,
        id_ac: "",
        id_ac_t: "",
        id_animal: 0,
        id_vet: 0,
        id_cv: 0,
        id_visita: 0,
        id_usuario: 0,
        id_med: 0,
        fecha_ac: "",
        observaciones_ac: "",
        resultado: "",
        realizada: 0,
      },
      form_actuacion_tipo_mostrar: false,
      form_actuacion_tipo_accion: "insert",
      form_actuacion_nombre_mostrar: false,
      form_actuacion_nombre_accion: "insert",
      actuaciones_tipos_ordenadas: [],
    };
  },
  components: {
    ActuacionTipoForm,
    ActuacionNombreForm,
  },
  props: {
    id_animal_prop: { default: 0 },
    id_visita_prop: { default: 0 },
    actuacion_modificar_activa_prop: { default: false },
    accion_form_prop: { default: "insert" },
    fecha_visita_prop: { default: false },
  },
  computed: {
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...userMapGetters(['puede_cm_actuaciones','puede_cm_actuaciones_tipos','puede_cm_actuaciones_nombres']),
    ...baseMapGetters({ actuaciones: "actuaciones_nombres" }),
    ...baseMapGetters({ actuaciones_tipos: "actuaciones_tipos" }),
    ...baseMapGetters({ base_animal_cargado: "base_animal_cargado" }),
    ...animalMapGetters({ animal_cargado: "animal_cargado" }),
    ...historialMapGetters(["historial"]),
    filtroListaActuaciones() {
      if (this.base_animal_cargado && this.formu.id_ac_t) {
        //return  this.actuaciones.map(this.filtrarAcciones);
        return this.actuaciones.filter(
          (obj) => obj.id_ac_t == this.formu.id_ac_t
        );
        //return true
      }
      return this.actuaciones;
    },
    hayActuacionTipoSeleccionada() {
      return this.formu.id_ac_t ? true : false;
    },
    hayActuacionNombreSeleccionada() {
      return this.formu.id_ac ? true : false;
    },
    nombreAcTipo(){
       let nombre_ac_t = this.actuaciones_tipos.find(
          (obj) => obj.id_ac_t == this.formu.id_ac_t
        );
        //return nombre_ac_t ;
        return nombre_ac_t ? nombre_ac_t.nombre_ac_t : false ;
    },
    nombreAc(){
       let nombre_ac = this.actuaciones.find(
          (obj) => obj.id_ac == this.formu.id_ac
        );
        //return nombre_ac ;
        return nombre_ac ? nombre_ac.nombre_ac : false ;
    },
    listaVisitas(){
      let arr = [{value: 0, text: 'Visita (ninguna)'}];
      this.historial.listas.visitas.forEach(
        element =>  
          arr.unshift(
            {value: element.id_visita, text: element.fecha_visita + ' - ' + element.tipo_visita}
            )
        )
      return arr 
    }
  },
  methods: {
    saludar(saludo) {
      alert(saludo);
    },
    cambiarIdAct(id) {
      this.formu.id_ac_t = id;
    },
    cambiarIdAc(id_act_t, id) {
      this.formu.id_ac_t = id_act_t;
      this.formu.id_ac = id;
    },
    cancelar() {
      //this.$refs['modal-actuacion'].hide()
      this.show = false;
    },
    anular(){
      console.log('borrar');
      let data = {
        id_item : this.formu.id_ac_rel,
        tipo_item: 'actuacion',
        id_user: this.usuario.id_usuario,
        accion: 'anular'
      };
      this.$http
        .post(
          this.$bambuvet +"/trash.php",
          JSON.stringify(data),
          {emulateJSON: true, withCredentials: true}
        )
        .then((res) => {
          let resp = res.body;
          console.log(resp)
          if(resp.msg=='ok'){
            console.log(resp.msg)
            let dat = {
              id_ac_rel: this.formu.id_ac_rel,
              fecha_ac: this.formu.fecha_ac
            }
            this.ACTUACION_ANULARH(dat);
            this.enviarCerrar();
          }
        });
    },
    onSubmit(event) {
      event.preventDefault();
      // alert(JSON.stringify(this.form));
      this.$http
        .post(
          this.$bambuvet +"/actuacion-post.php",
          JSON.stringify(this.formu),
          {emulateJSON: true, withCredentials: true}
        )
        .then((res) => {
          let resp = res.body;
          resp.nombre_ac_t = this.nombreAcTipo
          resp.nombre_ac = this.nombreAc
          
          if (this.accion_form_prop == "insert") {
            //this.ACTUACION_REALIZADA_INSERT(resp);
            this.ACTUACION_REALIZADA_INSERTH(resp); 
          } else {
            //this.ACTUACION_REALIZADA_UPDATE(resp);
            this.ACTUACION_REALIZADA_UPDATEH(resp);
          }
          this.enviarCerrar();
        });

      //this.$emit('update_animal_form', JSON.stringify(this.formu))
    },
    filtrarAcciones(obj) {
      if (obj.id_ac_t == this.formu.id_ac_t) return obj;
    },
    enviarCerrar() {
      this.$emit("cerrar");
    },
    abrirFormActuacionTipo(accion) {
      this.form_actuacion_tipo_accion = accion;
      this.form_actuacion_tipo_mostrar = true;
    },
    cerrarFormActuacionTipo() {
      this.form_actuacion_tipo_mostrar = false;
    },
    abrirFormActuacionNombre(accion) {
      this.form_actuacion_nombre_accion = accion;
      this.form_actuacion_nombre_mostrar = true;
    },
    cerrarFormActuacionNombre() {
      this.form_actuacion_nombre_mostrar = false;
    },
    cargarFormuDeProp() {
      if (this.actuacion_modificar_activa_prop != false) {
        let formu = Object.keys(this.formu);
        formu.forEach((element) => {
          this.formu[element] = this.actuacion_modificar_activa_prop[element];
        });
      }
      if(this.fecha_visita_prop) this.formu.fecha_ac = this.fecha_visita_prop
    },
    cargarActuacionesTiposOrdenadas() {
      if (this.actuaciones_tipos) {
        let items=[]

        let acs = Object.keys(this.actuaciones_tipos);

        acs.forEach((element) => {
          items[element] =
            this.actuaciones_tipos[element];
        });

        items.sort(function (a, b) {
          if (a.nombre_ac_t > b.nombre_ac_t) {
            return 1;
          }
          if (a.nombre_ac_t < b.nombre_ac_t) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.actuaciones_tipos_ordenadas = items
      }
    },
    ...historialMapActions([
      "ACTUACION_REALIZADA_INSERTH",
      'ACTUACION_REALIZADA_UPDATEH',
      'ACTUACION_ANULARH'
      ]),/*
    ...animalMapActions([
      "ACTUACION_REALIZADA_INSERT",
      "ACTUACION_REALIZADA_UPDATE",
    ]),*/
  },
  mounted() {
    this.formu.id_animal = this.id_animal_prop;
    this.formu.id_visita = this.id_visita_prop;
    this.cargarFormuDeProp();
    this.cargarActuacionesTiposOrdenadas();

    this.$root.$on("bv::modal::hide", () => {
      this.enviarCerrar();
    });
  },
  watch: {
    id_animal_prop: function () {
      this.formu.id_animal = this.id_animal_prop;
    },
    id_visita_prop: function () {
      this.formu.id_visita = this.id_visita_prop;
    },
    actuaciones_tipos: function(){
      this.cargarActuacionesTiposOrdenadas();
    }
  },
};
</script>
<style lang="scss" scoped>
</style>