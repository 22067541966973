<template>
  <div>
    historial visitas lista 
      <div v-if="id_animal_prop && visitas.length > 0">
        <Visita
            v-for="(visi, index) in visitas"
            :key="index"
            :visita_prop="visi"
            :id_visita_prop="visi.id_visita"
            :id_animal_prop="visi.id_animal"
            :mostrar_edicion_prop="false"
            :mostrar_agregar_actuacion_prop="false"
            :mostrar_agregar_tratamiento_prop="false"
            :mostrar_edicion_tratamiento_prop="false"
            :mostrar_agregar_doc_prop="false"
        ></Visita>
      </div>
  </div>
</template>

<script>
import Visita from "@/components/Visita";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: historialMapGetters, mapActions: historialMapActions } =
  createNamespacedHelpers("historial");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
export default {
  name: "HistorialVisitasLista",
  components: {
    Visita,
  },
  props: {
    id_animal_prop: { default: 0 },
  },
  methods: {
    ...historialMapActions(["GET_HISTORIAL_COMPLETO"]),
  },
  created: function () {
    if (this.id_animal_prop) this.GET_HISTORIAL_COMPLETO(this.id_animal_prop);
  },
  computed: {
    ...userMapGetters(["user","user_loged_in","usuario", "nombre_usuario"]),
    ...historialMapGetters(["historial",'visitas']),
    ...historialMapGetters(["nombre_animal"]),
  },
};
</script>

<style>
</style>