<template>
  <div class="ficha-propiedades">
    <b-row>
      <b-col> <span class="label">Nombre:</span> {{ animal.nombre }} </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <span class="label">Especie:</span> {{ especie_nombre }}
      </b-col>
      <b-col cols="auto">
        <span class="label">Tamaño:</span> {{ tamanio_nombre }}
      </b-col>
      <b-col cols="auto">
        <span class="label">Sexo:</span> {{ sexo_nombre }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <span class="label">F. nacimiento:</span> {{ fecha_nacimiento_mostrar }}
      </b-col>
      <b-col cols="auto">
        <span class="label">Edad:</span> {{ edad_calc }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <span class="label">Microchip:</span> {{ animal.microchip }}
      </b-col>
      <b-col cols="auto">
        <span class="label">Peso:</span> {{ animal.peso }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <span class="label">F. entrada:</span> {{ fecha_entrada_mostrar }}
      </b-col>
    </b-row>
    <b-row v-if="animal.fecha_salida">
      <b-col cols="auto">
        <span class="label">F. salida:</span> {{ fecha_salida_mostrar }}
      </b-col>
    </b-row>
    <b-row v-if="animal.fallecido">
      <b-col cols="auto">
        <span class="label">Fallecido:</span> {{ fecha_salida_mostrar }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: animalMapGetters } = createNamespacedHelpers("animal");
const { mapGetters: baseMapGetters } = createNamespacedHelpers("base");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
export default {
  name: "AnimalCaracteristicas",
  data() {
    return {
      modo_form: false,
    };
  },
  props: {
    animal: Object,
    modo_form_prop: {default: false}
  },
  computed: {
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...baseMapGetters({ especies: "especies" }),
    ...baseMapGetters({ tamanios: "tamanios" }),
    ...baseMapGetters({ sexos: "sexos" }),
    ...baseMapGetters({ base_animal_cargado: "base_animal_cargado" }),
    ...animalMapGetters({ animal_cargado: "animal_cargado" }),
    sexo_nombre() { 
      if (this.animal_cargado && this.base_animal_cargado) {
        let sex = this.sexos.find((sexo) => sexo.value == this.animal.sexo_id)
        if(sex) return sex.text;
        else return ''
      } else return "";
    },
    especie_nombre() {
      if (this.animal_cargado && this.base_animal_cargado) {
        let especie_ = this.especies.find(
          (especie) => especie.value == this.animal.especie_id
        );
        if(especie_) return especie_.text
        else return false
      } 
      else return "";
    },
    tamanio_nombre() {
      if (this.animal_cargado && this.base_animal_cargado) {
        let tam =  this.tamanios.find(
          (tamanio) => tamanio.value == this.animal.tamanio_id
        )
        if(tam) return tam.text;
        else return ''
      } else return "";
    },
    edad_calc(){
      if(this.animal){
        let f = this.dateDiff(this.animal.fecha_nacimiento);
        if(f){
          let edad_arr = [], anios='', meses='', dias='';
          if(f.years > 0){
            anios = f.years + " años";edad_arr.push(anios)
          } 
          if(f.months > 0){
            meses = f.months + " meses"; edad_arr.push(meses)
          }
          if(f.days == 1) dias = f.days + " día"
          if(f.days > 1) dias = f.days + " días"
          if(dias != '') edad_arr.push(dias)
          let edad = edad_arr.join(', ')
          return edad
        } else return ''
      } else return ''
    },
    fecha_entrada_mostrar(){
      return this.animal.fecha_entrada ? this.$root.fechaInvertir(this.animal.fecha_entrada) : this.animal.fecha_entrada
    },
    fecha_nacimiento_mostrar(){
      return this.animal.fecha_nacimiento ? this.$root.fechaInvertir(this.animal.fecha_nacimiento) : this.animal.fecha_nacimiento
    },
    fecha_salida_mostrar(){
      return this.animal.fecha_salida ? this.$root.fechaInvertir(this.animal.fecha_salida) : this.animal.fecha_salida
    },
  },
  methods: {
    dateDiff(date) {
        if(date){
          date = date.split('-');
          var today = new Date();
          var year = today.getFullYear();
          var month = today.getMonth() + 1;
          var day = today.getDate();
          var yy = parseInt(date[0]);
          var mm = parseInt(date[1]);
          var dd = parseInt(date[2]);
          var years, months, days;
          // months
          months = month - mm;
          if (day < dd) {
              months = months - 1;
          }
          // years
          years = year - yy;
          if (month * 100 + day < mm * 100 + dd) {
              years = years - 1;
              months = months + 12;
          }
          // days
          days = Math.floor((today.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));
          return {
              years: years,
              months: months,
              days: days
          };
        }
    }
  },
  created: function () {},
};
</script>

<style lang="scss" scoped>


.bg-info, .btn-info{background: #17a2b8 !important; color: #fff;}
.ficha-propiedades {
  min-height: 200px;
  .label {
    font-weight: 600;
  }
  dt {
    margin-right: 1rem;
    clear: left;
    float: left;
  }
  .row {
    padding-bottom: 3px;
    padding-top: 3px;
  }
}
button {
  color: #fff;
}
</style>