<template>
  <div v-if="puede_cm_visitas">
    <b-modal
      size="md"
      ref="modal-visita"
      v-model="show"
      :title="titulo_modal_prop"
      :hide-footer="true"
    >
      <VisitaCentroVetForm
        v-if="form_centro_vet_mostrar"
        :accion_form_prop="form_centro_vet_accion"
        :id_cv_prop="formu.id_cv"
        v-on:nuevo_id="cambiarIdCentroVet"
        v-on:cerrar="cerrarFormCentroVet()"
      ></VisitaCentroVetForm>

      <VisitaVetForm
        v-if="form_vet_mostrar"
        :accion_form_prop="form_vet_accion"
        :id_vet_prop="formu.id_vet"
        :nombre_vet_prop="formu.nombre_vet"
        :id_cv_prop="formu.id_cv"
        v-on:nuevo_id="cambiarIdVet"
        v-on:cerrar="cerrarFormVet()"
      ></VisitaVetForm>

      <form @submit="onSubmit">
        <div class="row">
          <div class="col-auto mb-2">
            <div class="custom-control custom-radio">
              <input
                type="radio"
                v-model="formu.estado"
                id="programada"
                value="programada"
                class="custom-control-input"
              />
              <label class="custom-control-label" for="programada">
                <span v-if="agregar_visita_prop">&nbsp; Programar visita</span>
                <span v-else>&nbsp; Visita programada</span>
              </label>
            </div>
            <div class="custom-control custom-radio">
              <input
                type="radio"
                v-model="formu.estado"
                id="realizada"
                value="realizada"
                class="custom-control-input"
              />
              <label class="custom-control-label" for="realizada">
                &nbsp; Visita realizada
              </label>
            </div>
          </div>

          <div class="col-auto mb-2">
            <input
              type="date"
              aria-label="fecha_visita"
              class="form-control bg-cian text-white"
              v-model="formu.fecha_visita"
              required
            />
          </div>

          <div class="col-auto mb-2">
            <select
              class="form-control custom-select"
              v-model="formu.tipo_visita"
              required="true"
              plain
            >
              <option value="" disabled>Selecciona</option>
              <option v-for="(tipo, index) in tipos_visita" :key="index">
                {{ tipo }}
              </option>
            </select>
          </div>
        </div>
        <!--row-->

        <div class="row">
          <div class="col-sm-6 mb-2">
            <label for="cv">Centro veterinario</label>
            <b-input-group>
              <template #prepend v-if="puede_cm_centros_vet">
                <b-dropdown variant="info">
                  <template #button-content>
                    <b-icon
                      icon="gear"
                      font-scale=".8"
                      style="margin-bottom: 0.2rem"
                    ></b-icon>
                  </template>
                  <b-dropdown-item-button @click="abrirFormCentroVet('insert')"
                    ><b-icon
                      icon="plus-square"
                      font-scale="1"
                      variant="cian"
                    ></b-icon>
                    Agregar</b-dropdown-item-button
                  >
                  <b-dropdown-item-button
                    v-if="hayCentroVetSeleccionado"
                    @click="abrirFormCentroVet('update')"
                    ><b-icon
                      icon="pencil-square"
                      font-scale="1"
                      variant="cian"
                    ></b-icon>
                    Modificar</b-dropdown-item-button
                  >
                </b-dropdown>
              </template>
              <select
                class="form-control custom-select"
                v-model="formu.id_cv"
                id="cv"
              >
                <option value="0" class="text-muted bg-light">Ninguno</option>
                <option
                  v-for="(cv, index) in centros_vet"
                  :value="cv.id_cv"
                  :key="index"
                >
                  {{ cv.nombre_cv }}
                </option>
              </select>
            </b-input-group>
          </div>

          <div class="col mb-2">
            <label for="vet">Veterinario</label>
            <b-input-group>
              <template #prepend v-if="puede_cm_veterinarios">
                <b-dropdown variant="info">
                  <template #button-content>
                    <b-icon
                      icon="gear"
                      font-scale=".8"
                      style="margin-bottom: 0.2rem"
                    ></b-icon>
                  </template>
                  <b-dropdown-item-button @click="abrirFormVet('insert')"
                    ><b-icon
                      icon="plus-square"
                      font-scale="1"
                      variant="cian"
                    ></b-icon>
                    Agregar</b-dropdown-item-button
                  >
                  <b-dropdown-item-button
                    v-if="hayVetSeleccionado"
                    @click="abrirFormVet('update')"
                    ><b-icon
                      icon="pencil-square"
                      font-scale="1"
                      variant="cian"
                    ></b-icon>
                    Modificar</b-dropdown-item-button
                  >
                </b-dropdown>
              </template>
              <select
                class="form-control custom-select"
                v-model="formu.id_vet"
                id="vet"
              >
                <option value="0" class="text-muted bg-light">Ninguno</option>
                <option
                  v-for="(vet, index) in filtroListaVets"
                  :value="vet.id_vet"
                  :key="index"
                >
                  {{ vet.nombre_vet }}
                </option>
              </select>
            </b-input-group>
          </div>
        </div>

        <div class="row" v-if="formu.estado == 'realizada'">
          <div class="form-floating mb-3 mt-3">
            <textarea
              class="form-control"
              placeholder="Diagnóstico"
              id="diagnostico"
              v-model="formu.diagnostico"
            ></textarea>
            <label for="diagnostico" class="ms-2">Diagnóstico</label>
          </div>

          <div class="form-floating mb-3">
            <textarea
              class="form-control"
              placeholder="Observaciones"
              id="observaciones"
              v-model="formu.observaciones"
            ></textarea>
            <label for="observaciones" class="ms-2">Observaciones</label>
          </div>


          <div class="form-floating mb-1">
            <textarea
              class="form-control"
              placeholder="Comentarios"
              id="comentarios"
              v-model="formu.comentarios"
            ></textarea>
            <label for="comentarios" class="ms-2">Comentarios</label>
          </div>



        </div>

        <hr />
        <div class="row d-flex justify-content-end">
          <div class="col-auto">
            <button type="submit" class="btn btn-cian">Guardar</button>
          </div>
          <div class="col-auto">
            <button type="button" @click="cancelar()" class="btn btn-secondary">
              Cancelar
            </button>
          </div>
          <div class="col-auto">
            <b-button
              v-if="formu.id_visita"
              squared
              class="w-auto ms-auto me-0"
              @click="anular()"
              variant="danger"
              >Anular</b-button
            >
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: baseMapGetters } = createNamespacedHelpers("base");
const { mapGetters: animalMapGetters } = createNamespacedHelpers("animal");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
const { mapGetters: historialMapGetters, mapActions: historialMapActions } =
  createNamespacedHelpers("historial");
import VisitaCentroVetForm from "@/components/VisitaCentroVetForm";
import VisitaVetForm from "@/components/VisitaVetForm";
export default {
  name: "VisitaForm",
  data() {
    return {
      show: true,
      formu: {
        id_visita: 0,
        id_animal: 0,
        id_vet: 0,
        id_cv: 0,
        tipo_visita: "",
        fecha_visita: "",
        hora: "",
        observaciones_visita: "",
        diagnostico: "",
        comentarios: "",
        estado: "programada",
        updated: "",
      },
      tipos_visita: ["Revision", "Rutinaria", "Seguimiento", "Urgencia"],
      form_centro_vet_mostrar: false,
      form_centro_vet_accion: "insert",
      form_vet_mostrar: false,
      form_vet_accion: "insert",
    };
  },
  components: {
    VisitaCentroVetForm,
    VisitaVetForm,
  },
  props: {
    visita_prop: { default: false },
    id_animal_prop: { default: 0 },
    id_visita_prop: { default: 0 },
    agregar_visita_prop: { default: 0 },
    visita_modificar_activa_prop: { default: false },
    accion_form_prop: { default: "insert" },
    titulo_modal_prop: { default: "Agregar visita" },
  },
  methods: {
    cargarFormuDeProp() {
      if (this.visita_modificar_activa_prop != false) {
        let formu = Object.keys(this.formu);
        formu.forEach((element) => {
          this.formu[element] = this.visita_prop[element];
        });
      }
    },
    cancelar() {
      //this.$refs['modal-actuacion'].hide()
      this.show = false;
    },
    anular() {
      console.log("borrar");
      let data = {
        id_item: this.formu.id_visita,
        tipo_item: "visita",
        id_user: this.usuario.id_usuario,
        accion: "anular",
      };
      this.$http
        .post(this.$bambuvet + "/trash.php", JSON.stringify(data), {
          emulateJSON: true,
          withCredentials: true,
        })
        .then((res) => {
          let resp = res.body;
          console.log(resp);
          if (resp.msg == "ok") {
            console.log(resp.msg);
            let dat = {
              id_visita: this.formu.id_visita,
              fecha_visita: this.formu.fecha_visita,
            };
            this.VISITA_ANULARH(dat);
            this.enviarCerrar();
          }
        });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$http
        .post(this.$bambuvet + "/visita-post.php", JSON.stringify(this.formu), {
          emulateJSON: true,
          withCredentials: true,
        })
        .then((res) => {
          //console.log(res.body);
          if (this.accion_form_prop == "insert") {
            this.VISITA_INSERTH(res.body);
          } else {
            this.VISITA_UPDATEH(res.body);
          }
          this.enviarCerrar();
        });
    },
    enviarCerrar() {
      this.$emit("cerrar");
    },
    ...historialMapActions(["VISITA_INSERTH", "VISITA_UPDATEH",'VISITA_ANULARH']) /*
    ...animalMapActions([
      "VISITA_INSERT",
      "VISITA_UPDATE",
    ]),*/,
    abrirFormCentroVet(accion) {
      this.form_centro_vet_accion = accion;
      this.form_centro_vet_mostrar = true;
    },
    cerrarFormCentroVet() {
      this.form_centro_vet_mostrar = false;
    },
    abrirFormVet(accion) {
      this.form_vet_accion = accion;
      this.form_vet_mostrar = true;
    },
    cerrarFormVet() {
      this.form_vet_mostrar = false;
    },
    cambiarIdCentroVet(id) {
      this.formu.id_cv = id;
    },
    cambiarIdVet(id_cv, id) {
      this.formu.id_cv = id_cv;
      this.formu.id_vet = id;
    },
  },
  created() {
    this.cargarFormuDeProp();
  },
  computed: {
    ...userMapGetters(["user_loged_in", "usuario", "nombre_usuario"]),
    ...userMapGetters([
      "puede_cm_visitas",
      "puede_cm_centros_vet",
      "puede_cm_veterinarios",
    ]),
    ...animalMapGetters({ animal_cargado: "animal_cargado" }),
    ...historialMapGetters(["historial"]),
    ...baseMapGetters({ base_animal_cargado: "base_animal_cargado" }),
    ...baseMapGetters({ centros_vet: "centros_vet" }),
    ...baseMapGetters({ veterinarios: "veterinarios" }),
    filtroListaVets() {
      if (this.base_animal_cargado && this.formu.id_cv != 0) {
        return this.veterinarios.filter((obj) => obj.id_cv == this.formu.id_cv);
        //return true
      }
      return this.veterinarios;
    },
    hayCentroVetSeleccionado() {
      return this.formu.id_cv ? true : false;
    },
    hayVetSeleccionado() {
      return this.formu.id_vet ? true : false;
    },
  },
  mounted() {
    this.formu.id_animal = this.id_animal_prop;
    this.formu.id_visita = this.id_visita_prop;
    this.cargarFormuDeProp();
    this.$root.$on("bv::modal::hide", () => {
      this.enviarCerrar();
    });
  },
  watch: {
    visita_prop: function () {
      this.cargarFormuDeProp();
    },
    visita_modificar_activa_prop: function () {
      this.cargarFormuDeProp();
    },
    id_animal_prop: function () {
      this.formu.id_animal = this.id_animal_prop;
    },
  },
};
</script>