<template>
  <div>
    <b-container id="ficha">
      <Breadcrumb :items_prop="itemsBreadcrumb"></Breadcrumb>
      <b-row class="justify-content-md-center">
        <b-col lg="5">
          <b-row class="sticky-md-top">
            <b-col md="12" class="mt-1">
              <h1 class="h2">Ficha de {{ animal.nombre }}</h1>
            </b-col>
            <b-col xl="5">
              <ImageUpload
                v-if="ShowImageUpload && puede_cm_animales && !es_nuevo_prop"
                :id_animal_prop="id"
                v-on:cerrar="cerrarImageUpload()"
                v-on:enviarImagen="enviarImagenUpload"
              ></ImageUpload>
              <img
                v-bind:src="src_imagen"
                class="foto-carnet mb-2 img-fluid"
                :alt="animal.nombre"
                @click="abrirImageUpload()"
              />
              <br />
            </b-col>
            <b-col v-if="!es_nuevo_prop" col style="text-align: left">
              <router-link
                v-if="id"
                class="btn btn-info btn-sm mb-2 me-2"
                :to="{
                  name: 'Historial completo',
                  params: {
                    id_animal: id,
                  },
                }"
                >Ver Historial</router-link
              >
              <b-button
                v-if="puede_cm_animales && !es_nuevo_prop"
                class="mb-2"
                squared
                size="sm"
                variant="info"
                :pressed.sync="modo_form"
              >
                {{ modo_form ? "Volver" : "Modificar datos" }}
              </b-button>

              <AnimalCaracteristicas
                
                :animal="animal"
                v-on:cambiar_modo_form="modo_form = $event"
                :modo_form_prop="modo_form"
              >
              </AnimalCaracteristicas>
            </b-col>
            <b-col sm="12"> </b-col>
          </b-row>
        </b-col>
        <b-col lg="7 mt-1">
          <AnimalCaracteristicasForm
            :animal="animal"
            v-if="puede_cm_animales && modo_form"
            v-on:cambiar_modo_form="modo_form = $event"
            v-on:update_animal_form="UPDATE_ANIMAL"
            v-on:insert_animal_form="ADD_ANIMAL"
            v-on:update_animales_listado="UPDATE_NOMBRE_ANIMAL"
            v-on:cerrar_caracteristicas_form="modo_form = false"
            :es_nuevo_prop="es_nuevo_prop"
          >
          </AnimalCaracteristicasForm>
          <div v-if="!es_nuevo_prop">
            <div v-if="sistema_archivos">
              <h2>Documentos</h2>
            <DocsLista
              :docs_prop="docs"
              :id_animal_prop="animal.id"
            ></DocsLista>
            </div>
            

            <b-row class="mt-2 heading_underline" alignv="top">
              <b-col class="d-flex">
                <h2 class="h4">Actividad</h2>
              </b-col>
              <b-col cols="auto">
                <b-dropdown :text="filtro_actividades" right>
                  <b-dropdown-item @click="filtroActividades('Todas')"
                    >Todas</b-dropdown-item
                  >
                  <b-dropdown-item @click="filtroActividades('Actuaciones')"
                    >Actuaciones</b-dropdown-item
                  >
                  <b-dropdown-item @click="filtroActividades('Tratamientos')"
                    >Tratamientos</b-dropdown-item
                  >
                  <b-dropdown-item @click="filtroActividades('Visitas')"
                    >Visitas</b-dropdown-item
                  >
                </b-dropdown>
              </b-col>
            </b-row>
            

            <ActuacionesLista
              v-if="
                mostrarActuaciones &&
                base_animal_cargado &&
                actuacionesRealizadas &&
                actuaciones_tipos
              "
              :actuaciones_realizadas_prop="actuacionesRealizadas"
            ></ActuacionesLista>

            <TratamientosLista v-if="mostrarTratamientos"></TratamientosLista>

            <VisitasLista v-if="mostrarVisitas"></VisitasLista>
          </div>

          <div v-if="animal.notas_internas">
            <h2 class="h4">Notas internas</h2>
            <div v-html="animal.notas_internas"></div>
          </div>
        </b-col>
      </b-row>
      <Loading :bussy_prop="BussyLoading"></Loading>
    </b-container>
  </div>
</template>

<script>
import AnimalCaracteristicas from "@/components/AnimalCaracteristicas";
import AnimalCaracteristicasForm from "@/components/AnimalCaracteristicasForm";
import ActuacionesLista from "@/components/ActuacionesLista";
import VisitasLista from "@/components/VisitasLista";
import TratamientosLista from "@/components/TratamientosLista";
import DocsLista from "@/components/DocsLista";

import { mapGetters, createNamespacedHelpers } from "vuex";

const { mapGetters: animalMapGetters, mapActions: animalMapActions } =
  createNamespacedHelpers("animal");
const { mapGetters: historialMapGetters, mapActions: historialMapActions } =
  createNamespacedHelpers("historial");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
const { mapGetters: baseMapGetters } = createNamespacedHelpers("base");
const { mapActions: animalesMapActions } = createNamespacedHelpers("animales");
import Breadcrumb from "@/components/Breadcrumb";
import ImageUpload from "@/components/ImageUpload";
import Loading from "@/components/Loading";
export default {
  name: "Animal",
  data() {
    return {
      modo_form: false,
      doc_title: "Ficha ",
      ShowImageUpload: false,
      BussyLoading: false,
      filtro_actividades: "Todas",
    };
  },
  components: {
    AnimalCaracteristicas,
    AnimalCaracteristicasForm,
    ActuacionesLista,
    VisitasLista,
    TratamientosLista,
    DocsLista,
    Breadcrumb,
    ImageUpload,
    Loading,
  },
  props: {
    nombre: String,
    id: String,
    es_nuevo_prop: { default: false },
  },
  created: function () {
    document.title = this.docTitle;
  },
  mounted() {
    if (!this.es_nuevo_prop) {
      if (this.id && this.animal.id != this.id) {
        this.GET_ANIMAL(this.id);
      }
      if (this.id && this.historial.id_animal != this.id) {
        this.GET_HISTORIAL_COMPLETO(this.id);
      }
    }
    if (this.es_nuevo_prop && this.puede_cm_animales) {
      this.RESET_ANIMAL();
      this.modo_form = true;
    }
  },
  computed: {
    ...userMapGetters(["user_loged_in", "usuario", "nombre_usuario"]),
    ...userMapGetters([
      "puede_cm_animales",
      "puede_cm_tratamientos",
      "puede_upload_docs",
      "sistema_archivos"
    ]),
    ...animalMapGetters(["animal", "docs"]),
    //...animalMapGetters({ actuaciones_realizadas: "actuaciones_realizadas" }),
    ...animalMapGetters({ animal_cargado: "animal_cargado" }),
    ...historialMapGetters(["historial"]),
    ...baseMapGetters({ base_animal_cargado: "base_animal_cargado" }),
    ...baseMapGetters({ especies: "especies" }),
    ...baseMapGetters({ sexos: "sexos" }),
    ...baseMapGetters({ tamanios: "tamanios" }),
    ...baseMapGetters({ actuaciones_tipos: "actuaciones_tipos" }),
    ...baseMapGetters({ actuaciones_nombres: "actuaciones_nombres" }),
    ...mapGetters(["url_site_local", "url_foto_default"]),
    ...userMapGetters(["user"]),
    NombreAnimal() {
      return this.animal.nombre;
    },
    src_imagen() {
      if (this.animal.id_foto != "0") {
        let path =
          this.usuario.url_web + "/imas/animales/" + "_" + this.id + "/";
        return path + "thumbnail_square.jpg?" + this.animal.id_foto;
      } else return this.url_foto_default;
    },
    actuacionesRealizadas() {
      return this.historial.listas.actuaciones;
    },
    docTitle() {
      if (this.animal.nombre)
        return this.doc_title + " de " + this.animal.nombre;
      else return this.doc_title;
    },
    colsFoto() {
      return this.modo_form ? "12" : "5";
    },
    itemsBreadcrumb() {
      var items = [
        {
          text: "Animales",
          to: "Animales",
        },
        {
          text: this.nombre,
          to: "animal",
          params: { nombre: this.nombre_animal, id: this.id_animal },
        },
      ];
      return items;
    },
    mostrarActuaciones() {
      return this.filtro_actividades == "Todas" ||
        this.filtro_actividades == "Actuaciones"
        ? true
        : false;
    },
    mostrarTratamientos() {
      return this.filtro_actividades == "Todas" ||
        this.filtro_actividades == "Tratamientos"
        ? true
        : false;
    },
    mostrarVisitas() {
      return this.filtro_actividades == "Todas" ||
        this.filtro_actividades == "Visitas"
        ? true
        : false;
    },
    mostrarCaracteristicasForm(){
      return this.puede_cm_animales && this.modo_form
    },
  },
  methods: {
    ...animalMapActions([
      "GET_ANIMAL",
      "UPDATE_ANIMAL",
      "GET_LISTAS_GENERAL",
      "FOTO_UPDATE",
      "RESET_ANIMAL",
    ]),
    ...historialMapActions(["GET_HISTORIAL_COMPLETO"]),
    ...animalesMapActions([
      "ADD_ANIMAL",
      "UPDATE_NOMBRE_ANIMAL",
      "UPDATE_FOTO_ANIMAL",
    ]),
    filtroActividades(valor = "Todas") {
      this.filtro_actividades = valor;
    },
    abrirImageUpload() {
      if (this.puede_cm_animales && !this.es_nuevo_prop) {
        this.ShowImageUpload = true;
      }
    },
    cerrarImageUpload() {
      this.ShowImageUpload = false;
    },
    enviarImagenUpload(data) {
      //console.log(data)
      this.BussyLoading = true;
      let postData = JSON.stringify(data);
      this.$http
        .post(this.$bambuvet + `/imagen-upload.php`, postData, {
          emulateJSON: true,
          withCredentials: true,
        })
        .then((res) => {
          //console.log(res.body);
          let respuesta = res.body;
          // console.log(respuesta);
          this.FOTO_UPDATE(respuesta);
          this.UPDATE_FOTO_ANIMAL({ id_foto: respuesta, id: this.id });
          this.cerrarImageUpload();
          this.BussyLoading = false;
          if (respuesta.msg == "ok") {
            //console.log("ola");
          }
        });
    },
  },
  watch: {
    NombreAnimal: function () {
      document.title = this.docTitle;
    },
    puede_cm_animales: function(){
      if (this.es_nuevo_prop && this.puede_cm_animales) {
      this.RESET_ANIMAL();
      this.modo_form = true;
    }
    }
  },
};
</script>

<style lang="scss" >
.heading_underline {
  border-bottom: 1px solid $cian;
  margin-bottom: .5rem !important;
  h2{
    padding-bottom: .4rem !important;
    margin-bottom: 0 !important;
    border: 0 none !important;
  }

}
#fileInput {
  display: none !important;
}

.bg-info,
.btn-info {
  background: #17a2b8 !important;
  color: #fff;
}
#ficha {
  text-align: left !important;
  min-height: 550px;
  h1,
  h2 {
    border-bottom: 1px solid $cian;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    color: $cian;
    font-size: 30px;
  }
  h2 {
    font-size: 25px;
    &.h4 {
      line-height: 36px;
    }
  }
}
</style>