<template>
  <div>
    <VisitaForm
      v-if="animal_cargado && agregar_visita "
      :id_animal_prop="id_animal"
      :agregar_visita_prop="agregar_visita"
      v-on:cerrar="agregar_visita = false"
    ></VisitaForm>


    <b-row class="mt-2 " align-v="end">
    <b-col class="d-flex">
      <h3 class="h5">Visitas</h3>
    </b-col>
    <b-col  cols="auto"  v-if="puede_cm_visitas">
      <b-button
      size="sm"
      variant="outline-info"
      class="mb-2 cian"
      :pressed.sync="agregar_visita"
      ><b-icon-plus-square-fill font-scale="1"></b-icon-plus-square-fill>
      Agregar visita</b-button
    >
    </b-col>
  </b-row>
    <div v-if="animal_cargado && historial.listas.visitas.length > 0">
      <visita
        v-for="(visita, fecha_visita) in historial.listas.visitas"
        :key="fecha_visita"
        :visita_prop="visita"
        :id_animal_prop="visita.id_animal"
        :id_visita_prop="visita.id_visita"
        :agregar_visita_prop="agregar_visita"
      >
      </visita>
    </div>
    <p v-else>No se encontraron visitas.</p>

    
  </div>
</template>

<script>
import Visita from "@/components/Visita";
import VisitaForm from "@/components/VisitaForm";
import { createNamespacedHelpers } from "vuex";

const { mapGetters: animalMapGetters } = createNamespacedHelpers("animal");
const { mapGetters: historialMapGetters } =
  createNamespacedHelpers("historial");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");

export default {
  name: "VisitasLista",
  data(){
    return{
      agregar_visita: false
    }
  },
  components: {
    Visita,
    VisitaForm
  },
  computed: {
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...userMapGetters(['puede_cm_visitas']),
    ...historialMapGetters(["historial"]),
    //...animalMapGetters({ visitas: "visitas" }),
    ...animalMapGetters( {id_animal: "id_animal"}),
    ...animalMapGetters( {animal_cargado: "animal_cargado"}),
  },
};
</script>