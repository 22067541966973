<template>
  <div v-if="puede_upload_docs && mostrar_agregar_doc_prop">
    <b-button
      size="sm"
      variant="outline-info"
      class="mb-2 cian"
      :pressed.sync="agregar_doc"
      ><b-icon-file-plus font-scale="1"></b-icon-file-plus>
      Agregar Documento</b-button
    >
    <b-modal
      size="lg"
      ref="modal-doc"
      v-model="agregar_doc"
      title="Agregar documento"
      :hide-footer="true"
    >
      <FileUpload
        :id_animal_prop="id_animal_prop"
        :id_visita_prop="id_visita_prop"
        v-on:cerrar="agregar_doc = false"
      ></FileUpload>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
import FileUpload from "@/components/FileUpload";
export default {
  name: "DocForm",
  data() {
    return {
      agregar_doc: false,
    };
  },
  props: {
    id_animal_prop: { default: false },
    id_visita_prop: { default: 0 },
    mostrar_agregar_doc_prop: { default: true },
  },
  components: {
    FileUpload,
  },
  computed: {
    ...userMapGetters(['puede_upload_docs']),
  },
};
</script>

<style>
</style>