<template>
  <div v-if="puede_cm_tratamientos">
    <b-modal
      size="lg"
      ref="modal-visita"
      v-model="show"
      :title="titulo_modal_prop + nombre_animal"
      :hide-footer="true"
    >
      <form @submit="onSubmit">
        <div class="row">
          <div class="col-md-7">
            <label for="tratamiento">Tratamiento</label>
            <textarea
              v-model="formu.tratamiento"
              class="form-control"
              placeholder="Tratamiento"
              id="tratamiento"
              required
            ></textarea>
          </div>
          <div class="col-md-5">
            <label for="posologia">Posología</label>
            <textarea
              v-model="formu.posologia"
              class="form-control"
              placeholder="Posología"
              id="posologia"
              required
            ></textarea>
          </div>
        </div>
        <!--  row -->

        <div class="row">
          <div class="col-auto">
            <!-- intervalo -->
            <label for="intervalo">Cada</label>
            <input
              v-model.number="formu.intervalo"
              type="number"
              :max="formu.periodo == 'horas' ? 24 : 365"
              :min="formu.periodo == 'horas' ? 1 : 2"
              id="intervalo"
              class="form-control"
              style="width: 80px"
              v-on:blur="interValoPeriodo"
              required
            />
          </div>
          <div class="col-auto">
            <label for="periodo">Periodo</label>
            <select v-model="formu.periodo" class="form-control" id="periodo">
              <option value="horas">Horas</option>
              <option value="dias">Días</option>
            </select>
          </div>

          <div class="col-auto">
            <!-- fecha -->
            <label for="fecha_inicio">Desde</label>
            <input
              type="date"
              aria-label="fecha_inicio"
              class="form-control"
              v-model="formu.fecha_inicio"
              id="fecha_inicio"
              required
            />
          </div>
          <div class="col-auto">
            <!-- fecha -->
            <label for="fecha_finalizacion">Hasta (finalización)</label>
            <input
              type="date"
              aria-label="fecha_finalizacion"
              class="form-control"
              v-model="formu.fecha_finalizacion"
              id="fecha_finalizacion"
              required
            />
          </div>
          <div class="col-auto" v-if="duracionTratamientoTxT">
            <label for="duracion">Duración</label>
            <input
              type="text"
              id="duracion"
              v-model="formu.duracion"
              class="form-control form-control-sm"
              readonly
            />
          </div>
        </div>
        <!-- row -->
        <hr />
        <div class="row d-sm-flex justify-content-between">
          <div class="col-auto mb-2">
            <b-form-select
              v-model="formu.id_visita"
              :options="listaVisitas"
              id="id_visita"
            ></b-form-select>
          </div>

          <div class="col-auto d-flex justify-content-end">
            <div class="col-auto me-2">
              <button type="submit" class="btn btn-cian">Guardar</button>
            </div>
            <div class="col-auto me-2">
              <button
                type="button"
                @click="cancelar()"
                class="btn btn-secondary"
              >
                Cancelar
              </button>
            </div>
            <div class="col-auto">
              <b-button
                v-if="formu.id_med"
                squared
                class="w-auto ms-auto me-0"
                @click="anular()"
                variant="danger"
                >Anular</b-button
              >
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
const { mapGetters: animalMapGetters } = createNamespacedHelpers("animal");
const { mapGetters: historialMapGetters, mapActions: historialMapActions } =
  createNamespacedHelpers("historial");

export default {
  name: "TratamientoForm",
  data() {
    return {
      show: true,
      formu: {
        id_med: 0,
        id_visita: 0,
        id_vet: 0,
        id_animal: 0,
        tratamiento: "",
        posologia: "",
        intervalo: 24,
        periodo: "horas",
        duracion: "",
        fecha_registro: "",
        fecha_inicio: "",
        fecha_finalizacion: "",
        fecha_finalizacion_inicial: "",
        finalizado: 0,
        updated_med: "",
      },

      periodoForm: "horas",
    };
  },
  props: {
    tratamiento_prop: { default: false },
    id_animal_prop: { default: 0 },
    id_visita_prop: { default: 0 },
    agregar_tratamiento_prop: { default: 0 },
    tratamiento_modificar_activo_prop: { default: false },
    accion_form_prop: { default: "insert" },
    titulo_modal_prop: { default: "Agregar tratamiento " },
  },
  methods: {
    cargarFormuDeProp() {
      if (this.tratamiento_modificar_activo_prop != false) {
        let formu = Object.keys(this.formu);
        formu.forEach((element) => {
          this.formu[element] = this.tratamiento_prop[element];
        });
      }
    },
    cancelar() {
      //this.$refs['modal-actuacion'].hide()
      this.show = false;
    },
    anular() {
      console.log("borrar");
      let data = {
        id_item: this.formu.id_med,
        tipo_item: "tratamiento",
        id_user: this.usuario.id_usuario,
        accion: "anular",
      };
      this.$http
        .post(this.$bambuvet + "/trash.php", JSON.stringify(data), {
          emulateJSON: true,
          withCredentials: true,
        })
        .then((res) => {
          let resp = res.body;
          console.log(resp);
          if (resp.msg == "ok") {
            console.log(resp.msg);
            let dat = {
              id_med: this.formu.id_med,
              fecha_inicio: this.formu.fecha_inicio,
            };
            this.TRATAMIENTO_ANULARH(dat);
            this.enviarCerrar();
          }
        });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$http
        .post(
          this.$bambuvet + "/tratamiento-post.php",
          JSON.stringify(this.formu),
          { emulateJSON: true, withCredentials: true }
        )
        .then((res) => {
          //console.log(res.body);
          if (this.accion_form_prop == "insert") {
            this.TRATAMIENTO_INSERTH(res.body);
          } else {
            this.TRATAMIENTO_UPDATEH(res.body);
          }
          this.enviarCerrar();
        });
    },
    enviarCerrar() {
      this.$emit("cerrar");
    } /*
    ...animalMapActions([
      "TRATAMIENTO_INSERT",
      "TRATAMIENTO_UPDATE",
    ]),*/,
    ...historialMapActions([
      "TRATAMIENTO_INSERTH",
      "TRATAMIENTO_UPDATEH",
      "TRATAMIENTO_ANULARH",
    ]),
    interValoPeriodo() {
      if (this.formu.periodo == "horas") {
        if (this.formu.intervalo > 24) this.formu.intervalo = 24;
        if (this.formu.intervalo < 1) this.formu.intervalo = 1;
      } else {
        if (this.formu.intervalo > 365) this.formu.intervalo = 365;
        if (this.formu.intervalo < 2) this.formu.intervalo = 2;
      }
    },
    lapsoTiempo(date, a, m, d) {
      date = date.split("-");
      let today = new Date(a, m, d);
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();
      let yy = parseInt(date[0]);
      let mm = parseInt(date[1]);
      let dd = parseInt(date[2]);
      let years, months, days;

      months = month - mm;
      if (day < dd) {
        months = months - 1;
      }
      years = year - yy;

      if (month * 100 + day < mm * 100 + dd) {
        years = years - 1;
        months = months + 12;
      }
      if (years < 0) months -= 12;
      // days
      days = Math.floor(
        (today.getTime() -
          new Date(yy + years, mm + months - 1, dd).getTime()) /
          (24 * 60 * 60 * 1000)
      );
      //
      return {
        years: years,
        months: months,
        days: days,
      };
    },
    calcularDuracionTratamientoTxT() {
      let lapso = "";
      if (this.formu.fecha_inicio && this.formu.fecha_finalizacion) {
        if (this.formu.fecha_inicio < this.formu.fecha_finalizacion) {
          let ff = this.formu.fecha_finalizacion.split("-");
          let f = this.lapsoTiempo(
            this.formu.fecha_inicio,
            ff[0],
            ff[1] - 1,
            ff[2]
          );
          if (f.years) lapso += f.years + (f.years > 1 ? "años" : " año ");
          if (f.months) lapso += f.months + (f.months > 1 ? " meses" : " mes");
          if (f.months && f.days) lapso += " y ";
          if (f.days) lapso += f.days + 1 + " días"; //+1 para que incluya el día de inicio
          this.formu.duracion = lapso;
        }
      }
      return lapso;
    },
  },
  computed: {
    ...userMapGetters(["user_loged_in", "usuario", "nombre_usuario"]),
    ...userMapGetters(["puede_cm_tratamientos"]),
    ...animalMapGetters({ listas_general_cargadas: "listas_general_cargadas" }),
    ...animalMapGetters({ nombre_animal: "nombre_animal" }),
    ...historialMapGetters(["historial"]),
    duracionTratamientoTxT() {
      return this.calcularDuracionTratamientoTxT();
    },
    listaVisitas() {
      let arr = [{ value: 0, text: "Visita (ninguna)" }];
      this.historial.listas.visitas.forEach((element) =>
        arr.unshift({
          value: element.id_visita,
          text: element.fecha_visita + " - " + element.tipo_visita,
        })
      );
      return arr;
    },
  },
  mounted() {
    this.formu.id_animal = this.id_animal_prop;
    this.formu.id_visita = this.id_visita_prop;
    this.formu.id_med = this.id_med_prop;
    this.periodoForm = this.tratamiento_prop.periodo;
    this.cargarFormuDeProp();
    this.$root.$on("bv::modal::hide", () => {
      this.enviarCerrar();
    });
  },
  watch: {
    tratamiento_prop: function () {
      this.cargarFormuDeProp();
    },
    tratamiento_modificar_activo_prop: function () {
      this.cargarFormuDeProp();
    },
    id_animal_prop: function () {
      this.formu.id_animal = this.id_animal_prop;
    },
  },
};
</script>