<template>
  <div>
    <b-modal
      size="lg"
      ref="modal-visita"
      v-model="show"
      :body-text-variant="
        aplicacion_tratamiento_prop.realizada ? 'success' : 'danger'
      "
      :title="nombre_animal_prop"
      :hide-footer="true"
      v-bind:class="
        aplicacion_tratamiento_prop.realizada ? 'text-success' : 'text-danger'
      "
    >
      <div class="row">
        <div class="col-auto">
          <img :src="usuario.url_web + src_foto_prop" class="mr-3" />
        </div>
        <div class="col-auto">
          <h5>
            {{ nombre_tratamiento_prop }}
            <span v-if="mostrar_orden_prop">
              {{ aplicacion_tratamiento_prop.orden }}ª
              <span v-if="aplicacion_tratamiento_prop.periodo == 'horas'">
                del día
              </span>
              <span v-else> del tratamiento</span>
            </span>
          </h5>

          {{ posologia_prop }}
        </div>
        <!-- col-->
      </div>
      <!-- row-->

      <div v-if="modo == 'form'">
        <form @submit="onSubmit">
          <div class="row">
            <div class="col-md-6">
              <label for="evolucion_med">Evolución</label>
              <textarea
                v-model="formu.evolucion_med"
                class="form-control"
              ></textarea>
            </div>
            <div class="col-md-6">
              <label for="observaciones_med">Observaciones</label>
              <textarea
                v-model="formu.observaciones_med"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <!-- row -->
          <hr />
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">Usuario: {{ usuario.nombre_usuario }}</div>
            <div>
              <input
                v-model="formu.fecha"
                type="date"
                readonly
                class="form-control w-auto mr-1"
              />
            </div>
            <div>
              <input
                v-model="formu.hora_formato"
                type="time"
                required
                class="form-control w-auto"
              />
            </div>
          </div>

          <hr />
          <div class="row d-flex justify-content-end">
            <div class="col-auto">
              <button type="submit" class="btn btn-cian">Guardar</button>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click="cancelar()"
                class="btn btn-secondary"
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
      </div>

      <div v-else>
        <hr />
        <h6>Evolución</h6>
        {{ aplicacion_tratamiento_prop.evolucion_med }}
        <hr />
        <h6>Observaciones</h6>
        {{ aplicacion_tratamiento_prop.observaciones_med }}
        <div>
          <hr />
          <div class="d-flex justify-content-between">
            <div>Por: {{ aplicacion_tratamiento_prop.nombre_usuario }}</div>
            <div>
              Fecha: {{ aplicacion_tratamiento_prop.fecha_formato }} a las
              {{ aplicacion_tratamiento_prop.hora_formato }}
            </div>
          </div>
          <hr />
          <div class="row d-flex justify-content-end">
            <div class="col-auto">
              <button @click="modo = 'form'" class="btn btn-cian">
                Modificar
              </button>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click="cancelar()"
                class="btn btn-secondary"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: tareasMapGetters, mapActions: tareasMapActions } =
  createNamespacedHelpers("tareas");

const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
export default {
  name: "TareaAplicacionForm",
  data() {
    return {
      show: true,
      modo: "form", //form, mostrar
      accion_form: "insert_verificar",
      formu: {
        id_med_rel: 0,
        id_med: 0,
        id_animal: 0,
        id_usuario: 0,
        nombre_usuario: '',
        evolucion_med: "",
        observaciones_med: "",
        fecha: "",
        fecha_formato: "",
        fecha_med: "",
        hora_formato: "",
        orden: 0,
      },
    };
  },
  props: {
    aplicacion_tratamiento_prop: { default: false },
    nombre_animal_prop: { default: false },
    src_foto_prop: { default: false },
    nombre_tratamiento_prop: { default: "form" },
    posologia_prop: { default: false },
    modo_prop: { default: false },
    accion_form_prop: { default: "insert_verificar" },
    mostrar_orden_prop: { default: true },
  },
  methods: {
    cargarFormuDeProp() {
      if (this.aplicacion_tratamiento_prop != false) {
        let formu = Object.keys(this.formu);
        formu.forEach((element) => {
          if (element == "hora_formato") {
            let horaminutos =
              this.aplicacion_tratamiento_prop[element].split(":");
            if (horaminutos.length == 3) horaminutos.pop();
            horaminutos = horaminutos.join(":");
            horaminutos = this.HoraMinsAhora2(horaminutos);
            this.formu[element] = horaminutos;
          } else {
            this.formu[element] = this.aplicacion_tratamiento_prop[element];
          }
        });
        this.formu.id_usuario = this.usuario.id_usuario;
        this.modo = this.modo_prop;
        this.accion_form = this.accion_form_prop;
        this.formu.nombre_usuario = this.usuario.nombre_usuario
      }
    },
    cancelar() {
      this.show = false;
    },
    onSubmit(event) {
      event.preventDefault();
      /**/
      //console.log(this.formu);
      this.$http
        .post(
          this.$bambuvet + "/tarea-aplicacion-post.php",
          JSON.stringify(this.formu),
          {emulateJSON: true, withCredentials: true}
        )
        .then((res) => {
          console.log(res.body);
          if (this.accion_form == "insert_verificar") {
            //console.log("llamo");
            if(res.body)this.TAREA_APLICACION_INSERT(res.body); 
          } 
          else {
            this.TAREA_APLICACION_UPDATE(res.body);
          }
          this.enviarCerrar();
        });
    },
    enviarCerrar() {
      this.$emit("cerrar");
    },
    ...tareasMapActions(["TAREA_APLICACION_INSERT", "TAREA_APLICACION_UPDATE"]),
    HoraMinsAhora2(val) {
      if (val == "00:00") {
        let d = new Date();
        let h = d.getHours();
        if (h < 10) h = "0" + h;
        let m = d.getMinutes();
        if (m < 10) m = "0" + m;
        val = h + ":" + m;
      }
      return val;
    },
  },
  mounted() {
    this.cargarFormuDeProp();
    this.$root.$on("bv::modal::hide", () => {
      this.enviarCerrar();
    });
  },
  computed: {
    ...tareasMapGetters(["fecha_hoy"]),
    ...tareasMapGetters(["fecha_consulta"]),
    ...userMapGetters(["user","user_loged_in","usuario", "nombre_usuario"]),
    fechaHora() {
      return this.formu.fecha + " " + this.formu.hora_formato + ":00";
    },
    HoraMinsAhora() {
      let horaf = this.formu.hora_formato;
      if (horaf == "00:00") {
        let d = new Date();
        let h = d.getHours();
        if (h < 10) h = "0" + h;
        let m = d.getMinutes();
        if (m < 10) m = "0" + m;
        horaf = h + ":" + m;
      }
      return horaf;
    },
  },
  watch: {
    tratamiento_prop: function () {
      this.cargarFormuDeProp();
    },
    fechaHora: function () {
      this.formu.fecha_med = this.fechaHora;
    },
  },
};
</script>

<style>
button.Close {
  border: 0 none;
}
.mr-1 {
  margin-right: 1rem;
}
</style>