'use strict'

export default {
  namespaced: true,
  state: {
    animales: [],
    paginado: {
      inicio: 0,
      p: 1,
      p_siguiente: 0,
      p_anterior: 0,
      total_registros: 0,
      total_paginas: 0,
    },
    busqueda: {},
    BussyLoading: true,
  },
  mutations: {
    SET_ANIMALES(state, animales) {
      state.animales = animales
      state.BussyLoading = false
    },
    SET_PAGINADO(state, paginado) {
      state.paginado = paginado
      state.BussyLoading = false
    },
    SET_BUSQUEDA(state, busqueda) {
      state.busqueda = busqueda
      state.BussyLoading = false
    },
    SET_ADD_ANIMAL(state, form) {
      state.animales.unshift = form
    },
    SET_UPDATE_NOMBRE_ANIMAL(state, formu) {
      let nombre = formu.nombre
      let id = formu.id
      if(Array.isArray(state.animales)){
        let index = state.animales.findIndex(
          (item) => item.id === id 
        );
        if(index != -1) state.animales[index].nombre = nombre;
      }
    },
    SET_UPDATE_FOTO_ANIMAL(state, formu) {
      let id_foto = formu.id_foto
      let id = formu.id
      if(Array.isArray(state.animales)){
        let index = state.animales.findIndex(
          (item) => item.id === id 
        );
        if(index != -1){
          state.animales[index].id_foto = id_foto;
          state.animales[index].src_thumbnail_square = '/imas/animales/_' + id + '/thumbnail_square.jpg'
        } 
      }
    },
    SET_BUSSYLOADING(state, estado) {
      state.BussyLoading = estado
    }
  },
  actions: {
    async GET_ANIMALES({ commit }, p) {
      commit('SET_BUSSYLOADING', true)
      //console.log('$bambuvet: ' + this._vm.$bambuvet)
      const res = await fetch(this._vm.$bambuvet + `/animales-listado.php?p=${p}`, {
        method: 'GET',
        credentials: 'include'
      })
      const lista = await res.json()
      commit('SET_ANIMALES', lista.animales)
      commit('SET_PAGINADO', lista.paginado)
      commit('SET_BUSQUEDA', lista.busqueda)
    },
    async GET_BUSCAR_ANIMALES({ commit }, s) {
      commit('SET_BUSSYLOADING', true)
      const res = await fetch(this._vm.$bambuvet + `/animales-listado.php?s=${s}`, {
        method: 'GET',
        credentials: 'include'
      })
      const lista = await res.json()
      commit('SET_ANIMALES', lista.animales)
      commit('SET_PAGINADO', lista.paginado)
      commit('SET_BUSQUEDA', lista.busqueda)
    },
    async ADD_ANIMAL({ commit }, form){
      form.id_foto = 0 
      form.src_foto = this._vm.$bambuvet_web + '/img/no-foto.jpg'
      form.src_thumbnail_square = this._vm.$bambuvet_web + '/img/no-foto.jpg'
      form.src_thumbnail_square_mini = this._vm.$bambuvet_web + '/img/no-foto.jpg'
      commit('SET_ADD_ANIMAL', form)
    },
    async UPDATE_NOMBRE_ANIMAL({ commit }, formu){
      commit('SET_UPDATE_NOMBRE_ANIMAL', formu)
    },
    async UPDATE_FOTO_ANIMAL({ commit }, formu){
      commit('SET_UPDATE_FOTO_ANIMAL', formu)
    }, 
    BUSSYLOADING({ commit },estado){
      commit('SET_BUSSYLOADING', estado)
    },
  },
  getters: {
    animales: state => { return state.animales },
    paginado: state => { return state.paginado },
    busqueda: state => { return state.busqueda },
    BussyLoadingAnimales: state => { return state.BussyLoading },
  }
}