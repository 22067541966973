<template>
  <div>
    <b-container class="d-sm-flex justify-content-between">
      <Breadcrumb :items_prop="itemsBreadcrumb"></Breadcrumb>
    <nav class="nav  justify-content-center">
      <router-link class="nav-link active" :to="'/Agenda'">
      Presente
    </router-link>
      <router-link
      class="nav-link"
      :to="{
        name: 'Agenda semana',
        params: {
          semana: 'proxima semana',
        },
      }"
    >
      Próxima
    </router-link>
      
      <router-link
      class="nav-link active"
      :to="{
        name: 'Agenda semana',
        params: {
          semana: 'semana pasada',
        },
      }"
    >
      Pasada
    </router-link>
    </nav>
    </b-container>

    <h1>{{ TituloAMostrar }}</h1>
    <AgendaSemana :fechas_semana_prop="SemanaAMostrar"></AgendaSemana>

    <div></div>
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from "vuex";
const { mapGetters: agendaMapGetters, mapActions: agendaMapActions } =
  createNamespacedHelpers("agenda");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
const { mapGetters: fechaslibMapGetters, mapActions: fechaslibMapActions } =
  createNamespacedHelpers("fechaslib");
import AgendaSemana from "@/components/AgendaSemana";
import Breadcrumb from "@/components/Breadcrumb";
export default {
  name: "Agenda",
  data() {
    return {};
  },
  components: {
    AgendaSemana,
    Breadcrumb
  },
  computed: {
    ...mapGetters(["url_site_local"]), 
    ...userMapGetters(["user"]),
    ...userMapGetters(['user_loged_in','usuario','nombre_usuario',]),
    ...agendaMapGetters(["fecha_hoy"]),
    ...agendaMapGetters(["fecha_consulta"]),
    ...agendaMapGetters(["animales_activos"]),
    ...agendaMapGetters(["tratamientos_activos"]),
    ...agendaMapGetters(["visitas"]),
    ...agendaMapGetters(["actuaciones"]),
    ...agendaMapGetters(["visitas"]),
    ...agendaMapGetters(["agenda_global"]),
    ...fechaslibMapGetters(["fechas_lib"]),
    SemanaAMostrar() {
      if (this.$route.params) {
        //console.log(this.$route.params);
        if (this.$route.params.semana == "semana pasada")
          return this.SemanaPasada;
        else if (this.$route.params.semana == "proxima semana")
          return this.SemanaProxima;
      }
      return this.SemanaPresente;
    },
    TituloAMostrar() {
      if (this.$route.params) {
        if (this.$route.params.semana == "semana pasada")
          return "Semana pasada ";
        else if (this.$route.params.semana == "proxima semana")
          return "Próxima semana ";
      }
      return "Semana presente";
    },
    SemanaPasada() {
      if (!this.fechas_lib.semana_pasada) return false;
      let semana_pasada = {};
      let fechas_global_keys = Object.keys(this.agenda_global);
      let fechas_semana_pasada_keys = Object.keys(
        this.fechas_lib.semana_pasada.fechas
      );

      fechas_global_keys.forEach((element) => {
        if (fechas_semana_pasada_keys.includes(element)) {
          let semana_pasada_keys = Object.keys(semana_pasada);
          if (!semana_pasada_keys.includes(element)) {
            let dia_nombre = this.fechas_lib.semana_pasada.fechas[element];
            semana_pasada[element] = {
              animales: [],
              agenda: [],
              dia_semana: dia_nombre,
            };
          }
          this.agenda_global[element].forEach((el) => {
            semana_pasada[element].agenda.push(el);
            if (!semana_pasada[element].animales.includes(el.id_animal)) {
              semana_pasada[element].animales.push(el.id_animal);
            }
          });
        }
      });
      return semana_pasada;
    },
    SemanaPresente() {
      if (!this.fechas_lib.semana_presente) return false;
      let semana_presente = {};
      let fechas_global_keys = Object.keys(this.agenda_global);
      let fechas_semana_presente_keys = Object.keys(
        this.fechas_lib.semana_presente.fechas
      );

      fechas_global_keys.forEach((element) => {
        if (fechas_semana_presente_keys.includes(element)) {
          let semana_presente_keys = Object.keys(semana_presente);
          if (!semana_presente_keys.includes(element)) {
            let dia_nombre = this.fechas_lib.semana_presente.fechas[element];
            semana_presente[element] = {
              animales: [],
              agenda: [],
              dia_semana: dia_nombre,
            };
          }
          this.agenda_global[element].forEach((el) => {
            semana_presente[element].agenda.push(el);
            if (!semana_presente[element].animales.includes(el.id_animal)) {
              semana_presente[element].animales.push(el.id_animal);
            }
          });
        }
      });
      return semana_presente;
    },
    SemanaProxima() {
      if (!this.fechas_lib.semana_proxima) return false;
      let semana_proxima = {};
      let fechas_global_keys = Object.keys(this.agenda_global);
      let fechas_semana_proxima_keys = Object.keys(
        this.fechas_lib.semana_proxima.fechas
      );

      fechas_global_keys.forEach((element) => {
        if (fechas_semana_proxima_keys.includes(element)) {
          let semana_proxima_keys = Object.keys(semana_proxima);
          if (!semana_proxima_keys.includes(element)) {
            let dia_nombre = this.fechas_lib.semana_proxima.fechas[element];
            semana_proxima[element] = {
              animales: [],
              agenda: [],
              dia_semana: dia_nombre,
            };
          }
          this.agenda_global[element].forEach((el) => {
            semana_proxima[element].agenda.push(el);
            if (!semana_proxima[element].animales.includes(el.id_animal)) {
              semana_proxima[element].animales.push(el.id_animal);
            }
          });
        }
      });
      return semana_proxima;
    },
    itemsBreadcrumb() {
      var items = [
        {
          text: 'Agenda',
          to: "Agenda",
        }
      ];
      return items;
    },
  },
  methods: {
    ...fechaslibMapActions(["GET_LIB_FECHAS"]),
    ...agendaMapActions(["GET_AGENDA"]),
  },
  created() {},
  mounted() {
    if(!this.user_loged_in){
      //this.$router.push({ name: 'Login'})
    }
    else{
      this.GET_AGENDA();
    }
    document.title = 'Agenda ' ;
  },
  watch: {
    user_loged_in: function(){
      if(this.user_loged_in) this.GET_AGENDA();
    }
  },
};
</script>

<style lang="scss" scoped>
nav a.nav-link{color: $cian !important}
</style>