<template>
  <div>
    <ActuacionForm
      v-if="actuacion_modificar_activa"
      v-on:cerrar="actuacion_modificar_activa = false"
      :actuacion_modificar_activa_prop="actuacion_modificar_activa"
      :id_animal_prop="id_animal"
      accion_form_prop="update"
    ></ActuacionForm>
    <b-row class="mt-2" align-v="end">
      <b-col class="d-flex">
        <h3 class="h5" v-if="!carga_en_visita_prop">Actuaciones</h3>
      </b-col>
      <b-col
        cols="auto"
        v-if="puede_cm_actuaciones & mostrar_agregar_actuacion_prop"
      >
        <b-button
          size="sm"
          variant="outline-info"
          class="mb-2 cian"
          :pressed.sync="agregar_actuacion"
          ><b-icon-plus-square-fill font-scale="1"></b-icon-plus-square-fill>
          Agregar actuación</b-button
        >
      </b-col>
    </b-row>

    <ActuacionForm
      v-if="agregar_actuacion"
      v-on:cerrar="agregar_actuacion = false"
      :id_animal_prop="id_animal"
      :id_visita_prop="id_visita_prop"
      :fecha_visita_prop="fecha_visita_prop"
    ></ActuacionForm>

    <b-table
      v-if="animal_cargado && actuaciones_realizadas_prop.length > 0"
      :busy="!animal_cargado"
      table-variant="light"
      thead-class="d-none"
      hover
      :items="actuaciones_realizadas_prop"
      :fields="columnasCargar"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template v-slot:cell(realizada)="scope">
        <div class="text-nowrap">
          <b-icon
            v-if="scope.value == '1'"
            icon="check"
            class="text-success"
          ></b-icon>
          <b-icon v-else icon="calendar3" class="text-danger"></b-icon>
        </div>
      </template>
      <template #cell(show_details)="row">
        <div style="text-align: right" class="text-nowrap">
          <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            <b-icon-caret-up-fill
              v-if="row.detailsShowing"
              font-scale="0.8"
            ></b-icon-caret-up-fill>
            <b-icon-caret-down-fill
              v-else
              font-scale="0.8"
            ></b-icon-caret-down-fill>
          </b-button>
          &nbsp;

          <b-button
            v-if="puede_cm_actuaciones && mostrar_edicion_actuacion_prop"
            size="sm"
            variant="info"
            @click="actuacion_modificar_activa = row.item"
            class="text-white"
          >
            <b-icon-pencil font-scale="1"></b-icon-pencil>
          </b-button>
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <strong v-if="row.item.resultado">Resultado</strong>
          {{ row.item.resultado }}
          <h4 class="h6" v-if="row.item.observaciones_ac">
            <b>Observaciones</b>
          </h4>
          {{ row.item.observaciones_ac }}
        </b-card>
      </template>
    </b-table>
    <div v-else>
      <p  v-if="!carga_en_visita_prop">No se encontraron actuaciones</p>
    </div>
    
  </div>
</template>

<script>
import ActuacionForm from "@/components/ActuacionForm";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: animalMapGetters } = createNamespacedHelpers("animal");
const { mapGetters: baseMapGetters } = createNamespacedHelpers("base");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");

export default {
  name: "ActuacionesLista",
  data() {
    return {
      agregar_actuacion: false,
      modificar_actuacion: false,
      actuacion_modificar_activa: false,
      carga_en_visita: false,
      fields: [
        { key: "realizada", label: "", tdClass: "text-left col-realizada" },
        { key: "nombre_ac_t", label: "Actuación" },
        { key: "nombre_ac", label: "" },
        { key: "fecha_ac", label: "Fecha" },
        { key: "show_details", label: "Detalles", tdClass: "text-right" },
      ],
    };
  },
  components: {
    ActuacionForm,
  },
  props: {
    actuaciones_realizadas_prop: { default: () => [] },
    id_visita_prop: { default: 0 },
    carga_en_visita_prop: { default: false },
    fecha_visita_prop: { default: false },
    mostrar_agregar_actuacion_prop: { default: true },
    mostrar_edicion_actuacion_prop: { default: true },
  },
  computed: {
    ...userMapGetters(["user_loged_in", "usuario", "nombre_usuario"]),
    ...userMapGetters(["puede_cm_actuaciones"]),
    ...baseMapGetters({ actuaciones: "actuaciones" }),
    ...baseMapGetters({ actuaciones_tipos: "actuaciones_tipos" }),
    //...animalMapGetters({ actuaciones_realizadas: "actuaciones_realizadas" }),
    ...animalMapGetters({ id_animal: "id_animal" }),
    ...animalMapGetters({ animal_cargado: "animal_cargado" }),
    //isBusy(){return !this.animal_cargado}
    columnasCargar() {
      if (this.carga_en_visita_prop) {
        return this.fields.filter((obj) => obj.label != "Fecha");
      } else {
        return this.fields;
      }
    },
  },
  methods: {
    toggleBusy() {
      //this.isBusy = !this.isBusy
    },
    saluda(ea) {
      alert("ola " + ea);
    },
  },
  mounted() {},
};
</script>


<style lang="scss">
#visita {
  h2.h5 {
    font-size: 1rem !important;
    color: #17a2b8;
    font-weight: 500;
    margin-bottom: 0.7rem;
  }
}
</style>

