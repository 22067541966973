<template>
  <div>
    <DocForm
     :id_animal_prop="id_animal_prop"
     :id_visita_prop="id_visita_prop"
     :mostrar_agregar_doc_prop="mostrar_agregar_doc_prop"
    ></DocForm>
    <ul v-if="docs_prop" class="list-unstyled">
      <li v-for="(doc, index) in docs_prop "
      :key="index"
      >
      <Doc
        :doc_prop="doc"
      ></Doc> 
      </li>
    </ul>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: animalMapGetters } = createNamespacedHelpers("animal");
import Doc from "@/components/Doc";
import DocForm from "@/components/DocForm";
export default {
  name: "DocsLista",
  data() {
    return {};
  },
  components:{
    Doc,
    DocForm
  },
  props:{
    id_animal_prop: { default: false },
    docs_prop: { default: false },
    id_visita_prop: { default: 0 },
    mostrar_agregar_doc_prop: { default: true },
  },
  computed: {
    ...animalMapGetters(["animal", "docs", "id_animal"]),
  },
};
</script>

<style>
</style>