<template>
  <div id="animal-caracteristicas-form pb-1" v-if="puede_cm_animales">
    <h2 class="h4">Modificar datos</h2>
    <b-form @submit="onSubmit" class=" mb-3">
      <b-row class="mt-3">
        <b-col cols="auto" class=" mb-2">
          <b-form-group 
          :class="validacion.nombre.class"
          id="formu-nombre-group" label="Nombre:" label-for="formu-nombre">
        <b-form-input
          id="formu-nombre"
          v-model="formu.nombre"
          placeholder="Nombre"
          required
          :class="validacion.nombre.class"
        ></b-form-input>
      </b-form-group>
        </b-col>
        <b-col cols="auto" class=" mb-2">
          <b-form-group id="formu-fecha_entrada-group" label="Fecha de entrada:" label-for="formu-fecha_entrada">
          <b-form-input 
            id="formu-fecha_entrada" type="date" v-model="formu.fecha_entrada"
          :class="validacion.fecha_entrada.class" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      

      <b-row>
        <b-col cols="auto" class=" mb-2">
          <b-form-group
            id="fg-especies"
            label="Especie:"
            label-for="f-especies"
            
          >
            <b-form-select
              class="form-control w-auto  custom-select"
              id="f-especies"
              v-model="formu.especie_id"
              :options="especies"
              required
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="auto" class=" mb-2">
          <b-form-group
            id="fg-tamanios"
            label="Tamaño:"
            label-for="f-tamanios"
            
          >
            <b-form-select
              class="form-control w-auto custom-select"
              id="f-tamanios"
              v-model="formu.tamanio_id"
              :options="tamanios"
              required
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="auto" class=" mb-2">
          <b-form-group
            id="fg-sexos"
            label="Sexo:"
            label-for="f-sexos"
            
          >
            <b-form-select
              class="form-control w-auto custom-select"
              :class="validacion.fecha_salida.class"
              id="f-sexos"
              v-model="formu.sexo_id"
              :options="sexos"
              required
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="d-flex align-items-center ">
        <b-col cols="auto" class=" mb-2">

            <b-form-group 
              id="formu-fecha_entrada-group" label="Fecha nacimiento estimada:" label-for="form_fecha_nacimiento">
            <b-form-input 
              id="form_fecha_nacimiento" 
              type="date" 
              v-model="mifecha"
              class="w-auto"
               :class="validacion.fecha_nacimiento.class"
               required
              ></b-form-input>
            </b-form-group>
        </b-col>
        <b-col cols="auto" class=" mb-2">
          <b-form-group id="form-edad-group" label="Edad:" label-for="form-edad">
        <b-form-input
          id="form-edad"
          v-model="edad_calc"
          placeholder="Edad"
          disabled
        ></b-form-input>
      </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="auto" class=" mb-2">
          <b-form-group id="form-microchip-group" label="Microchip:" label-for="form-microchip">
            <b-form-input
              id="form-microchip"
              v-model="formu.microchip"
              placeholder="Microchip"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="auto" class=" mb-2">
          <b-form-group id="form-peso-group" label="Peso:" label-for="form-peso">
            <b-form-input
              id="form-peso"
              v-model="formu.peso"
              placeholder="Peso"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-sm-content-end align-items-center" v-if="!es_nuevo_prop">
        <b-col cols="auto" class=" mb-2">
          <b-form-group id="formu-fecha_salida-group" label="Fecha de salida:" label-for="formu-fecha_salida">
          <b-form-input :class="validacion.fecha_salida.class" id="formu-fecha_salida" type="date" v-model="formu.fecha_salida"></b-form-input>
          
          </b-form-group>
        </b-col>
        <b-col cols="auto" class=" mb-2">
          
          <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="fallecido"
                v-model="formu.fallecido"
              />
              <label class="form-check-label" for="fallecido">
                fallecido
              </label>
            </div>

        </b-col>
        
      </b-row>

      <b-button
        squared
        class="mr-2 mt-3"
        size="sm"
        type="submit"
        variant="primary"
        >Guardar</b-button
      >
      <b-button
        class="ml-1 mt-3"
        squared
        size="sm"
        variant="info"
        :pressed.sync="modo_form"
        @click="$emit('cambiar_modo_form', modo_form)"
      >
        Volver</b-button
      >
    </b-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: animalMapGetters } = createNamespacedHelpers("animal");
const { mapGetters: baseMapGetters } = createNamespacedHelpers("base");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
const { mapGetters: fechaslibMapGetters } = createNamespacedHelpers("fechaslib");
export default {
  name: "AnimalCaracteristicasForm",
  data() {
    return {
      modo_form: true,
      formu: {
        nombre: "",
        fallecido: false,
      },
      validacion:{
        nombre: {
          valid: false,
          class: ''
        },
        especie_id: {
          valid: false,
          class: ''
        },
        tamanio_id: {
          valid: false,
          class: ''
        },
        sexo_id: {
          valid: false,
          class: ''
        },
        fecha_entrada: {
          valid: false,
          class: ''
        },
        fecha_nacimiento: {
          valid: false,
          class: ''
        },
        fecha_salida: {
          valid: false,
          class: ''
        },
      },
      mifecha: "2020-09-20",
      accion: 'update',
      
    };
  },
  props: {
    animal: Object,
    es_nuevo_prop: { default: false },
  },
  methods: {
    formValidacion(){
      let pasa = true;
      if(this.formu.nombre.length < 3 ){
        this.validacion.nombre.class='fieldError'
        pasa = false
      }
      if(!this.formu.especie_id){
        this.validacion.especie_id.class='fieldError'
        pasa = false
      }
      if(!this.formu.tamanio_id){
        this.validacion.tamanio_id.class='fieldError'
        pasa = false
      }
      if(!this.formu.sexo_id){
        this.validacion.sexo_id.class='fieldError'
        pasa = false
      }
      if(!this.formu.fecha_entrada){
        this.validacion.fecha_entrada.class='fieldError'
        pasa = false
      }
      if(!this.formu.fecha_nacimiento){
        this.validacion.fecha_nacimiento.class='fieldError'
        pasa = false
        console.log('ffffff')
      }
      if(this.formu.fallecido && !this.formu.fecha_salida){
        this.validacion.fecha_salida.class='fieldError'
        pasa = false
        console.log('gggggggggg')
      }
      console.log(pasa)
      return pasa;
    },
    cargarAnimalForm() {
      let cars = this.caracteristicas_form;
      cars.forEach((element) => {
        this.formu[element] = this.animal[element];
        
        if(
          element == 'fecha_salida' ||
          element == 'fecha_entrada' ||
          element == 'fecha_nacimiento'
          ){
          if(this.formu[element]=='0000-00-00'){
            this.formu[element] = null
            console.log(element)
          }
        }
        if(this.formu[element]=== undefined) this.formu[element] = ''
      });
      this.mifecha = this.formu.fecha_nacimiento;
      if(this.es_nuevo_prop){
        this.formu.nombre = '';
        this.formu.fecha_entrada = this.fechas_lib.fecha_hoy;
      }
    },
    onSubmit(event) {
      event.preventDefault();
      console.log('submito')
      if(this.formValidacion()){
        let url_enviar = this.$bambuvet + "/animal-update.php";
        if(this.accion=='insert'){
          url_enviar = this.$bambuvet + "/animal-insert.php";
        }
        //console.log('url_enviar: ' + url_enviar)
        this.$http
          .post(
            url_enviar,
            JSON.stringify(this.formu),
            {emulateJSON: true, withCredentials: true}
          )
          .then((res) => {
            //console.log(res.body);
            this.$emit("update_animal_form", JSON.stringify(this.formu));
            this.$emit("update_animales_listado", this.formu); 
            if(this.accion=='insert'){
              console.log('ID: ' + res.body.animal.id) 
              let id=res.body.animal.id;
              this.formu.id = id.toString();
              this.$emit("insert_animal_form", this.formu);
              //console.log('entro en redirigir')
              this.$router.push({ 
                name: 'animal',
                params: { nombre: this.formu.nombre, id: this.formu.id}, 
                });
            }
          
            this.$emit('cerrar_caracteristicas_form')
          });
      }
    },

    dateDiff(date) {
      if (date) {
        date = date.split("-");
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var yy = parseInt(date[0]);
        var mm = parseInt(date[1]);
        var dd = parseInt(date[2]);
        var years, months, days;
        // months
        months = month - mm;
        if (day < dd) {
          months = months - 1;
        }
        // years
        years = year - yy;
        if (month * 100 + day < mm * 100 + dd) {
          years = years - 1;
          months = months + 12;
        }
        // days
        days = Math.floor(
          (today.getTime() -
            new Date(yy + years, mm + months - 1, dd).getTime()) /
            (24 * 60 * 60 * 1000)
        );
        return {
          years: years,
          months: months,
          days: days,
        };
      }
    },
  },
  created() {
    this.cargarAnimalForm();
  },
  mounted(){
    this.accion = this.es_nuevo_prop ? 'insert' : 'update';
  },
  watch: {
    animal: function () {
      this.cargarAnimalForm();
    },
    mifecha: function () {
      this.formu.fecha_nacimiento = this.mifecha;
    },
  },
  computed: {
    
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...userMapGetters(['puede_cm_animales']),
    ...animalMapGetters({ caracteristicas_form: "caracteristicas_form" }),
    ...baseMapGetters({ especies: "especies" }),
    ...baseMapGetters({ tamanios: "tamanios" }),
    ...baseMapGetters({ sexos: "sexos" }),
    ...fechaslibMapGetters(["fechas_lib"]),
    edad_calc() {
      if (this.animal) {
        let f = this.dateDiff(this.mifecha);
        if (f) {
          let edad_arr = [],
            anios = "",
            meses = "",
            dias = "";
          if (f.years > 0) {
            anios = f.years + " años";
            edad_arr.push(anios);
          }
          if (f.months > 0) {
            meses = f.months + " meses";
            edad_arr.push(meses);
          }
          if (f.days == 1) dias = f.days + " día ";
          if (f.days > 1) dias = f.days + " días ";
          if (dias != "") edad_arr.push(dias);
          let edad = edad_arr.join(", ");
          return edad;
        } else return "";
      } else return "";
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  color: #fff !important;
  margin-right: 1rem;
}
</style>