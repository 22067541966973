<template>
  <div> 
    <div class="aplicacion-tratamiento text-right">
      <div
        class="row"
        v-bind:class="
          aplicacion_tratamiento_prop.realizada ? 'text-success' : 'text-danger'
        "
      >
        <div class="col-auto iz flex-grow-1">
         
          <div class="row">
            <div class="col-auto">
              {{ aplicacion_tratamiento_prop.fecha_formato }}
              <span v-if="aplicacion_tratamiento_prop.realizada">{{
                HorasMin()
              }}</span>
            </div>
            <!-- col-->
            <div v-if="aplicacion_tratamiento_prop.realizada" class="col-auto">
              <b-icon icon="check"></b-icon> Realizada
            </div>
            <!-- col-->
            <div v-else class="col-auto ml-auto">Pendiente de realizar</div>
            <div
              v-if="aplicacion_tratamiento_prop.evolucion_med"
              class="col-auto"
            >
              <b-icon icon="chat-dots-fill"></b-icon>
            </div>
            <div
              v-if="aplicacion_tratamiento_prop.observaciones_med"
              class="col-auto"
            >
              <b-icon icon="chat-dots"></b-icon>
            </div>
          </div>
          <!-- col iz -->
        </div>
        <div class="col-auto mr-auto">
          <div v-if="puede_aplicar_tareas">
            <button
              v-if="!aplicacion_tratamiento_prop.realizada"
              class="btn btn-sm btn-info mt-1"
              @click="mostrar_form = true"
            >
              Guardar como realizada
            </button>

            <button
              v-else
              class="btn btn-sm btn-success"
              @click="
                modo_form = 'ver';
                accion_form = 'modify';
                mostrar_form = true;
              "
            >
              Ver
            </button>

            <TareaAplicacionForm
              v-if="mostrar_form"
              :aplicacion_tratamiento_prop="aplicacion_tratamiento_prop"
              :nombre_animal_prop="nombre_animal_prop"
              :nombre_tratamiento_prop="nombre_tratamiento_prop"
              :posologia_prop="posologia_prop"
              :tratamiento_prop="tratamiento_prop"
              :src_foto_prop="src_foto_prop"
              :modo_prop="modo_form"
              :accion_form_prop="accion_form"
              :mostrar_orden_prop="false"
              v-on:cerrar="mostrar_form = false"
            ></TareaAplicacionForm>
          </div>
          <!-- aplicar tareas-->
        </div>
      </div>
      <!--row-->
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: tareasMapGetters } = createNamespacedHelpers("tareas");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
import TareaAplicacionForm from "@/components/TareaAplicacionForm";
export default {
  name: "TareaAplicacion",
  data() {
    return {
      mostrar_form: false,
      modo_form: "form", //form, mostrar
      accion_form: "insert",
    };
  },
  components: {
    TareaAplicacionForm,
  },
  props: {
    aplicacion_tratamiento_prop: { default: false },
    nombre_animal_prop: { default: false },
    src_foto_prop: { default: false },
    nombre_tratamiento_prop: { default: false },
    posologia_prop: { default: false },
    tratamiento_prop: { default: false },
  },
  methods: {
    HorasMin() {
      let horaminutos =
        this.aplicacion_tratamiento_prop.hora_formato.split(":");
      if (horaminutos.length == 3) horaminutos.pop();
      horaminutos = horaminutos.join(":");
      return horaminutos;
    },
  },
  computed: {
    ...userMapGetters(["user_loged_in", "usuario", "nombre_usuario"]),
    ...userMapGetters(["puede_ver_tareas", "puede_aplicar_tareas"]),
    ...tareasMapGetters(["fecha_hoy"]),
    ...tareasMapGetters(["fecha_consulta"]),
  },
};
</script>

<style type="scss" scoped>
.aplicacion-tratamiento {
  border-top: 1px solid #ccc;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>