<template>
  <div v-if="actuacion">
    <b-table responsive
      v-if="actuacion.length > 0"
      table-variant="light"
      thead-class="d-none"
      hover
      outlined
      fixed
      :items="actuacion"
      :fields="fields"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(realizada)="scope">
        <div class="text-nowrap" >
          <b-icon v-if="scope.value=='1'" icon="check" class="text-success"></b-icon>
      <b-icon v-else icon="calendar3" class="text-danger"></b-icon>
        </div>
      </template>

      <template #cell(show_details)>
        <div style="text-align: right" class="text-nowrap" >
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <strong v-if="row.item.resultado">Resultado</strong>
          {{ row.item.resultado }}
          <h4 class="h6" v-if="row.item.observaciones_ac">
            <b>Observaciones</b>
          </h4>
          {{ row.item.observaciones_ac }}
        </b-card>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "Actuacion",
  data() {
    return {
      actuacion: false,
      fields: [
        { key: "realizada", label: "", tdClass: "text-left col-realizada" }, 
        { key: "nombre_ac_t", label: "Actuación" },
        { key: "nombre_ac", label: "" },
        { key: "fecha_ac", label: "Fecha" },
        { key: "show_details", label: "Detalles", tdClass: "text-right" },
      ],
    };
  },
  props: {
    actuacion_prop: { default: false },
  },
  methods: {
    actualizarData() {
      this.actuacion = [this.actuacion_prop];
    },
  },
  created: function () {
    this.actualizarData();
  },
  watch: {
    actuacion_prop: function () {
      this.actualizarData();
    },
  },
};
</script>

<style lang="scss">
table td{text-align: left !important;}
.col-realizada{width: 25px;}

</style>