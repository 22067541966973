<template>
  <div>
    <div class="card">
      <div class="card-header">
        <small>{{dia_semana_prop}} {{fechaFormato}}</small>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-auto">
            <router-link v-if="animal"
            class="stretched-link"
            :to="{
              name: 'animal',
              params: { nombre: animal.nombre, id: animal.id_animal },
            }"
          >
            <img :src="srcFoto" class="img-fluid" style="width: 90px" />
            </router-link>
          </div>
          <div class="col-auto">
            <h3 class="h5" v-if="animal">{{ animal.nombre_animal }}</h3>
            <div v-for="(tarea, index) in Agenda" :key="index">
              <div v-if="tarea.tipo == 'tratamiento'">
                {{ obtenerItem(tratamientos_activos, 'id_med', tarea.id).tratamiento }}
              </div>
              <div v-else-if="tarea.tipo == 'visita'"> 
                <AgendaAnimalActivoVisita 
                  :nombre_animal_prop="animal.nombre_animal"
                  :foto_prop="srcFoto"
                  :visita_prop="obtenerItem(visitas, 'id_visita', tarea.id)"
                ></AgendaAnimalActivoVisita>
              </div>
              <div v-else-if="tarea.tipo == 'actuacion'">
               
                <AgendaAnimalActivoActuacion
                  :nombre_animal_prop="animal.nombre_animal"
                  :foto_prop="srcFoto"
                  :actuacion_prop="obtenerItem(actuaciones, 'id_ac_rel', tarea.id)"
                ></AgendaAnimalActivoActuacion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- card-body-->
    </div>
    <div v-for="(tarea, index) in Agenda" :key="index">
      <div v-if="tarea.tipo == 'visita'">
        <AgendaAnimalActivoVisita
          :nombre_animal_prop="animal.nombre_animal"
          :foto_prop="srcFoto"
          :visita_prop="obtenerItem(visitas, 'id_visita', tarea.id)"
          :fecha_prop="fecha_prop"
        ></AgendaAnimalActivoVisita>
       
      </div>
    </div>
    <!-- VISITA-->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: agendaMapGetters } = createNamespacedHelpers("agenda");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
import AgendaAnimalActivoVisita from "@/components/AgendaAnimalActivoVisita";
import AgendaAnimalActivoActuacion from "@/components/AgendaAnimalActivoActuacion";
export default {
  name: "AgendaAnimalActivo",
  data() {
    return {
      animal_base: {
        nombre_animal: 'Nombre'
      }
    };
  },
  components: {
    AgendaAnimalActivoVisita,
    AgendaAnimalActivoActuacion,
  },
  props: {
    id_animal_prop: { defaul: 0 },
    agenda_prop: { defaul: false },
    dia_semana_prop: { defaul: false },
    fecha_prop: { default: false },
  },
  computed: {
    ...userMapGetters(["user", "user_loged_in","usuario", "nombre_usuario"]),
    ...agendaMapGetters(["animales_activos"]),
    ...agendaMapGetters(["visitas"]),
    ...agendaMapGetters(["actuaciones"]),
    ...agendaMapGetters(["tratamientos_activos"]),
    Agenda() {
      return this.agenda_prop.filter(
        (item) => item.id_animal == this.id_animal_prop
      );
    },
    imagenDefault() {
      return this.$store.state.url_foto_default;
    },
    srcFoto() {
      let src_foto = this.id_animal_prop
        ? this.usuario.url_web +
          "/imas/animales/_" +
          this.id_animal_prop +
          "/thumbnail_square_mini.jpg"
        : this.imagenDefault;
      return src_foto;
    },
    animal() {
      if(Array.isArray(this.animales_activos)){
        let anim = this.animales_activos.find(
          (item) => item.id_animal === this.id_animal_prop 
        );
        return anim;
      }
      else return this.animal_base
    },
    fechaFormato(){
      if(this.fecha_prop){
        let fe = this.fecha_prop.split('-')
      let fefo = fe[2]+'-'+fe[1]+'-'+fe[0]
      return fefo
      }
      else return false
    }
  },
  methods: {
    obtenerItem(lista, nombre_id, id) {
      let item = lista.find((it) => it[nombre_id] === id);
      return item;
    },
  },
};
</script>

<style>
.card{text-align: left !important;}
</style>