'use strict'

export default {
  namespaced: true,
  state: {
    animal: {
      id: '',
      src_thumbnail_square: '',
      id_foto: '0',
      fallecido: 0,
      fecha_salida: null,
    },
    animal_cargado: false,
    caracteristicas_form: ['id', 'nombre', 'especie_id', 'tamanio_id', 'sexo_id', 'fecha_nacimiento', 'microchip', 'peso', 'fecha_entrada', 'fecha_salida', 'fallecido'],
    visitas: false,
    actuaciones_realizadas: false,
    tratamientos: false,
    docs: false
  },
  mutations: {
    SET_HISTORIAL_TRATAMIENTO_DATA(state, historial_tratamiento) {
      let el_tratamiento = historial_tratamiento.tratamiento
      let nombre_animal = historial_tratamiento.tratamiento.nombre_animal
      state.animal.nombre = nombre_animal
      state.tratamientos=[el_tratamiento]
    },
    SET_HISTORIAL_COMPLETO_DATA(state, historial) {
      let id_animal = historial.id_animal
      let nombre_animal = historial.nombre_animal
      let actuaciones_realizadas = historial.actuaciones
      state.animal.id_animal = id_animal
      state.animal.nombre = nombre_animal
      state.actuaciones_realizadas = [actuaciones_realizadas]
    },
    SET_NOMBRE_ANIMAL(state, nombre) {
      state.animal.nombre = nombre
    },
    SET_RESET_ANIMAL(state, animal){
      state.animal = animal;
    },
    SET_ANIMAL(state, animal) {
      state.animal = animal.animal
      state.visitas = animal.visitas
      state.tratamientos = animal.tratamientos
      state.actuaciones_realizadas = animal.actuaciones_realizadas
      state.docs = animal.docs
      state.animal_cargado = true;
    },
    SET_ANIMAL_UPDDATE(state,animal){
      let cars = state.caracteristicas_form
      animal = JSON.parse(animal)
      cars.forEach(element => {
        state.animal[element]=animal[element]
      });
    },
    SET_ACTUACION_REALIZADA_INSERT(state,actuacion_realizada){
      state.actuaciones_realizadas.unshift(actuacion_realizada)
    },
    SET_ACTUACION_REALIZADA_UPDATE(state,actuacion_realizada){
      //console.log(actuacion_realizada)
      let index = 
        state.actuaciones_realizadas
          .findIndex(actuacion => actuacion.id_ac_rel === actuacion_realizada.id_ac_rel);

      let actuacion = Object.keys(actuacion_realizada)
      
      actuacion.forEach(element => {
        state.actuaciones_realizadas[index][element]= actuacion_realizada[element];
      });
      //console.log('actuación updated')
    },
    SET_VISITA_INSERT(state,visita){
      state.visitas.unshift(visita)
    },
    SET_VISITA_UPDATE(state,visita){
      
      let index = 
        state.visitas
          .findIndex(visit => visit.id_visita === visita.id_visita);
      let visit = Object.keys(visita)
      
      visit.forEach(element => {
        state.visitas[index][element]= visita[element];
      });
    },
    SET_TRATAMIENTO_INSERT(state,tratamiento){
      state.tratamientos.unshift(tratamiento)
    },
    SET_TRATAMIENTO_UPDATE(state,tratamiento){
      if(state.tratamientos){
        let index = 
          state.tratamientos
            .findIndex(trata => trata.id_med === tratamiento.id_med);
        let trata = Object.keys(tratamiento)
        if(index > -1){
          trata.forEach(element => {
            state.tratamientos[index][element]= tratamiento[element];
          });
        }
      }
    },
    SET_FOTO_UPDATE(state, id_foto) {
      state.animal.id_foto = id_foto
    },
    SET_DOC_INSERT(state,doc){
      state.docs.unshift(doc)
    },

  },
  actions: {
    async GET_ANIMAL({ commit }, pk) {
      const res = await fetch(this._vm.$bambuvet + `/animal.php?id=${pk}`,
      {
        method: 'GET',
        credentials: 'include'
      })
      const animal = await res.json()

      commit('SET_ANIMAL', animal)
    },
    async UPDATE_ANIMAL({ commit }, form) {
      commit ('SET_ANIMAL_UPDDATE', form)
    },
    RESET_ANIMAL({ commit }) {
      let animal= {
        id: '0',
        src_thumbnail_square: '',
        id_foto: '0',
        nombre: 'nuevo'
      }
      commit ('SET_RESET_ANIMAL', animal)
    },
    async ACTUACION_REALIZADA_INSERT({ commit}, actuacion_realizada){
      commit('SET_ACTUACION_REALIZADA_INSERT', actuacion_realizada)
    },
    async ACTUACION_REALIZADA_UPDATE({ commit}, actuacion_realizada){
      commit('SET_ACTUACION_REALIZADA_UPDATE', actuacion_realizada)
    },
    async VISITA_INSERT({ commit}, visita){
      commit('SET_VISITA_INSERT', visita)
    },
    async VISITA_UPDATE({ commit}, visita){
      commit('SET_VISITA_UPDATE', visita)
    },
    async TRATAMIENTO_INSERT({ commit}, visita){
      commit('SET_TRATAMIENTO_INSERT', visita)
    },
    async TRATAMIENTO_UPDATE({ commit}, tratamiento){
      commit('SET_TRATAMIENTO_UPDATE', tratamiento)
      commit('historial/SET_TRATAMIENTO_UPDATE', tratamiento, { root: true })
      commit('historial/SET_TRATAMIENTO_UPDATE_EN_LISTA', tratamiento, { root: true })
    },
    async FOTO_UPDATE({ commit}, id_foto){
      commit('SET_FOTO_UPDATE', id_foto)
    },
    async DOC_INSERT({ commit}, doc){
      commit('SET_DOC_INSERT', doc)
    },
  },
  getters: {
    animal: state => { return state.animal },
    id_animal: state => { return state.animal.id },
    nombre_animal: state => { return state.animal.nombre },
    animal_cargado: state => { return state.animal_cargado },
    caracteristicas_form: state => { return state.caracteristicas_form },
    actuaciones_realizadas: state => { return state.actuaciones_realizadas },
    visitas: state => { return state.visitas },
    tratamientos: state => { return state.tratamientos },
    docs: state => { return state.docs },
    //listas_general_cargadas: state => { return state.listas_general_cargadas },
  }
}