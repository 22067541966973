<template>
  <div></div>
</template>

<script>
import {  createNamespacedHelpers } from "vuex";
const {  mapActions: userMapActions } =
  createNamespacedHelpers("user");
export default {
  name: "LogOut",
  data() {
    return {};
  },
  methods: {
    ...userMapActions(["LOG_OUT"]),
    LogOut() {
      this.LOG_OUT();
    },
  },
  mounted(){
    this.LOG_OUT();
  },
  computed:{
    
  }
};
</script>

<style>
</style>