import Vue from 'vue'
import Vuex from 'vuex'
import userStore from './user'
import usuariosStore from './usuarios'
import animalesStore from './animales'
import animalStore from './animal'
import tareasStore from './tareas'
import fechaslibStore from './fechaslib'
import historialStore from './historial'
import baseStore from './base'
import agendaStore from './agenda'
import papeleraStore from './papelera'
import ultimaactividadStore from './ultimaactividad'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: userStore,
    usuarios: usuariosStore,
    animales: animalesStore,
    animal: animalStore,
    tareas: tareasStore,
    fechaslib: fechaslibStore,
    historial: historialStore,
    base: baseStore,
    agenda: agendaStore,
    papelera: papeleraStore,
    ultimaactividad: ultimaactividadStore
 },
  state: {
    url_site_local: Vue.$bambuvet_web ,
    url_foto_default: Vue.$bambuvet_web + '/img/no-foto.jpg',
   
  }, 
  mutations:{
    SET_GLOBALS(state) {
      state.url_site_local= this._vm.$bambuvet 
      state.url_foto_default= this._vm.$bambuvet_web + '/img/no-foto.jpg'
    },
    SET_SESION_INICIADA(state, sesion) {
      state.bv_sesion_iniciada = sesion
    },
  },
  actions:{ 
    GET_GLOBALS({ commit }){
      commit('SET_GLOBALS', )
    }
  },
  getters: {
    url_site_local: state => {return state.url_site_local},
    url_foto_default: state => {return state.url_foto_default},
    bv_sesion_iniciada: state => {return state.bv_sesion_iniciada},
 }
  
})
