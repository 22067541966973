'use strict'

export default{
    namespaced : true,
    state: {
        fechas_lib: {}
    },
    mutations: {
        SET_LIB_FECHAS(state, fechas_lib){
            state.fechas_lib = fechas_lib
        }
    },
    actions: {
        async GET_LIB_FECHAS({commit}){
        const res = await fetch(this._vm.$bambuvet + `/fechas-lib`, {
            method: 'GET',
            credentials: 'include'
          })
        //const res = await fetch(`API/fechas-lib`)
        const lista = await res.json()
        commit ('SET_LIB_FECHAS', lista)
        }
    },
    getters: {
        fechas_lib: state =>{ return state.fechas_lib}
     } 
}