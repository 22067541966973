import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueResource from 'vue-resource';
Vue.use(VueResource)

import { BootstrapVue,IconsPlugin  } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import Croppa from 'vue-croppa'
Vue.use(Croppa) 

Vue.config.productionTip = false

Vue.prototype.$bambuvet = 'https://bambu-vet.tunait.com/API'
Vue.prototype.$bambuvet_web = 'https://bambu-vet.tunait.com'
/*
Vue.prototype.$bambuvet = (process.env.NODE_ENV === 'production') ? 'https://bambu-vet.tunait.com/API' : 'https://bambu-vet.local/API'
Vue.prototype.$bambuvet_web = (process.env.NODE_ENV === 'production') ? 'https://bambu-vet.tunait.com' : 'https://bambu-vet.tunait.local' 
*/

new Vue({
  router,
  store,
  methods:{
    fechaInvertir(fecha){
      if(fecha){
        let _fecha = fecha.split('-').reverse();
        return _fecha.join('-')
      }
    },
  },
  render: h => h(App)
}).$mount('#app')
