<template>
  <div>
    <TratamientoForm
      v-if="puede_cm_tratamientos && mostrar_form"
      :tratamiento_prop="tratamiento_prop"
      :id_med_prop="id_med"
      :id_animal_prop="id_animal_prop"
      :id_visita_prop="id_visita"
      :tratamiento_modificar_activo_prop="true"
      accion_form_prop="update"
      titulo_modal_prop="Modificar tratamiento para "
      v-on:cerrar="mostrar_form = false"
    ></TratamientoForm>

    <div class="bg-white p-3 border border-info mb-3 item-tratamiento">
      <div class="row d-flex justify-content-between">
        <div class="col-auto">
          <router-link v-if="ver_tratamiento_prop"
            class="btn btn-sm btn-secondary text-white"
            :to="{
                  name: 'Historial Tratamiento',
                  params: {
                    id_med: tratamiento_prop.id_med,id_animal: tratamiento_prop.id_animal
                  },
                }"
            >
              ver tratamiento
            </router-link>
        </div>
        <div class="col-auto align-self-end"  v-if="puede_cm_tratamientos && mostrar_edicion_prop & mostrar_edicion_tratamiento_prop">
          <button class="btn btn-cian btn-sm" @click="mostrar_form = true">
            <b-icon-pencil font-scale="1"></b-icon-pencil>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-auto" v-if="tratamiento_prop.tratamiento">
          <label class="text-cian">Tratamiento</label>
          {{ tratamiento_prop.tratamiento }}
        </div>
        <div class="col-auto" v-if="tratamiento_prop.posologia">
          <label class="text-cian">Posología</label>
          {{ tratamiento_prop.posologia }}
        </div>
        <div class="col-auto" v-if="tratamiento_prop.intervalo">
          <span class="text-cian">Cada </span> {{ tratamiento_prop.intervalo }}
          {{ tratamiento_prop.periodo == "horas" ? "horas" : "días" }}
        </div>
        <div class="col-auto" v-if="tratamiento_prop.duracion">
          <span class="text-cian">Durante</span>
          {{ tratamiento_prop.duracion }}
        </div>
        <div class="col-auto" v-if="tratamiento_prop.fecha_inicio">
          <span class="text-cian">Desde</span> el
          {{ tratamiento_prop.fecha_inicio }}
        </div>
        <div class="col-auto" v-if="tratamiento_prop.fecha_finalizacion">
          <span class="text-cian">hasta</span> el
          {{ tratamiento_prop.fecha_finalizacion }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TratamientoForm from "@/components/TratamientoForm";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: animalMapGetters } = createNamespacedHelpers("animal");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");

export default {
  name: "Tratamiento",
  data() {
    return {
      id_med: 0,
      id_animal: 0,
      id_visita: 0,
      mostrar_form: false,
    };
  },
  components: {
    TratamientoForm,
  },
  props: {
    tratamiento_prop: { default: false },
    ver_tratamiento_prop: { default: true },
    id_med_prop: { default: 0 },
    id_animal_prop: { default: 0 },
    id_visita_prop: { default: 0 },
    mostrar_edicion_prop: { default: true },
    mostrar_edicion_tratamiento_prop: { default: true },
  },
  methods: {},
  computed: {
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...userMapGetters(['puede_cm_tratamientos']),
    ...animalMapGetters({ listas_general_cargadas: "listas_general_cargadas" }),
  },
  mounted() {
    this.id_animal = this.id_animal_prop;
    this.id_visita = this.id_visita_prop;
    this.id_med = this.id_med_prop;
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@media print {
    .item-tratamiento{
      break-inside: avoid !important; 
      page-break-inside: avoid;
      
      }

}
</style>