'use strict'

export default {
  namespaced: true,
  state: {
    id_med: 0,
    id_animal: 0,
    nombre_animal: '',
    id_foto: 0,
    src_foto: '',
    src_foto_mini: '',
    id_visita: 0,
    tratamiento: false,
    listas: {
      actuaciones: [],
      visitas: [],
      tratamientos: [],
      aplicaciones: []
    },
    fechas: false,
    historial_cargado: false,
    bussy: true
  },
  mutations: {
    SET_HISTORIAL_COMPLETO(state, historial) {

      let id_animal = historial.id_animal
      let nombre_animal = historial.nombre_animal
      let id_foto = historial.id_foto
      let src_foto = historial.src_foto
      let src_foto_mini = historial.src_foto_mini
      
      let visitas = historial.visitas
      let tratamientos = historial.tratamientos
      let actuaciones = historial.actuaciones
      let fechas = historial.fechas

      state.id_animal = id_animal
      state.nombre_animal = nombre_animal
      state.id_foto = id_foto
      state.src_foto = src_foto
      state.src_foto_mini = src_foto_mini

      state.listas.visitas = visitas
      state.listas.tratamientos = tratamientos
      state.listas.actuaciones = actuaciones

      state.fechas = fechas
      state.historial_cargado=true 
      state.bussy=false
    },
    SET_HISTORIAL_TRATAMIENTOS_LISTA(state, historial_trats_lista) {
      state.listas.tratamientos = historial_trats_lista
      state.historial_cargado=true
      state.bussy=false  
    },
    SET_HISTORIAL_TRATAMIENTO(state, historial_tratamiento) {
      let id_med = historial_tratamiento.tratamiento.id_med
      let id_animal = historial_tratamiento.tratamiento.id_animal
      let nombre_animal = historial_tratamiento.tratamiento.nombre_animal
      let id_foto = historial_tratamiento.tratamiento.id_foto
      
      let id_visita = historial_tratamiento.tratamiento.id_visita
      let el_tratamiento = historial_tratamiento.tratamiento
      let aplicaciones = historial_tratamiento.aplicaciones
      state.id_med = id_med
      state.id_animal = id_animal
      state.nombre_animal = nombre_animal
      state.id_foto = id_foto
      
      state.id_visita = id_visita
      state.tratamiento = el_tratamiento
      state.listas.aplicaciones = aplicaciones

      if(id_foto){
        let src_foto =  '/imas/animales/_'+id_animal + '/thumbnail_square.jpg'
        let src_foto_mini = '/imas/animales/_'+id_animal + '/thumbnail_square_mini.jpg'

        state.src_foto = src_foto
        state.src_foto_mini = src_foto_mini
        
      }
      //console.log(state.rootState)
      state.bussy=false
    },
    SET_TRATAMIENTO_UPDATE(state,historial_tratamiento){
      let el_tratamiento = historial_tratamiento.tratamiento
      
      if(state.tratamiento && el_tratamiento){
        let claves = Object.keys(el_tratamiento)
        claves.forEach(element => {
          state.tratamiento[element]= el_tratamiento[element];
        });
        
        //console.log('actualizado en historial')
      }
     // else console.log('NO actualizado en historial')
    },
    SET_TRATAMIENTO_UPDATE_EN_LISTA(state,tratamiento){
      let index = 
        state.listas.tratamientos
          .findIndex(trata => trata.id_med === tratamiento.id_med);

      let trata = Object.keys(tratamiento)
      if(index > -1){
        trata.forEach(element => {
          state.listas.tratamientos[index][element]= tratamiento[element];
        });

      }
    },
    SET_ACTUACION_REALIZADA_INSERT(state,actuacion){
      
      state.listas.actuaciones.unshift(actuacion)
      /*let act = {
            [actuacion.fecha_ac] : [
              {id: actuacion.id_ac_rel, tipo: 'actuacion'}
            ]
          }*/
      //state.fechas.unshift(act)
      if(state.fechas){
        if(actuacion.fecha_ac in state.fechas){
          state.fechas[actuacion.fecha_ac].unshift({id: actuacion.id_ac_rel, tipo: 'actuacion'})
            console.log("si");
        }
        else{
          state.fechas[actuacion.fecha_ac]=[]
          state.fechas[actuacion.fecha_ac].unshift({id: actuacion.id_ac_rel, tipo: 'actuacion'})
        }
      }
      else{
        let act = {
          [actuacion.fecha_ac] : [
            {id: actuacion.id_ac_rel, tipo: 'actuacion'}
          ]
        }
        state.fechas = {}
        state.fechas = act
        console.log("no");
      }

    },
    SET_ACTUACION_REALIZADA_UPDATE(state,actuacion){
      //console.log('estoy en historial')
      //console.log(actuacion)
      let index = 
        state.listas.actuaciones
          .findIndex(actuac => actuac.id_ac_rel === actuacion.id_ac_rel);

      let keys = Object.keys(actuacion)
      
      keys.forEach(element => {
        state.listas.actuaciones[index][element]= actuacion[element];
      });
      //console.log('actuación updated')
    },
    SET_ACTUACION_ANULAR(state,actuacion){
      if(state.fechas){
        if(actuacion.fecha_ac in state.fechas){

          let indexF = 
          state.fechas[actuacion.fecha_ac]
            .findIndex(
              actuac => actuac.id === actuacion.id_ac_rel
              && actuac.tipo == 'actuacion'
            );
          if(indexF > -1){
            state.fechas[actuacion.fecha_ac].splice(indexF, 1);
            if(!state.fechas[actuacion.fecha_ac].length){
              delete state.fechas[actuacion.fecha_ac]; 
            }
          }
        }
      }
      
      let index = 
      state.listas.actuaciones
        .findIndex(actuac => actuac.id_ac_rel === actuacion.id_ac_rel);
      
      if(index > -1){
        state.listas.actuaciones.splice(index, 1);
      }
        
    },
    SET_VISITA_INSERT(state,visita){
      state.listas.visitas.unshift(visita)
      if(state.fechas){
        if(visita.fecha_visita in state.fechas){
          state.fechas[visita.fecha_visita].unshift({id: visita.id_visita, tipo: 'visita'})
            console.log("si");
        }
        else{
          state.fechas[visita.fecha_visita]=[]
          state.fechas[visita.fecha_visita].unshift({id: visita.id_visita, tipo: 'visita'})
        }
      }
      else{
        let act = {
          [visita.fecha_visita] : [
            {id: visita.id_visita, tipo: 'visita'}
          ]
        }
        state.fechas = act
        console.log("no");
      }
    },
    SET_VISITA_UPDATE(state,visita){
      
      let index = 
        state.listas.visitas
          .findIndex(visit => visit.id_visita === visita.id_visita);
      let visit = Object.keys(visita)
      
      visit.forEach(element => {
        state.listas.visitas[index][element]= visita[element];
      });
    },
    SET_VISITA_ANULARH(state,visita){
      console.log(visita)
      if(state.fechas){
        if(visita.fecha_visita in state.fechas){

          let indexF = 
          state.fechas[visita.fecha_visita]
            .findIndex(
              visi => visi.id === visita.id_visita
              && visi.tipo == 'visita'
            );
          if(indexF > -1){
            state.fechas[visita.fecha_visita].splice(indexF, 1);
            if(!state.fechas[visita.fecha_visita].length){
              delete state.fechas[visita.fecha_visita]; 
            }
          }
        }
      }

      let index = 
      state.listas.visitas
        .findIndex(visi => visi.id_visita === visita.id_visita);
      
      if(index > -1){
        state.listas.visitas.splice(index, 1);
      }
      
    },
    SET_TRATAMIENTO_INSERT(state,tratamiento){
      state.listas.tratamientos.unshift(tratamiento)
      if(state.fechas){
        if(tratamiento.fecha_visita in state.fechas){
          state.fechas[tratamiento.fecha_inicio].unshift({id: tratamiento.id_med, tipo: 'tratamiento'})
            console.log("si");
        }
        else{
          state.fechas[tratamiento.fecha_inicio]=[]
          state.fechas[tratamiento.fecha_inicio].unshift({id: tratamiento.id_med, tipo: 'tratamiento'})
        }
      }
      else{
        let act = {
          [tratamiento.fecha_inicio] : [
            {id: tratamiento.id_med, tipo: 'tratamiento'}
          ]
        }
        state.fechas = act
        console.log("no");
      }
    },
    SET_TRATAMIENTO_UPDATEH(state,tratamiento){
      if(state.listas.tratamientos){
        let index = 
          state.listas.tratamientos
            .findIndex(trata => trata.id_med === tratamiento.id_med);
        let trata = Object.keys(tratamiento)
        if(index > -1){
          trata.forEach(element => {
            state.listas.tratamientos[index][element]= tratamiento[element];
          });
        }
      }
    },
    SET_TRATAMIENTO_ANULARH(state,tratamiento){
      console.log(tratamiento)
      if(state.fechas){
        if(tratamiento.fecha_inicio in state.fechas){

          let indexF = 
          state.fechas[tratamiento.fecha_inicio]
            .findIndex(
              trata => trata.id === tratamiento.id_med
              && trata.tipo == 'tratamiento'
            );
          if(indexF > -1){
            state.fechas[tratamiento.fecha_inicio].splice(indexF, 1);
            if(!state.fechas[tratamiento.fecha_inicio].length){
              delete state.fechas[tratamiento.fecha_inicio]; 
            }
          }
        }
      }

      let index = 
      state.listas.tratamientos
        .findIndex(trata => trata.id_med === tratamiento.id_med);
      
      if(index > -1){
        state.listas.tratamientos.splice(index, 1);
      }
      
    },
    SET_BUSSY(state, estado){
      state.bussy=estado
    }
  },
  actions: {
    async GET_HISTORIAL_COMPLETO({ commit }, id_animal) {
      const res = await fetch(this._vm.$bambuvet + `/historial.php?id_animal=${id_animal}`, {
        method: 'GET',
        credentials: 'include'
      })
      const historial = await res.json()
      commit('SET_HISTORIAL_COMPLETO', historial) 
      //commit('animal/SET_HISTORIAL_COMPLETO_DATA', historial, { root: true })
    },
    async GET_HISTORIAL_TRATAMIENTOS_LISTA({ commit }, id_animal) {

      const res = await fetch(this._vm.$bambuvet + `/tratamientos-historial-lista.php?id_animal=${id_animal}`, {
        method: 'GET',
        credentials: 'include'
      })
      const historial_trats_lista = await res.json()
      commit('SET_HISTORIAL_TRATAMIENTOS_LISTA', historial_trats_lista) 
      //commit('animal/SET_HISTORIAL_TRATAMIENTO_DATA', historial_med, { root: true })
    },
    async GET_HISTORIAL_TRATAMIENTO({ commit }, pk) {
      //const url = commit.state.url_site_local
      //const res = await fetch(url + `/API/tratamiento-historial.php?id_med=${pk}`)
      const res = await fetch(this._vm.$bambuvet + `/tratamiento-historial.php?id_med=${pk}`, {
        method: 'GET',
        credentials: 'include'
      })
      const historial_med = await res.json()
      commit('SET_HISTORIAL_TRATAMIENTO', historial_med)
      //commit('animal/SET_HISTORIAL_TRATAMIENTO_DATA', historial_med, { root: true })
    },
    SET_NOMBRE_ANIMAL({ commit }, nombre) {
      commit('animal/SET_NOMBRE_ANIMAL', nombre, { root: true })
    },
    async ACTUACION_REALIZADA_INSERTH({ commit}, actuacion){
      commit('SET_ACTUACION_REALIZADA_INSERT', actuacion)
    },
    async ACTUACION_REALIZADA_UPDATEH({ commit}, actuacion_realizada){
      commit('SET_ACTUACION_REALIZADA_UPDATE', actuacion_realizada)
    },
    async ACTUACION_ANULARH({ commit}, actuacion_realizada){
      commit('SET_ACTUACION_ANULAR', actuacion_realizada)
    },
    async VISITA_INSERTH({ commit}, visita){
      commit('SET_VISITA_INSERT', visita)
    },
    async VISITA_UPDATEH({ commit}, visita){
      commit('SET_VISITA_UPDATE', visita)
    },
    async VISITA_ANULARH({ commit}, visita){
      commit('SET_VISITA_ANULARH', visita)
    },
    async TRATAMIENTO_INSERTH({ commit}, visita){
      commit('SET_TRATAMIENTO_INSERT', visita)
    },
    async TRATAMIENTO_UPDATEH({ commit}, tratamiento){
      commit('SET_TRATAMIENTO_UPDATEH', tratamiento)
      //commit('historial/SET_TRATAMIENTO_UPDATE', tratamiento, { root: true })
     // commit('historial/SET_TRATAMIENTO_UPDATE_EN_LISTA', tratamiento, { root: true })
    },
    async TRATAMIENTO_ANULARH({ commit}, tratamiento){
      commit('SET_TRATAMIENTO_ANULARH', tratamiento)
    },
    SET_BUSSY({commit}, estado){
      commit('SET_BUSSY', estado)
    }
  },
  getters: {
    historial: state => { return state },
    id_med: state => { return state.id_med },
    id_animal: state => { return state.id_animal },
    nombre_animal: state => { return state.nombre_animal },
    id_visita: state => { return state.id_visita },
    listas: state => { return state.listas },
    actuaciones: state => { return state.listas.actuaciones },
    visitas: state => { return state.listas.visitas },
    bussy: state => { return state.bussy }, 
  }
}
