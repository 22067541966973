<template>
  <div
    class="
      out
      d-flex
      justify-content-center
      align-items-center
      mb-3
    "
  >
    <div class="in">
      <h3>{{tituloAccion}}</h3>

     <div :class="validacion.nombre_ac_t.css">
       <input type="text" v-model="formu.nombre_ac_t" 
        class="form-control"
        >
        <span>{{validacion.nombre_ac_t.errMsg}}</span>
     </div>
        

        <div>
          <b-button
            squared
            class="mr-2 mt-3 w-auto"
            size="sm"
            type="submit"
            variant="primary"
            @click="onSubmit()"
            >Guardar</b-button
          >
          &nbsp;
          <b-button
            squared
            class="mr-2 mt-3 w-auto"
            size="sm"
            @click="enviarCerrar()"
            variant="secondary"
            >Cancelar</b-button
          >
        </div>
     
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: baseMapGetters, mapActions: baseMapActions } = createNamespacedHelpers("base");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
export default {
  name: "ActuacionTipoForm",
  data() {
    return {
      show: true,
      form_enviado: false,
      formu: {
        id_ac_t: 0,
        nombre_ac_t: "",
      },
      validacion:{
        nombre_ac_t:{
          class: '',
          errMsg: ''
        }   
      },
    };
  },
  props: {
    id_ac_t_prop: { default: 0 },
    accion_form_prop: { default: "insert" }, // insert, modify
  },
  computed: {
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...baseMapGetters({ actuaciones_nombres: "actuaciones_nombres" }),
    ...baseMapGetters({ actuaciones_tipos: "actuaciones_tipos" }),
    tituloAccion(){
      return (this.accion_form_prop=='insert') ? 'Agregar tipo de actuación' : 'Modificar tipo: '+ this.formu.nombre_ac_t
    },
  },
  created(){
    this.cargarFormuDeProp();
  },
  methods: {
    ...baseMapActions(["ACTUACION_TIPO_INSERT", "ACTUACION_TIPO_UPDATE"]),
    onSubmit(){
      if(this.formValidacion()){
       this.form_enviado = true
        this.$http
          .post(
            this.$bambuvet + "/actuacion-tipo-post.php",
            JSON.stringify(this.formu),
            {emulateJSON: true, withCredentials: true}
          )
          .then((res) => {
            //console.log(res.body);
            if(this.accion_form_prop=='insert'){
              this.ACTUACION_TIPO_INSERT(res.body)
            }
            else{
              this.ACTUACION_TIPO_UPDATE(res.body)
            }
            this.enviarIdNuevo(res.body.id_ac_t)
            this.enviarCerrar()
          }); 
      }
    },
    enviarCerrar() {
      this.$emit("cerrar");
    },
    enviarIdNuevo(id){
      this.$emit("nuevo_id", id);
    },
    cargarFormuDeProp() {
      if (this.id_ac_t_prop && this.accion_form_prop=='update') {
        this.formu.id_ac_t=this.id_ac_t_prop
        let nombre = this.actuaciones_tipos.find(item => item.id_ac_t === this.id_ac_t_prop).nombre_ac_t        
        this.formu.nombre_ac_t= nombre
      }
    },
    formValidacion(){
      let pasa = true;
      let nombre_ac_t = this.formu.nombre_ac_t.trim()
      if(nombre_ac_t.length < 3 ){
        this.validacion.nombre_ac_t.css='fieldError'
        this.validacion.nombre_ac_t.errMsg='Indica un nombre de al menos 3 caracteres'
        pasa = false
      }
      this.actuaciones_tipos.forEach(element => {
        if(element.nombre_ac_t.toLowerCase() == nombre_ac_t.toLowerCase()){
          this.validacion.nombre_ac_t.css='fieldError'
          this.validacion.nombre_ac_t.errMsg=nombre_ac_t + ' ya existe'
          pasa = false
        }
      });
      return pasa
    }
  },
};
</script>

<style>
</style>