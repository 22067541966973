<template>
  <div class="out d-flex justify-content-center align-items-center mb-3">
    <div class="in">
      <h3>{{ tituloAccion }}</h3>

      <b-form-group
        id="formu-actuacion-tipo-group"
        label="Actuación:"
        label-for="form-actuacion-tipo"
        required
      >
        <b-form-select
          id="form-actuacion-tipo"
          v-model="formu.id_ac_t"
          :options="actuaciones_tipos_prop"
          class="mb-3 custom-select"
          value-field="id_ac_t"
          text-field="nombre_ac_t"
          disabled-field="notEnabled"
          plain
          required
        >
        </b-form-select>
      </b-form-group>

      <div :class="validacion.nombre_ac.css">
        <input type="text" v-model="formu.nombre_ac" class="form-control">
        <div 
          class="alert alert-danger alert-dismissible fade show" role="alert"
          v-if="validacion.nombre_ac.errMsg"
          >{{validacion.nombre_ac.errMsg}}
          
          </div>
          
      </div>
      

      <div>
        <b-button
          squared
          class="mr-2 mt-3 w-auto"
          size="sm"
          type="submit"
          variant="primary"
          @click="onSubmit()"
          >Guardar</b-button
        >
        &nbsp;
        <b-button
          squared
          class="mr-2 mt-3 w-auto"
          size="sm"
          @click="enviarCerrar()"
          variant="secondary"
          >Cancelar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: baseMapGetters, mapActions: baseMapActions } =
  createNamespacedHelpers("base");
  const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
export default {
  name: "ActuacionNombreForm",
  data() {
    return {
      show: true,
      form_enviado: false,
      formu: {
        id_ac_t: 0,
        id_ac: 0,
        nombre_ac: "",
      },
      validacion:{
        nombre_ac:{
          css: '',
          errMsg: ''
        }   
      },
    };
  },
  props: {
    id_ac_prop: { default: 0 },
    id_ac_t_prop: { default: 0 },
    actuaciones_tipos_prop: [],
    accion_form_prop: { default: "insert" }, // insert, modify
  },
  computed: {
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...baseMapGetters({ actuaciones_nombres: "actuaciones_nombres" }),
    ...baseMapGetters({ actuaciones_tipos: "actuaciones_tipos" }),
    tituloAccion() {
      return this.accion_form_prop == "insert"
        ? "Agregar actuación"
        : "Modificar : " + this.formu.nombre_ac;
    },
    actuacionesTiposOrdenada() {
      let items = this.actuaciones_tipos
      items.sort(function (a, b) {
        if (a.nombre_ac_t > b.nombre_ac_t) {
          return 1;
        }
        if (a.nombre_ac_t < b.nombre_ac_t) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      return items
    },
  },
  created() {
    this.cargarFormuDeProp();
    //console.log(this.accion_form_prop);
  },
  methods: {
    ...baseMapActions(["ACTUACION_NOMBRE_INSERT", "ACTUACION_NOMBRE_UPDATE"]),
    onSubmit() {
      if(this.formValidacion()){
        this.form_enviado = true;
        this.$http
          .post(
            this.$bambuvet + "/actuacion-nombre-post.php",
            JSON.stringify(this.formu),
            {emulateJSON: true, withCredentials: true}
          )
          .then((res) => {
            //console.log(res.body);
            if (this.accion_form_prop == "insert") {
              this.ACTUACION_NOMBRE_INSERT(res.body);
            } else {
              this.ACTUACION_NOMBRE_UPDATE(res.body);
            }
            this.enviarIdNuevo(res.body.id_ac_t, res.body.id_ac);
            this.enviarCerrar()
          });
      }
    },
    enviarCerrar() {
      this.$emit("cerrar");
    },
    enviarIdNuevo(id_act_t, id) {
      this.$emit("nuevo_id", id_act_t, id);
    },
    cargarFormuDeProp() {
      this.formu.id_ac_t = this.id_ac_t_prop;
      if (
        this.id_ac_t_prop &&
        this.id_ac_prop &&
        this.accion_form_prop == "update"
      ) {
        this.formu.id_ac = this.id_ac_prop;


        let nombre_ac = this.actuaciones_nombres.find(
          (item) => item.id_ac === this.id_ac_prop
        ).nombre_ac;
        this.formu.nombre_ac = nombre_ac;
      }
    },
    formValidacion(){
      let pasa = true;
      let nombre_ac = this.formu.nombre_ac.trim()
      let id_ac_t = this.formu.id_ac_t
      if(nombre_ac.length < 3 ){
        this.validacion.nombre_ac.css='fieldError'
        this.validacion.nombre_ac.errMsg='Indica un nombre de al menos 3 caracteres'
        pasa = false
      }
      this.actuaciones_nombres.forEach(element => {
        if(
          element.id_ac_t == id_ac_t &&
          element.nombre_ac.toLowerCase() == nombre_ac.toLowerCase()
          ){
            let nombre_ac_t = '';
            let index = 
                this.actuaciones_tipos
                  .findIndex(ac => ac.id_ac_t === id_ac_t);
            if(index > -1){
              nombre_ac_t = this.actuaciones_tipos[index].nombre_ac_t
            }

            this.validacion.nombre_ac.css='fieldError'
            let errMsg = nombre_ac + ' ya existe'
            if(nombre_ac_t) errMsg += ' para ' + nombre_ac_t
            this.validacion.nombre_ac.errMsg= errMsg
            pasa = false
        }
      });
      return pasa
    }
  },
};
</script>

<style>
</style>