<template>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-md-6 list-group list-group-flush text-start ">
        <h3 class="h5 text-info text-start">Última actividad</h3>
        <div v-for="(item, index) in actividad.ultimos" :key="index">
          <router-link
              :to="{
                name: 'animal',
                params: { nombre: item.nombre_animal, id: item.id_animal },
              }"
              class="list-group-item list-group-item-action"
            >
            <div class="d-flex">
              <div class="w-auto ">
                <b-img-lazy
                  class="rounded-circle img-avatar"
                  fluid-grow
                  :src="item.fotos.src_thumbnail_square_mini"
                  :alt="item.nombre_animal"
                />
              </div>
              <div>
                <div class="d-flex  ms-3 mt-2">
                  <h5 class="mb-1">{{item.nombre_animal}}</h5>
                </div>
                <ul class="list-group list-group-flush mt-0">
                  <li 
                    class="list-group-item pt-0"
                    v-for="(actividad, index2) in item.actividad" :key="index2"
                    ><small class="text-muted">{{actividad.text}}</small>
                    
                    </li>
                </ul>
              </div>
            </div>
          
          
          
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from "vuex";
const { mapGetters: ultimaactividadMapGetters, mapActions: ultimaactividadMapActions } =
  createNamespacedHelpers("ultimaactividad");
export default {
  name: "UltimaActividad",
  data() {
    return {

    }
  },
  props:{
    user_loged_in_prop: {default: false}
  },
  created (){
    this.loadData()
  },
  methods:{
    loadData(){
      if (this.user_loged_in_prop) {
        this.GET_ULTIMA_ACTIVIDAD();
      }
    },
    ...ultimaactividadMapActions(["GET_ULTIMA_ACTIVIDAD"]),
  },
  computed:{
    ...mapGetters(["url_site_local"]),
    ...ultimaactividadMapGetters(["actividad"]),
  }
}
</script>