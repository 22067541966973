'use strict'

export default {
  namespaced: true,
  state: {
    veterinarios: false,
    centros_vet: false,
    actuaciones_tipos: false,
    actuaciones_nombres: false,
    caracteristicas: {
      especies: [],
      estados: [],
      grupos: [],
      sexos: [],
      situacion: [],
      tamanios: [],
    },
    caracteristicas_cargadas: false,
    base_animal_cargado: false,
  },
  mutations: {
    SET_LISTAS_GENERAL(state, listas) {
      let veterinarios = listas.listas_general.veterinarios
      let centros_vet = listas.listas_general.centros_vet
      let actuaciones_tipos = listas.listas_general.actuaciones_tipos
      let actuaciones_nombres = listas.listas_general.actuaciones

      let caracteristicas = listas.caracteristicas

      state.veterinarios = veterinarios
      state.centros_vet = centros_vet
      state.actuaciones_tipos = actuaciones_tipos
      state.actuaciones_nombres = actuaciones_nombres
      state.caracteristicas = caracteristicas
      state.base_animal_cargado = true
      state.caracteristicas_cargadas = true
      //console.log('listas en base')
    },
    SET_ACTUACION_TIPO_INSERT(state, actuacion_tipo) {
      state.actuaciones_tipos.unshift(actuacion_tipo)
    },
    SET_ACTUACION_TIPO_UPDATE(state, actuacion_tipo) {
      let index =
        state.actuaciones_tipos
          .findIndex(actuacion => actuacion.id_ac_t === actuacion_tipo.id_ac_t);

      if (index > -1) {
        let actuacion = Object.keys(actuacion_tipo)

        actuacion.forEach(element => {
          state.actuaciones_tipos[index][element] = actuacion_tipo[element];
        });
       // console.log('actuación tipo updated')
      }
    },
    SET_ACTUACION_NOMBRE_INSERT(state, actuacion_nombre) {
      state.actuaciones_nombres.unshift(actuacion_nombre)
    },
    SET_ACTUACION_NOMBRE_UPDATE(state, actuacion_nombre) {
      let index =
        state.actuaciones_nombres
          .findIndex(actuacion => actuacion.id_ac === actuacion_nombre.id_ac);

      if (index > -1) {
        let actuacion = Object.keys(actuacion_nombre)

        actuacion.forEach(element => {
          state.actuaciones_nombres[index][element] = actuacion_nombre[element];
        });
        //console.log('actuación nombre updated')
      }
    },
    SET_CENTRO_VET_INSERT(state, centro_vet) {
      state.centros_vet.unshift(centro_vet)
    },
    SET_CENTRO_VET_UPDATE(state, centro_vet) {
      let index =
        state.centros_vet
          .findIndex(item => item.id_cv === centro_vet.id_cv);

      if (index > -1) {
        let cv = Object.keys(centro_vet)

        cv.forEach(element => {
          state.centros_vet[index][element] = centro_vet[element];
        });
        //console.log('centro vet updated')
      }
    },
    SET_VET_INSERT(state, vet) {
      state.veterinarios.unshift(vet)
    },
    SET_VET_UPDATE(state, vet) {
      let index =
        state.veterinarios
          .findIndex(item => item.id_vet === vet.id_vet);

      if (index > -1) {
        let vete = Object.keys(vet)

        vete.forEach(element => {
          state.veterinarios[index][element] = vet[element];
        });
        //console.log('vet updated')
      }
    },
  },
  actions: {
    async GET_LISTAS_GENERAL({ commit }) {
      //console.log('yuju')
      const res = await fetch(this._vm.$bambuvet + `/listas-general.php`, {
        method: 'GET',
        credentials: 'include'
      })
      //const res = await fetch(`API/listas-general.php`)
      
      const listas_general = await res.json()
      commit('SET_LISTAS_GENERAL', listas_general)
    },
    async ACTUACION_TIPO_INSERT({ commit }, actuacion_tipo) {
      commit('SET_ACTUACION_TIPO_INSERT', actuacion_tipo)
    },
    async ACTUACION_TIPO_UPDATE({ commit }, actuacion_tipo) {
      commit('SET_ACTUACION_TIPO_UPDATE', actuacion_tipo)
    },
    async ACTUACION_NOMBRE_INSERT({ commit }, actuacion_nombre) {
      commit('SET_ACTUACION_NOMBRE_INSERT', actuacion_nombre)
    },
    async ACTUACION_NOMBRE_UPDATE({ commit }, actuacion_nombre) {
      commit('SET_ACTUACION_NOMBRE_UPDATE', actuacion_nombre)
    },
    async CENTRO_VET_INSERT({ commit }, centro_vet) {
      commit('SET_CENTRO_VET_INSERT', centro_vet)
    },
    async CENTRO_VET_UPDATE({ commit }, centro_vet) {
      commit('SET_CENTRO_VET_UPDATE', centro_vet)
    },
    async VET_INSERT({ commit }, vet) {
      commit('SET_VET_INSERT', vet)
    },
    async VET_UPDATE({ commit }, vet) {
      commit('SET_VET_UPDATE', vet)
    },
  },
  getters: {
    base: state => { return state },
    veterinarios: state => { return state.veterinarios },
    centros_vet: state => { return state.centros_vet },
    actuaciones_tipos: state => { return state.actuaciones_tipos },
    actuaciones_nombres: state => { return state.actuaciones_nombres },
    caracteristicas: state => { return state.caracteristicas },
    especies: state => { return state.caracteristicas.especies },
    sexos: state => { return state.caracteristicas.sexos },
    tamanios: state => { return state.caracteristicas.tamanios },
    estados: state => { return state.caracteristicas.estados },
    grupos: state => { return state.caracteristicas.grupos },
    base_animal_cargado: state => { return state.base_animal_cargado },
    caracteristicas_cargadas: state => { return state.caracteristicas_cargadas },
  },
}