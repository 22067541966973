<template>
  <div class="visita">
    <div class="bg-light p-3 border border-info mb-3">
      <div class="row d-flex justify-content-between">
        <div class="col-auto ps-1">
          <h4 v-bind:class="[claseRealizada, 'h6']">
            
            <b-icon
              icon="check"
              v-if="visita_prop.estado == 'realizada'"
            ></b-icon>
            <b-icon v-else icon="calendar3" class="text-danger me-1"></b-icon>
            <router-link
              
              :to="{
                name: 'Historial visita',
                params: {
                  id_visita: id_visita,
                  id_animal: id_animal
                },
              }"
            >
            Visita {{ fechaLocal }}</router-link> [id: {{ id_visita }}]
          </h4>
        </div>
        <div class="col-auto align-self-end" v-if="mostrar_edicion && puede_cm_visitas">
          <button class="btn btn-cian btn-sm" @click="mostrar_form = true">
            <b-icon-pencil font-scale="1"></b-icon-pencil>
          </button>
        </div>
      </div>

      <div class="row bg-cian ms-0 me-0 p-1 visita-info">
        <div class="col-auto">
          <span class="label">Estado:</span> {{ visita_prop.estado }}
        </div>
        <div class="col-auto">Visita: {{ visita_prop.tipo_visita }}</div>
        <div class="col-auto">Fecha: {{ visita_prop.fecha_visita }}</div>
        <div class="col-auto" v-if="nombre_veterinario">
          Veterinario: {{ nombre_veterinario }}
        </div>
        <div class="col-auto" v-if="nombre_centro_vet">
          Centro vet.: {{ nombre_centro_vet }}
        </div>
      </div>
      <div v-if="visita_prop.diagnostico">
        <h4 class="mt-3 h6 mb-3"><span class="bg-cian p-1">Diagnóstico</span></h4>
        {{ visita_prop.diagnostico }}
      </div>
      <div v-if="visita_prop.observaciones_visita" class="col-auto">
        <h4 class="mt-3 h6 mb-3">
          <span class="bg-cian p-1">Observaciones</span>
        </h4>
        {{ visita_prop.observaciones_visita }}
      </div>
      <div v-if="visita_prop.comentarios" class="col-auto">
        <h4 class="mt-3 h6 mb-3"><span class="bg-cian p-1">Comentarios</span></h4>
        {{ visita_prop.comentarios }}
      </div>

      <ActuacionesLista
        v-if="mostrar_actuaciones" 
        :actuaciones_realizadas_prop="actuacionesRealizadasVisita"
        :id_visita_prop="visita_prop.id_visita"
        :carga_en_visita_prop="true"
        :fecha_visita_prop="visita_prop.fecha_visita"
        :mostrar_agregar_actuacion_prop="mostrar_agregar_actuacion_prop"
        :mostrar_edicion_actuacion_prop="mostrar_edicion_actuacion_prop"
      ></ActuacionesLista>

      <div
        v-if="
          mostrar_tratamientos_prop 
        "
      >
        <TratamientosLista 
        :id_visita_prop="id_visita"
        :carga_en_visita_prop="true"
        :mostrar_tratamientos_prop="mostrar_tratamientos_prop"
        :mostrar_agregar_tratamiento_prop="mostrar_agregar_tratamiento_prop"
        :mostrar_edicion_tratamiento_prop="mostrar_edicion_tratamiento_prop"
        ></TratamientosLista>
      </div>

      <div>
        <DocsLista
              :docs_prop="docsVisita"
              :id_animal_prop="id_animal_prop"
              :id_visita_prop="id_visita"
              :mostrar_agregar_doc_prop="mostrar_agregar_doc_prop"
            ></DocsLista>
      </div>
    </div>

    <VisitaForm
      v-if="mostrar_form && puede_cm_visitas"
      :visita_prop="visita_prop"
      :id_animal_prop="id_animal_prop"
      :id_visita_prop="id_visita"
      :visita_modificar_activa_prop="true"
      accion_form_prop="update"
      titulo_modal_prop="Modificar visita"
      v-on:cerrar="mostrar_form = false"
    ></VisitaForm>
  </div>
</template>

<script>
import ActuacionesLista from "@/components/ActuacionesLista";
import VisitaForm from "@/components/VisitaForm";
import TratamientosLista from "@/components/TratamientosLista";
import DocsLista from "@/components/DocsLista";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: baseMapGetters } = createNamespacedHelpers("base");
const { mapGetters: animalMapGetters } = createNamespacedHelpers("animal");
const { mapGetters: historialMapGetters } = createNamespacedHelpers("historial");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");

export default {
  name: "Visita",
  data() {
    return {
      id_animal: 0,
      id_visita: 0,
      visita_modificar_activa: false,
      mostrar_form: 0,
      tipos_visita: ["Revision", "Rutinaria", "Seguimiento", "Urgencia"],
      mostrar_actuaciones: true,
      mostrar_tratamientos: true,
      mostrar_edicion: false,
    };
  },
  components: {
    ActuacionesLista,
    VisitaForm,
    TratamientosLista,
    DocsLista,
  },
  props: {
    id_animal_prop: { default: 0 },
    id_visita_prop: { default: 0 },
    visita_prop: { default: false },
    agregar_visita_prop: { default: false },
    mostrar_actuaciones_prop: { default: true },
    mostrar_tratamientos_prop: { default: true },
    mostrar_edicion_prop: { default: true },
    mostrar_agregar_actuacion_prop: { default: true },
    mostrar_edicion_actuacion_prop: { default: true },
    mostrar_agregar_tratamiento_prop: { default: true },
    mostrar_edicion_tratamiento_prop: { default: true },
    mostrar_agregar_doc_prop: { default: true },
  },
  methods: {
    actualizarData() {
      this.id_animal = this.id_animal_prop;
      this.id_visita = this.id_visita_prop;
      this.mostrar_actuaciones = this.mostrar_actuaciones_prop;
      this.mostrar_tratamientos = this.mostrar_tratamientos_prop;
      this.mostrar_edicion = this.mostrar_edicion_prop;
    },
  },
  created() {},
  mounted() {
    this.actualizarData();
  },
  computed: {
    claseRealizada() {
      let clase = this.estado == "programada" ? "text-danger" : "text-cian";
      return clase;
    },
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...userMapGetters(['puede_cm_visitas']),
    ...historialMapGetters(["historial"]),
    //...animalMapGetters({ actuaciones_realizadas: "actuaciones_realizadas" }),
    ...animalMapGetters({ docs: "docs" }),
    ...baseMapGetters({ base_animal_cargado: "base_animal_cargado" }),
    ...baseMapGetters({ centros_vet: "centros_vet" }),
    ...baseMapGetters({ veterinarios: "veterinarios" }),
    ...baseMapGetters({ actuaciones_tipos: "actuaciones_tipos" }),
    ...baseMapGetters({ actuaciones_nombres: "actuaciones_nombres" }),
    actuacionesRealizadasVisita() {
      if (this.base_animal_cargado && this.visita_prop.id_visita) {
        //return this.actuaciones_realizadas.filter(
        return this.historial.listas.actuaciones.filter(
          (obj) => obj.id_visita == this.visita_prop.id_visita
        );
      }
      return false;
    },
    tratamientosRealizadosVisita() {
      if (this.base_animal_cargado && this.visita_prop.id_visita) {
        //if(this.tratamientos && this.tratamientos.length){
         if(this.historial.listas.tratamientos && this.historial.listas.tratamientos.length){
          return this.historial.listas.tratamientos.filter(
            (obj) => obj.id_visita == this.visita_prop.id_visita
          );
        }
      }
      return false;
    },
    docsVisita() {
      if (this.base_animal_cargado && this.visita_prop.id_visita) {
        //if(this.tratamientos && this.tratamientos.length){
         if(this.docs && this.docs.length){
          return this.docs.filter(
            (obj) => obj.id_visita == this.visita_prop.id_visita
          );
        }
      }
      return false;
    },
    fechaLocal() {
      let date = new Date(this.visita_prop.fecha_visita);
      let formatDate = (date) => {
        let formatted_date =
          date.getDate() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getFullYear();
        return formatted_date;
      };
      return formatDate(date);
    },
    nombre_centro_vet() {
      if (this.base_animal_cargado) {
        let nombre_cv = "";
        if (this.visita_prop.id_cv) {
          let obj_centro_vet = this.centros_vet.find(
            (item) => item.id_cv === this.visita_prop.id_cv
          );

          if (obj_centro_vet) {
            nombre_cv = obj_centro_vet.nombre_cv;
          }
        }
        return nombre_cv;
      } else return false;
    },
    nombre_veterinario() {
      if (this.base_animal_cargado) {
        let nombre_vet = "";
        if (this.visita_prop.id_vet) {
          let obj_vet = this.veterinarios.find(
            (item) => item.id_vet === this.visita_prop.id_vet
          );

          if (obj_vet) {
            nombre_vet = obj_vet.nombre_vet;
          }
        }
        return nombre_vet;
      } else return false;
    },
  },
  watch: {
    mostrar_actuaciones_prop: function () {
      this.actualizarData();
    },
  },
};
</script>
<style lang="scss" scoped>
.visita-info {
  font-weight: normal;
}
</style>