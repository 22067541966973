'use strict'

export default {
  namespaced: true,
  state: {
    usuarios: false,
    usuario: false
  },
  mutations: {
    SET_USUARIOS(state, usuarios) {
      state.usuarios = usuarios
    },
    SET_USUARIO(state, usuario) {
      state.usuario = usuario
    },
    SET_NUEVO_USUARIO(state, usuario){
      state.usuarios.unshift(usuario);
    },
    SET_UPDATE_USUARIO(state, usuario){
      let index = 
        state.usuarios
          .findIndex(usu => usu.id_usuario === usuario.id_usuario);
      
      let keys = Object.keys(usuario)
      
      keys.forEach(element => {
        state.usuarios[index][element]= usuario[element];
      });
    }
  },
  actions: {
    async GET_USUARIOS({ commit }) {
      //console.log('$bambuvet en usuarios: ' + this._vm.$bambuvet)
      const res = await fetch(this._vm.$bambuvet + `/usuarios-lista.php`, 
      {
        method: 'GET',
        credentials: 'include'
      })
      const lista = await res.json()
      commit('SET_USUARIOS', lista)
    },
    async GET_USUARIO({ commit }, pk) {
      const res = await fetch(this._vm.$bambuvet + `/usuario.php?id_usuario=${pk}`, {
        method: 'GET',
        credentials: 'include'
      })
      const usuario = await res.json()
      commit('SET_USUARIO', usuario)
    },
    async UPDATE_USUARIO({ commit }, usuario) {
      commit('SET_UPDATE_USUARIO', usuario)
    },
    async INSERT_USUARIO({ commit }, usuario) {
      commit('SET_NUEVO_USUARIO', usuario)
    },
  },
  getters: {
    usuarios: state => { return state.usuarios },
    usuario: state => { return state.usuario }
  }
}