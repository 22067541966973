<template>
  <div class="out full d-flex justify-content-center align-items-center mb-3">
    <div class="in">
      <image-uploader
        :preview="true"
        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
        :capture="false"
        :debug="1"
        :quality="0.7"
        doNotResize="gif"
        :autoRotate="true"
        outputFormat="verbose"
        @input="setImage"
      >
      </image-uploader>

      <label for="fileInput" slot="upload-label">
        <b-icon icon="camera" font-scale="2"></b-icon>
        <br />
        <span class="upload-caption btn btn-info btn-sm">
          {{ hasImage ? "Cambiar" : "Click para seleccionar" }}
        </span>
      </label>
      <br />
      <b-button
        squared
        class="me-2 mt-3 w-auto"
        size="sm"
        @click="enviar()"
        variant="info"
        :disabled="!hasImage"
        >Enviar</b-button
      >
      <b-button
        squared
        class="mr-2 mt-3 w-auto"
        size="sm"
        @click="enviarCerrar()"
        variant="secondary"
        >Cancelar</b-button
      >
      <div
        class="out d-flex justify-content-center align-items-center mb-3"
        v-if="croppaInit"
      >
        <div class="in-in">
          <croppa
            v-model="croppa"
            :width="300"
            :height="300"
            :initial-image="image.dataUrl"
            :prevent-white-space="true"
            :show-remove-button="false"
            :show-loading="true"
            :quality="1"
          >
          </croppa>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUploader from "vue-image-upload-resize";
import "vue-croppa/dist/vue-croppa.css";
export default {
  name: "ImageUpload",
  data() {
    return {
      hasImage: false,
      image: null,
      croppa: {},
      initialImage: null,
      croppaInit: false,
      croppa_image: "",
      formu: {
        id_animal: 0,
        imagen: "",
        thumb_sqare: "",
      },
    };
  },
  components: {
    ImageUploader,
  },
  props: {
    show_prop: { default: true },
    id_animal_prop: { default: 0 },
  },
  methods: {
    setImage: function (output) {
      //image-resize
      this.hasImage = true;
      this.image = output;
      //console.log("Info", output.info);
      //console.log("Exif", output.exif);
    },
    enviarCerrar() {
      this.$emit("cerrar");
    },
    cambiarCroppa() {
      if (!this.croppaInit) {
        if (this.hasImage) {
          setTimeout(this.iniciarCroppa, 500);
        }
      }
    },
    iniciarCroppa() {
      this.croppaInit = true;
    },
    cargarFormu() { //creo que no se usa
      (this.formu.id_animal = this.id_animal_prop),
        (this.formu.imagen = this.image.dataUrl),
        (this.formu.thumb_sqare = this.croppa.generateDataUrl());
    },
    enviar() {
      let data = {
        id_animal: this.id_animal_prop,
        imagen: this.image.dataUrl,
        thumb_square: this.croppa.generateDataUrl()
      }
      this.$emit("enviarImagen",data)
    }
  },
  watch: {
    image: function () {
     // console.log("he cambiao");
      this.formu.imagen = this.image.dataUrl;
      this.croppaInit = false;
      this.cambiarCroppa();
      //this.croppaInit = true
    },
  },
};
</script>

<style lang="scss" >
#fileInput {
  display: none !important;
}
label {
  display: inline-block;
  margin: auto;
  figure {
    display: inline-block;
  }
}
.out .out {
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  bottom: 150px;
  background: transparent;
  background-color: rgb(68, 68, 68, 0.8);
  width: auto;
}
.in {
  text-align: center;
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  padding: 1rem !important;
  overflow: auto;
  position: relative;
}
.in-in {
  text-align: center;
  width: 100%;
  max-width: 600px;
 
  padding: 1rem !important;
  overflow: auto;
  
}
.img-preview {
  width: auto;
  max-width: 100%;
  max-height: 600px;
  margin-bottom: 1rem;
}
</style>