<template>
  <div v-if="doc_prop">
   
      <b-icon :icon="Icon" :class="ClassIcon"></b-icon>
    
      <a :href="doc_prop.uri_doc" target="_blank"> {{ doc_prop.descripcion_doc ? doc_prop.descripcion_doc : doc_prop.nombre_doc }}</a>

  </div>
</template>

<script>
export default {
  name: "Doc",
  data() {
    return {
      icon_default: {
        icon: "file-richtext",
        class: "richtext",
      },
      icons: [
        {
          type: "image/jpeg",
            icon: "file-image",
            class: "imagen",
          },
          {
            type: "application/pdf",
            icon: "file-richtext",
            class: "richtext",
          },
        {
          type: "application/msword",
            icon: "file-word",
            class: "word",
          },
        {
          type: "application/vnd.oasis.opendocument.text",
            icon: "file-word",
            class: "word",
          },
        {
          type: "application/rtf",
            icon: "file-richtext",
            class: "word",
          },
        {
          type: "text/plain",
            icon: "file-text",
            class: "text",
          },
      ],
    };
  },
  props: {
    doc_prop: { default: false },
  },
  computed: {
    Icon() {
      let icon = this.icon_default.icon
      if (this.doc_prop.type) {

        let res = this.icons.find(
              (item) => item.type === this.doc_prop.type
            ).icon;
        if(res) icon = res
//console.log(res)
        
        //return this.icons[this.doc_prop.type].icon;
      } //else return this.icon_default.icon;
      return icon
    },
    ClassIcon() {
      return this.icon_default.class;
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  color: grey;
}
.word {
  color: #037ef3;
}
.richtext {
  color: #00a4e4;
}
.imagen {
  color: #fbb034;
}
</style>