var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{attrs:{"id":"ficha"}},[_c('Breadcrumb',{attrs:{"items_prop":_vm.itemsBreadcrumb}}),_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"lg":"5"}},[_c('b-row',{staticClass:"sticky-md-top"},[_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('h1',{staticClass:"h2"},[_vm._v("Ficha de "+_vm._s(_vm.animal.nombre))])]),_c('b-col',{attrs:{"xl":"5"}},[(_vm.ShowImageUpload && _vm.puede_cm_animales && !_vm.es_nuevo_prop)?_c('ImageUpload',{attrs:{"id_animal_prop":_vm.id},on:{"cerrar":function($event){return _vm.cerrarImageUpload()},"enviarImagen":_vm.enviarImagenUpload}}):_vm._e(),_c('img',{staticClass:"foto-carnet mb-2 img-fluid",attrs:{"src":_vm.src_imagen,"alt":_vm.animal.nombre},on:{"click":function($event){return _vm.abrirImageUpload()}}}),_c('br')],1),(!_vm.es_nuevo_prop)?_c('b-col',{staticStyle:{"text-align":"left"},attrs:{"col":""}},[(_vm.id)?_c('router-link',{staticClass:"btn btn-info btn-sm mb-2 me-2",attrs:{"to":{
                name: 'Historial completo',
                params: {
                  id_animal: _vm.id,
                },
              }}},[_vm._v("Ver Historial")]):_vm._e(),(_vm.puede_cm_animales && !_vm.es_nuevo_prop)?_c('b-button',{staticClass:"mb-2",attrs:{"squared":"","size":"sm","variant":"info","pressed":_vm.modo_form},on:{"update:pressed":function($event){_vm.modo_form=$event}}},[_vm._v(" "+_vm._s(_vm.modo_form ? "Volver" : "Modificar datos")+" ")]):_vm._e(),_c('AnimalCaracteristicas',{attrs:{"animal":_vm.animal,"modo_form_prop":_vm.modo_form},on:{"cambiar_modo_form":function($event){_vm.modo_form = $event}}})],1):_vm._e(),_c('b-col',{attrs:{"sm":"12"}})],1)],1),_c('b-col',{attrs:{"lg":"7 mt-1"}},[(_vm.puede_cm_animales && _vm.modo_form)?_c('AnimalCaracteristicasForm',{attrs:{"animal":_vm.animal,"es_nuevo_prop":_vm.es_nuevo_prop},on:{"cambiar_modo_form":function($event){_vm.modo_form = $event},"update_animal_form":_vm.UPDATE_ANIMAL,"insert_animal_form":_vm.ADD_ANIMAL,"update_animales_listado":_vm.UPDATE_NOMBRE_ANIMAL,"cerrar_caracteristicas_form":function($event){_vm.modo_form = false}}}):_vm._e(),(!_vm.es_nuevo_prop)?_c('div',[(_vm.sistema_archivos)?_c('div',[_c('h2',[_vm._v("Documentos")]),_c('DocsLista',{attrs:{"docs_prop":_vm.docs,"id_animal_prop":_vm.animal.id}})],1):_vm._e(),_c('b-row',{staticClass:"mt-2 heading_underline",attrs:{"alignv":"top"}},[_c('b-col',{staticClass:"d-flex"},[_c('h2',{staticClass:"h4"},[_vm._v("Actividad")])]),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-dropdown',{attrs:{"text":_vm.filtro_actividades,"right":""}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.filtroActividades('Todas')}}},[_vm._v("Todas")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.filtroActividades('Actuaciones')}}},[_vm._v("Actuaciones")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.filtroActividades('Tratamientos')}}},[_vm._v("Tratamientos")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.filtroActividades('Visitas')}}},[_vm._v("Visitas")])],1)],1)],1),(
              _vm.mostrarActuaciones &&
              _vm.base_animal_cargado &&
              _vm.actuacionesRealizadas &&
              _vm.actuaciones_tipos
            )?_c('ActuacionesLista',{attrs:{"actuaciones_realizadas_prop":_vm.actuacionesRealizadas}}):_vm._e(),(_vm.mostrarTratamientos)?_c('TratamientosLista'):_vm._e(),(_vm.mostrarVisitas)?_c('VisitasLista'):_vm._e()],1):_vm._e(),(_vm.animal.notas_internas)?_c('div',[_c('h2',{staticClass:"h4"},[_vm._v("Notas internas")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.animal.notas_internas)}})]):_vm._e()],1)],1),_c('Loading',{attrs:{"bussy_prop":_vm.BussyLoading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }