'use strict'

export default {
  namespaced: true,
  state: {
    fecha_hoy: '',
    fecha_consulta: '',
    animales_activos: {},
    tratamientos_activos: {},
    tratamientos_activos_por_fechas:false, 
    tratamientos_activos_x_id: {}
  },
  mutations: {
    SET_TAREAS(state, lista) {
      //console.log('lista: ' + lista)
      let slistas = Object.keys(lista)
     // console.log('keys: ' + slistas)
      slistas.forEach(element => {
        if (element == 'fecha_hoy') {
          state.fecha_hoy = lista[element];
        }
        if (element == 'fecha_consulta') {
          state.fecha_consulta = lista[element];
        }
        if (element == 'animales_activos') {
          state.animales_activos = lista[element];
        }
        if (element == 'tratamientos_activos') {
          state.tratamientos_activos = lista[element];
        }
        if (element == 'tratamientos_activos_por_fechas') {
          state.tratamientos_activos_por_fechas = lista[element];
        }
        if (element == 'tratamientos_activos_x_id') {
          state.tratamientos_activos_x_id = lista[element];
        }
      });
    },
    SET_TAREA_APLICACION_INSERT(state,tarea_aplicacion){
      //console.log(tarea_aplicacion)
      /*
      let fecha = tarea_aplicacion.fecha
      let obj_tarea_aplicacion =  
        state.tratamientos_activos_por_fechas[fecha]['t_'+tarea_aplicacion.id_med]['aplicaciones_dia']["'" + tarea_aplicacion.orden + "'"];
      
        if(obj_tarea_aplicacion){ 
        let obj_tarea_aplicacion_keys = Object.keys(tarea_aplicacion)

        obj_tarea_aplicacion_keys.forEach(element => {
          state.tratamientos_activos_por_fechas[fecha]['t_'+tarea_aplicacion.id_med]['aplicaciones_dia']["'" + tarea_aplicacion.orden + "'"][element]= tarea_aplicacion[element];
        });
        */
        /////////////////////////////////////////
        console.log('entro en insertar tarea')
        console.log(tarea_aplicacion)
        let fecha = tarea_aplicacion.fecha
        console.log(fecha)
        if(fecha in state.tratamientos_activos_por_fechas){
          console.log('entro en tratas por fechas ')
          let obj_med ='t_'+tarea_aplicacion.id_med
          if(obj_med in state.tratamientos_activos_por_fechas[fecha]){
            console.log('entro en tratas por fechas ' + obj_med)
            let obj_orden = tarea_aplicacion.orden
            
              state.tratamientos_activos_por_fechas[fecha][obj_med].aplicaciones_dia[obj_orden] = tarea_aplicacion
            
          }
        }
      //}
    },
    SET_TAREA_APLICACION_UPDATE(state,tarea_aplicacion){

      let fecha = tarea_aplicacion.fecha
      let obj_tarea_aplicacion =  
        state.tratamientos_activos_por_fechas[fecha]['t_'+tarea_aplicacion.id_med]['aplicaciones_dia']["'" + tarea_aplicacion.orden + "'"];
      

        if(obj_tarea_aplicacion){
        let obj_tarea_aplicacion_keys = Object.keys(tarea_aplicacion)

        obj_tarea_aplicacion_keys.forEach(element => {
          state.tratamientos_activos_por_fechas[fecha]['t_'+tarea_aplicacion.id_med]['aplicaciones_dia']["'" + tarea_aplicacion.orden + "'"][element]= tarea_aplicacion[element];
        });
        
      }
    },
  },
  actions: {
    async GET_TAREAS({ commit }, fecha) {
      let url = this._vm.$bambuvet + `/tareas`
      if(fecha) url = this._vm.$bambuvet + `/tareas-${fecha}`
      //console.log(url)
      const res = await fetch(url, {
        method: 'GET',
        credentials: 'include'
      })
      const lista = await res.json()
      commit('SET_TAREAS', lista)
    },
    async TAREA_APLICACION_INSERT({ commit }, tarea_aplicacion) {
      commit('SET_TAREA_APLICACION_INSERT', tarea_aplicacion)
    },
    async TAREA_APLICACION_UPDATE({ commit }, tarea_aplicacion) {
      commit('SET_TAREA_APLICACION_INSERT', tarea_aplicacion)
    },
  },
  getters: {
    tareas: state => { return state },
    fecha_hoy: state => { return state.fecha_hoy },
    fecha_consulta: state => { return state.fecha_consulta },
    animales_activos: state => { return state.animales_activos },
    tratamientos_activos: state => { return state.tratamientos_activos },
    tratamientos_activos_por_fechas: state => { return state.tratamientos_activos_por_fechas },
    tratamientos_activos_x_id: state => { return state.tratamientos_activos_x_id },
  }
}