<template>
  <div v-if="puede_cm_veterinarios"
  class="out d-flex justify-content-center align-items-center mb-3">
    <div class="in">
      <h3>{{ tituloAccion }}</h3>

      <select class="form-control custom-select mb-2" v-model="formu.id_cv" id="cv">
        <option value="0" class="text-muted bg-light">Ninguno</option>
        <option
          v-for="(cv, index) in centros_vet"
          :value="cv.id_cv"
          :key="index"
        >
          {{ cv.nombre_cv }}
        </option>
      </select>
      <div :class="validacion.nombre_vet.css">
        <input type="text" v-model="formu.nombre_vet" class="form-control" />
        <div 
          class="alert alert-danger alert-dismissible fade show" role="alert"
          v-if="validacion.nombre_vet.errMsg"
          >{{validacion.nombre_vet.errMsg}}
          
          </div>
          
        </div>


      <div>
        <b-button
          squared
          class="me-2 mt-3 w-auto"
          size="sm"
          type="submit"
          variant="primary"
          @click="onSubmit()"
          >Guardar</b-button
        >
        &nbsp;
        <b-button
          squared
          class="me-2 mt-3 w-auto"
          size="sm"
          @click="enviarCerrar()"
          variant="secondary"
          >Cancelar</b-button
        >
        
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: baseMapGetters, mapActions: baseMapActions } =
  createNamespacedHelpers("base");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");

export default {
  name: "VisitaVetForm",
  data() {
    return {
      show: true,
      form_enviado: false,
      formu: {
        id_cv: 0,
        id_vet: 0,
        nombre_vet: "",
      },
      validacion:{
        nombre_vet:{
          class: '',
          errMsg: ''
        }   
      },
    };
  },
  props: {
    id_cv_prop: { default: 0 },
    id_vet_prop: { default: 0 },
    nombre_vet_prop: { default: "" },
    accion_form_prop: { default: "insert" }, // insert, modify
  },
  computed: {
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...userMapGetters(['puede_cm_visitas','puede_cm_veterinarios']),
    ...baseMapGetters({ centros_vet: "centros_vet" }),
    ...baseMapGetters({ veterinarios: "veterinarios" }),
    tituloAccion() {
      return this.accion_form_prop == "insert"
        ? "Agregar veterinario"
        : "Modificar : " + this.formu.nombre_vet;
    },
  },
  created() {
    this.cargarFormuDeProp();
    //console.log(this.accion_form_prop);
  },
  methods: {
    ...baseMapActions(["VET_INSERT", "VET_UPDATE"]),
    formValidacion(){
      let pasa = true;
      let nombre_vet = this.formu.nombre_vet.trim()
      let id_cv = this.formu.id_cv
      if(nombre_vet.length < 3 ){
        this.validacion.nombre_vet.css='fieldError'
        this.validacion.nombre_vet.errMsg='Indica un nombre de al menos 3 caracteres'
        pasa = false
      }
      this.veterinarios.forEach(element => {
        if(
          element.id_cv == id_cv &&
          element.nombre_vet.toLowerCase() == nombre_vet.toLowerCase()
          ){
            let nombre_cv = '';
            let index = 
                this.centros_vet
                  .findIndex(v => v.id_cv === id_cv);
            if(index > -1){
              nombre_cv = this.centros_vet[index].nombre_cv
            }

            this.validacion.nombre_vet.css='fieldError'
            let errMsg = nombre_vet + ' ya existe'
            if(nombre_cv) errMsg += ' para ' + nombre_cv
            this.validacion.nombre_vet.errMsg= errMsg
            pasa = false
          }
      });
      return pasa
    },
    onSubmit() {
      if(this.formValidacion()){
        this.form_enviado = true;
        this.$http
          .post(
            this.$bambuvet + "/vet-post.php",
            JSON.stringify(this.formu),
            {emulateJSON: true, withCredentials: true}
          )
          .then((res) => {
            //console.log(res.body);
            if (this.accion_form_prop == "insert") {
              this.VET_INSERT(res.body);
            } else {
              this.VET_UPDATE(res.body);
            }
            this.enviarIdNuevo(res.body.id_cv, res.body.id_vet);
            this.enviarCerrar()
          });
      }
    },
    enviarCerrar() {
      this.$emit("cerrar");
    },
    enviarIdNuevo(id_cv, id) {
      this.$emit("nuevo_id", id_cv, id);
    },
    cargarFormuDeProp() {
      if (this.id_vet_prop && this.accion_form_prop == "update") {
        this.formu.id_vet = this.id_vet_prop;
        let nombre = this.veterinarios.find(
          (item) => item.id_vet === this.id_vet_prop
        ).nombre_vet;
        this.formu.nombre_vet = nombre;
      }

      this.formu.id_cv = this.id_cv_prop;
      if (
        this.id_cv_prop &&
        this.id_vet_prop &&
        this.accion_form_prop == "update"
      ) {
        this.formu.id_vet = this.id_vet_prop;

        let nombre_vet = this.veterinarios.find(
          (item) => item.id_vet === this.id_vet_prop
        ).nombre_vet;
        this.formu.nombre_vet = nombre_vet;
      }
    },
  },
  watch: {
    veterinarios: function () {
      //this.cargarActuacionesTiposOrdenadas();
    },
  },
};
</script>

<style>
</style>