<template>
  <div v-if="bussy_prop" class="d-flex justify-content-center align-items-center mb-3" id="bussy">
      <b-icon  variant="info" icon="arrow-clockwise" animation="spin" font-scale="5"></b-icon>
    </div>
</template>
<script>
export default {
    name: 'Loading',
    props:{bussy_prop: { default: true }}
}
</script>

<style>

</style>