import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import LogOut from '../views/LogOut.vue'
import Animal from '../views/Animal.vue'
import Historial from '../views/Historial.vue'
import Agenda from '../views/Agenda.vue'
//import Papelera from '../views/Papelera.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogOut
  },
  {
    path: '/animales',
    name: 'Animales',
    //component: Animales,
    children:[],
    component: () => import(/* webpackChunkName: "animales" */ '../views/Animales.vue')
  },
  {
    path: '/animales/1',
    name: 'Animales',
    //component: Animales,
    children:[],
    component: () => import(/* webpackChunkName: "animales" */ '../views/Animales.vue')
  },
  {
    path: '/animales/:p',
    name: 'Animales',
    //component: Animales,
    props: true ,
    component: () => import(/* webpackChunkName: "animales" */ '../views/Animales.vue')
  },
  {
    path: '/animales-buscar/:s',
    name: 'Animales buscar',
    //component: Animales,
    props: true ,
    component: () => import(/* webpackChunkName: "animales-buscar" */ '../views/Animales.vue')
  },
  {
    path: '/animales-buscar/:p/:s',
    name: 'Animales buscar',
    //component: Animales,
    props: true ,
    component: () => import(/* webpackChunkName: "animales-buscar" */ '../views/Animales.vue')
  },
  { 
    path: '/animales/:nombre/:id', 
    name: 'animal', 
    component: Animal, 
    props: true 
  },
  { 
    path: '/animales-nuevo', 
    name: 'Animal nuevo', 
    component: Animal, 
    //props: true 
    props: { nombre: 'Nuevo', id: '0', es_nuevo_prop: true }
  },
  {
    path: '/tareas',
    name: 'Tareas',
    //component: Tareas 
    component: () => import(/* webpackChunkName: "tareas" */ '../views/Tareas.vue')
  },
  {
    path: '/tareas/:fecha',
    name: 'Tareas por fecha',
    //component: Tareas 
    component: () => import(/* webpackChunkName: "tareas" */ '../views/Tareas.vue')
  },
  {
    path: '/historial/:id_med',
    name: 'Historial',
    component: Historial 
  },
  {
    path: '/animales/:nombre/:id_animal/historial',
    name: 'Historial completo',
    component: Historial 
  },
  {
    path: '/animales/:nombre/:id_animal/historial/visitas',
    name: 'Historial visitas',
    component: Historial 
  },
  {
    path: '/animales/:nombre/:id_animal/historial/visitas/:id_visita',
    name: 'Historial visita',
    component: Historial 
  },
  {
    path: '/animales/:nombre/:id_animal/historial/tratamientos',
    name: 'Historial Tratamientos',
    component: Historial 
  },
  {
    path: '/animales/:nombre/:id_animal/historial/tratamientos/:id_med',
    name: 'Historial Tratamiento', 
    component: Historial 
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: Agenda 
  },
  {
    path: '/agenda/:semana',
    name: 'Agenda semana',
    component: Agenda 
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "usuarios" */ '../views/Usuarios.vue')
  },
  {
    path: '/papelera',
    name: 'Papelera',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "usuarios" */ '../views/Papelera.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    //return savedPosition || {top:0}
    //return { top: 0 }
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
