'use strict'

export default {
  namespaced: true,
  state: {
    fecha_hoy: '',
    fecha_consulta: '',
    animales_activos: {},
    tratamientos_activos: {},
    visitas: [],
    actuaciones: [],
    agenda_global: {}
  },
  mutations: {
    SET_AGENDA(state, lista) {
      let slistas = Object.keys(lista)
      slistas.forEach(element => {
        if (element == 'fecha_hoy') {
          state.fecha_hoy = lista[element];
        }
        if (element == 'fecha_consulta') {
          state.fecha_consulta = lista[element];
        }
        if (element == 'animales_activos') {
          state.animales_activos = lista[element];
        }
        if (element == 'tratamientos_activos') {
          state.tratamientos_activos = lista[element];
        }
        if (element == 'visitas') {
          state.visitas = lista[element];
        }
        if (element == 'actuaciones') {
          state.actuaciones = lista[element];
        }
        if (element == 'agenda_global') {
          state.agenda_global = lista[element];
        }
      });
    },
  },
  actions: {
    async GET_AGENDA({ commit }, fecha) {
      let url = this._vm.$bambuvet + `/tareas-semanas`
      if(fecha) url = this._vm.$bambuvet + `/tareas-semanas-${fecha}`
      const res = await fetch(url, {
        method: 'GET',
        credentials: 'include'
      })
      const lista = await res.json()
      commit('SET_AGENDA', lista)
    },
  },
  getters: {
    agenda: state => { return state },
    fecha_hoy: state => { return state.fecha_hoy },
    fecha_consulta: state => { return state.fecha_consulta },
    animales_activos: state => { return state.animales_activos },
    tratamientos_activos: state => { return state.tratamientos_activos },
    visitas: state => { return state.visitas },
    actuaciones: state => { return state.actuaciones },
    agenda_global: state => { return state.agenda_global },
  }
}