<template>
  <div> historial tratamientos lista
      <div v-if="id_animal_prop && historial.listas.tratamientos.length > 0">
        <Tratamiento
            v-for="(trata, index) in historial.listas.tratamientos"
            :key="index"
            :tratamiento_prop="trata"
            :id_visita_prop="trata.id_visita"
            :id_med_prop="trata.id_med"
            :mostrar_edicion_prop="false"
        ></Tratamiento>
      </div>
       
  </div>
</template>

<script>
import Tratamiento from "@/components/Tratamiento";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: historialMapGetters, mapActions: historialMapActions } =
  createNamespacedHelpers("historial");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
export default {
    name: "HistorialTratamientosLista",
    components:{
        Tratamiento
    },
    props:{
        id_animal_prop: {default: 0}
    },
    methods: {
    ...historialMapActions(["GET_HISTORIAL_TRATAMIENTOS_LISTA"]),
  },
  created: function () {
    if (this.id_animal_prop) this.GET_HISTORIAL_TRATAMIENTOS_LISTA(this.id_animal_prop);
  },
  computed: {
    ...userMapGetters(["user","user_loged_in","usuario", "nombre_usuario"]),
    ...historialMapGetters(["historial"]),
    ...historialMapGetters(["nombre_animal"]),
  },
  watch:{
      
  }
}
</script>

<style>

</style>