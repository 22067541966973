<template>
  <div v-if="puede_cm_centros_vet"
    class="
      out
      d-flex
      justify-content-center
      align-items-center
      mb-3
    "
  >
    <div class="in">
      <h3>{{tituloAccion}}</h3>

     <div :class="validacion.nombre_cv.css">
        <input type="text" v-model="formu.nombre_cv" class="form-control" />
 <div 
          class="alert alert-danger alert-dismissible fade show" role="alert"
          v-if="validacion.nombre_cv.errMsg"
          >{{validacion.nombre_cv.errMsg}}
          
          </div>
          
      </div>


        <div>
          <b-button
            squared
            class="mr-2 mt-3 w-auto"
            size="sm"
            type="submit"
            variant="primary"
            @click="onSubmit()"
            >Guardar</b-button
          >
          &nbsp;
          <b-button
            squared
            class="mr-2 mt-3 w-auto"
            size="sm"
            @click="enviarCerrar()"
            variant="secondary"
            >Cancelar</b-button
          >
        </div>
     
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: baseMapGetters, mapActions: baseMapActions } = createNamespacedHelpers("base");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
export default {
  name: "VisitaCentroVetForm",
  data() {
    return {
      show: true,
      form_enviado: false,
      formu: {
        id_cv: 0,
        nombre_cv: "",
      },
      validacion:{
        nombre_cv:{
          class: '',
          errMsg: ''
        }   
      },
    };
  },
  props: {
    id_cv_prop: { default: 0 },
    accion_form_prop: { default: "insert" }, // insert, modify
  },
  computed: {
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...userMapGetters(['puede_cm_visitas','puede_cm_centros_vet']),
    ...baseMapGetters({ centros_vet: "centros_vet" }),
    ...baseMapGetters({ veterinarios: "veterinarios" }),
    tituloAccion(){
      return (this.accion_form_prop=='insert') ? 'Agregar centro veterinario' : 'Modificar : '+ this.formu.nombre_cv
    },
  },
  created(){
    this.cargarFormuDeProp();
    //console.log(this.accion_form_prop)
  },
  methods: {
    ...baseMapActions(["CENTRO_VET_INSERT", "CENTRO_VET_UPDATE"]),
    formValidacion(){
      let pasa = true;
      let nombre_cv = this.formu.nombre_cv.trim()
      if(nombre_cv.length < 3 ){
        this.validacion.nombre_cv.css='fieldError'
        this.validacion.nombre_cv.errMsg='Indica un nombre de al menos 3 caracteres'
        pasa = false
      }
      this.centros_vet.forEach(element => {
        if(element.nombre_cv.toLowerCase() == nombre_cv.toLowerCase()){
          this.validacion.nombre_cv.css='fieldError'
          this.validacion.nombre_cv.errMsg=nombre_cv + ' ya existe'
          pasa = false
        }
      });
      return pasa
    },
    onSubmit(){
      if(this.formValidacion()){
       this.form_enviado = true
        this.$http
          .post(
            this.$bambuvet + "/centro-vet-post.php",
            JSON.stringify(this.formu),
            {emulateJSON: true, withCredentials: true}
          )
          .then((res) => {
            //console.log(res.body);
            if(this.accion_form_prop=='insert'){
              this.CENTRO_VET_INSERT(res.body)
            }
            else{
              this.CENTRO_VET_UPDATE(res.body)
            }
            this.enviarIdNuevo(res.body.id_cv)
            this.enviarCerrar()
          });
      }
    },
    enviarCerrar() {
      this.$emit("cerrar");
    },
    enviarIdNuevo(id){
      this.$emit("nuevo_id", id);
    },
    cargarFormuDeProp() {
      if (this.id_cv_prop && this.accion_form_prop=='update') {
        this.formu.id_cv=this.id_cv_prop
        let nombre = this.centros_vet.find(item => item.id_cv === this.id_cv_prop).nombre_cv        
        this.formu.nombre_cv= nombre
      }
    },
  },
  watch: {
    centros_vet: function(){
      this.cargarActuacionesTiposOrdenadas();
    }
  },
};
</script>

<style>
</style>