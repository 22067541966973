<template>
  <div>
    <div class="pb-1 border-bottom">
      Visita {{ visita_prop.tipo_visita }}
        <span v-if="centroVet">
          en <strong>{{ centroVet.nombre_cv }}</strong>
        </span>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: baseMapGetters } = createNamespacedHelpers("base");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
export default {
  name: "AgendaAnimalActivoVisita",
  props: {
    visita_prop: { default: false },
    nombre_animal_prop: { default: false },
    foto_prop: { default: false },
    fecha_prop:  { default: false },
  },
  computed:{
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...baseMapGetters(["centros_vet"]),
    centroVet(){
      if(this.centros_vet){
        return this.centros_vet.find(
          (cv) => cv.id_cv == this.visita_prop.id_cv
        )
      }
      else return false
    }
  }
};
</script>

<style>
</style>