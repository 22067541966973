<template>
  <div class="home">
    
    
    <h1 class="mt-3 mb-3">{{ usuario.entidad  }}</h1>
    <h2>¡Hola {{ nombre_usuario}}!</h2>
    <UltimaActividad
      :user_loged_in_prop="user_loged_in"
    ></UltimaActividad>
     

  </div> 
</template>

<script>
// @ is an alias to /src
import { mapGetters, createNamespacedHelpers } from 'vuex'
const {mapGetters: userMapGetters} = createNamespacedHelpers('user')
const { 
  mapGetters: animalesMapGetters, 
  mapActions: animalesMapActions 
  } = createNamespacedHelpers('animales')
import UltimaActividad from "@/components/UltimaActividad";
export default {
  name: 'Home',
  components: {
    UltimaActividad
  },
  mounted(){
    //console.log(this.$store.state)
    if(!this.user_loged_in){
        this.$router.push({ name: 'Login'})
      }
      document.title = 'Inicio';
  },
  computed:{

    ...mapGetters(["url_site_local"]), 
    ...userMapGetters(['user_loged_in','usuario','nombre_usuario',]),
    ...animalesMapGetters([
      'animales'
    ])
  },
  methods:{
    /*...mapActions({
      GET_USERNAME: 'user/GET_USERNAME'
    })*//*
    ...userMapActions([
      'GET_USERNAME'
    ]),
    ...usersMapActions([
      'GET_USERS'
    ]),*/
    ...animalesMapActions([
      'GET_ANIMALES'
    ])
  },
  watch:{
    user_loged_in: function(){
     // if(this.user_loged_in) this.GET_ANIMALES();
    }
  }
  
}
</script> 
<style lang="scss" scoped>
  .home{min-height: 450px;}
</style>