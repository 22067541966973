'use strict'

export default {
  namespaced: true,
  state: {
    BussyLoading: true,
    actividad: false
  },
  mutations: {
    SET_ULTIMA_ACTIVIDAD(state, lista) {
      state.actividad = lista
      state.BussyLoading = false
    },
    SET_BUSSYLOADING(state, estado) {
      state.BussyLoading = estado
    }
  },
  actions: {
    async GET_ULTIMA_ACTIVIDAD({ commit }) {
      commit('SET_BUSSYLOADING', true)
        const res = await fetch(this._vm.$bambuvet + `/ultimos.php`, {
        method: 'GET',
        credentials: 'include'
      })
      const lista = await res.json()
      commit('SET_ULTIMA_ACTIVIDAD', lista)
 
    },
  },
  getters: {
    actividad: state => { return state.actividad },
    BussyLoading: state => { return state.BussyLoading },
  }
}