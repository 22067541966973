<template>
  <div id="historial">
    <b-container id="historial">
      <Breadcrumb v-if="nombre_animal" :items_prop="itemsBreadcrumb"></Breadcrumb>
      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="flex-shrink-0 me-sm-3 order-1 oeder-sm-0">
          <router-link v-if="id_animal && nombre_animal"
                :to="{
                  name: 'animal',
                  params: { nombre: nombre_animal, id: id_animal },
                }"
              >
          <img
           
            v-bind:src="src_imagen"
          />
          </router-link>
        </div>
        <div class="flex-grow-1 ms-sm-3 order-0 order-sm-1">
          <h1 class="h3">Historial de {{ historial.nombre_animal }}</h1>
        </div>
      </div> 

      <div v-if="accion == 'Historial completo'">
        <div v-for="(item, index) in historial.fechas" :key="index">
          <div v-for="(item2, index2) in item" :key="index2">
            <Tratamiento
              v-if="item2.tipo == 'tratamiento'"
              :tratamiento_prop="
                obtenerObjeto(item2.id, 'id_med', 'tratamientos')
              "
              :id_animal_prop="id_animal"
              :id_med_prop="item2.id_med"
              :id_visita_prop="item2.id_visita"
              :mostrar_edicion_prop="false"
            ></Tratamiento>

            <visita
              v-if="item2.tipo == 'visita'"
              :visita_prop="obtenerObjeto(item2.id, 'id_visita', 'visitas')"
              :id_animal_prop="id_animal"
              :id_visita_prop="item2.id"
              :agregar_visita_prop="false"
              :mostrar_actuaciones_prop="false"
              :mostrar_tratamientos_prop="false"
              :mostrar_edicion_prop="false"
              :mostrar_agregar_tratamiento_prop="false"
              :mostrar_agregar_doc_prop="false"
            >
            </visita>

            <Actuacion
              v-if="item2.tipo == 'actuacion'"
              :actuacion_prop="
                obtenerObjeto(item2.id, 'id_ac_rel', 'actuaciones')
              "
            ></Actuacion>
          </div>
        </div>
      </div>

      <HistorialVisitasLista
        v-if="accion == 'Historial visitas'"
        :id_animal_prop="id_animal"
      ></HistorialVisitasLista>

      <Visita
        v-if="id_visita && accion == 'Historial visita'"
        :visita_prop="obtenerObjeto(id_visita, 'id_visita', 'visitas')"
        :id_animal_prop="id_animal"
        :id_visita_prop="id_visita"
        :agregar_visita_prop="false"
        :mostrar_actuaciones_prop="true"
        :mostrar_tratamientos_prop="true"
        :mostrar_edicion_prop="false" 
        :mostrar_edicion_tratamiento_prop="false" 
        :mostrar_agregar_actuacion_prop="false"
        :mostrar_agregar_tratamiento_prop="false"
        :mostrar_edicion_actuacion_prop="false"
        :mostrar_agregar_doc_prop="false"
      ></Visita>
      
      <HistorialTratamiento
        v-if="id_med && accion == 'Historial Tratamiento'"
        :id_med_prop="id_med"
        :ver_tratamiento_prop="false"
      ></HistorialTratamiento>

      <HistorialTratamientosLista 
        v-if="accion == 'Historial Tratamientos'"
        :id_animal_prop="id_animal"
      ></HistorialTratamientosLista>
      <Loading :bussy_prop="bussy"></Loading>
    </b-container>
  </div>
</template>

<script>
import Actuacion from "@/components/Actuacion";
import Visita from "@/components/Visita";
import HistorialVisitasLista from "@/components/HistorialVisitasLista";
import Tratamiento from "@/components/Tratamiento";
import HistorialTratamiento from "@/components/HistorialTratamiento";
import HistorialTratamientosLista from "@/components/HistorialTratamientosLista";
import Loading from "@/components/Loading";
import { mapGetters,createNamespacedHelpers } from "vuex";
const { mapGetters: historialMapGetters, mapActions: historialMapActions } =
  createNamespacedHelpers("historial");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
const { mapGetters: animalMapGetters, mapActions: animalMapActions } =
  createNamespacedHelpers("animal"); 
import Breadcrumb from "@/components/Breadcrumb";
export default {
  name: "Historial",
  data() {
    return {
      id_med: 0,
      id_animal: 0,
      id_visita: 0,
      accion: "Historial completo",
      doc_title: "Historial",
    };
  },
  components: {
    HistorialTratamiento,
    HistorialTratamientosLista,
    HistorialVisitasLista,
    Tratamiento,
    Visita,
    Actuacion,
    Loading,
    Breadcrumb,
  },
  props: {},
  computed: {
    ...userMapGetters(["user"]),
    ...userMapGetters(['user_loged_in','usuario','nombre_usuario',]),
    ...historialMapGetters(["historial"]),
    ...historialMapGetters(["nombre_animal"]),
    ...historialMapGetters(["bussy"]),
    ...animalMapGetters(["animal"]),
    ...mapGetters(["url_site_local", "url_foto_default"]),
    src_imagen() {
      if (this.animal.id_foto != "0") {
        let path =
          this.usuario.url_web + "/imas/animales/" + "_" + this.animal.id + "/";
        return path + "thumbnail_square_mini.jpg?" + this.animal.id_foto;
      } else return this.url_foto_default;
    },
    docTitle() {
      if (this.nombre_animal)
        return this.doc_title + " de " + this.nombre_animal;
      else return this.doc_title;
    },
    routerOk(){
      return this.$route.params
    },
    itemsBreadcrumb() {
      var items = [
        {
          text: 'Animales',
          to: "Animales",
        },
        {
          text: this.nombre_animal,
          to: "animal",
          params: { nombre: this.nombre_animal, id: this.id_animal },
        },
        {
          text: "Historial",
          to: "Historial completo",
          params: { id: this.id_animal },
        },
      ];
      let ob_tratamientos = {
        text: "Tratamientos",
        to: "Historial Tratamientos",
        params: { id: this.id_animal },
      };
      let ob_tratamiento = {
        text: "Tratamiento",
        to: "Historial Tratamiento",
        params: { id: this.id_animal, id_med: this.id_med },
      };
      let ob_visitas = {
        text: "Visitas",
        to: "Historial visitas",
        params: { id: this.id_animal },
      };
      let ob_visita = {
        text: "Visita",
        to: "Historial visita",
        params: { id: this.id_animal, id_visita: this.id_visita },
      };

      if (this.accion == "Historial Tratamientos") {
        items.push(ob_tratamientos);
      }
      if (this.accion == "Historial Tratamiento") {
        items.push(ob_tratamientos, ob_tratamiento);
      }

      if (this.accion == "Historial visitas") {
        items.push(ob_visitas);
      }
      if (this.accion == "Historial visita") {
        items.push(ob_visitas, ob_visita);
      }
      return items;
    },
  },
  methods: {
    ...historialMapActions(["GET_HISTORIAL_COMPLETO"]),
    ...animalMapActions(["GET_ANIMAL"]),
    actualizarRoutes() {
      this.id_med = this.$route.params.id_med;
      this.id_animal = this.$route.params.id_animal;
      this.id_visita = this.$route.params.id_visita;
      this.accion = this.$route.name;
      //console.log(this.$route.params.id_med);
    },
    obtenerObjeto(id, nombre_id, lista) {
      let index = this.historial.listas[lista].findIndex(
        (item) => item[nombre_id] === id
      );
      if (index > -1) return this.historial.listas[lista][index];
    },
  },
  created: function () {
    this.actualizarRoutes();
    //document.title = this.$route.meta.title;
    document.title = this.docTitle;
  },
  mounted() {
    if (this.id_animal){
      if(this.historial.id_animal != this.id_animal){
        this.GET_HISTORIAL_COMPLETO(this.id_animal);
      }
      if(this.id_animal && this.animal.id!=this.id){
        this.GET_ANIMAL(this.id_animal);
      }
    } 
  },
  watch: {
    $route: function () {
      this.actualizarRoutes();
    },
  },
};
</script>

<style lang="scss" scoped>
#historial {
  text-align: left;
}
.nav-animales {
  color: #42b983 !important;
}
</style>