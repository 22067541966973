<template>
  <div>
   <b-container class="semana">
    
    <div v-if="fechas_semana_prop">
      <AgendaDia
        v-for="(fecha, index) in fechas_semana_prop" :key="index"
        :animales_activos_dia_prop = "fecha.animales"
        :agenda_dia_prop = "fecha.agenda"
        :dia_semana_prop = "fecha.dia_semana"
        :fecha_prop = "index"
      ></AgendaDia>
    </div>
   </b-container>
  </div>
</template>

<script>

import AgendaDia from "@/components/AgendaDia";
export default {
  name: "AgendaSemana",
  components: {
    AgendaDia,
  },
  props:{
    fechas_semana_prop: { default : false}
  }
};
</script>

<style>

</style>