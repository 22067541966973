<template>
  <div>
    <TratamientoForm
      v-if="animal_cargado && agregar_tratamiento && puede_cm_tratamientos"
      :id_animal_prop="id_animal"
      :id_visita_prop="id_visita_prop"
      :agregar_tratamiento_prop="agregar_tratamiento"
      v-on:cerrar="agregar_tratamiento = false"
    ></TratamientoForm>





    <b-row class="mt-2" align-v="end">
      <b-col class="d-flex">
        <h3 class="h5"  v-if="!carga_en_visita_prop">Tratamientos</h3>
      </b-col>
      <b-col cols="auto" v-if="puede_cm_tratamientos && mostrar_agregar_tratamiento_prop">
        <b-button
          size="sm"
          variant="outline-info"
          class="mb-2 cian"
          :pressed.sync="agregar_tratamiento"
          ><b-icon-plus-square-fill font-scale="1"></b-icon-plus-square-fill>
          Agregar tratamiento</b-button
        >
      </b-col>
    </b-row>
    <div 
      v-if="base_animal_cargado 
          && tratamientosLista.length > 0
          && mostrar_tratamientos_prop
          ">
      <Tratamiento
        v-for="(trata, id_med) in tratamientosLista.slice().reverse()"
        :key="id_med"
        :tratamiento_prop="trata"
        :id_animal_prop="trata.id_animal"
        :id_med_prop="trata.id_med"
        :id_visita_prop="trata.id_visita"
        :mostrar_agregar_tratamiento_prop="mostrar_agregar_tratamiento_prop"
        :mostrar_edicion_tratamiento_prop="mostrar_edicion_tratamiento_prop"
      ></Tratamiento>
    </div>
    <div v-else>
      <p v-if="!carga_en_visita_prop">No se encontraron tratamientos.</p>
    </div>
    
  </div>
</template>

<script>
import Tratamiento from "@/components/Tratamiento";
import TratamientoForm from "@/components/TratamientoForm";

import { createNamespacedHelpers } from "vuex";
const { mapGetters: animalMapGetters } = createNamespacedHelpers("animal");
const { mapGetters: historialMapGetters } = createNamespacedHelpers("historial");
const { mapGetters: baseMapGetters } = createNamespacedHelpers("base");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");

export default {
  name: "TratamientosLista",
  components: {
    Tratamiento,
    TratamientoForm
  },
  props:{
    id_visita_prop: { default: 0 },
    mostrar_agregar_tratamiento_prop: { default: true },
    mostrar_edicion_tratamiento_prop: { default: true },
    mostrar_tratamientos_prop: { default: true }, 
    carga_en_visita_prop: { default: false },
  },
  data() {
    return {
      agregar_tratamiento: false,
    };
  },
  computed: {
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    ...userMapGetters(['puede_cm_tratamientos']),
    ...baseMapGetters({ base_animal_cargado: "base_animal_cargado" }),
    ...animalMapGetters( {animal_cargado: "animal_cargado"}),
    ...animalMapGetters( {id_animal: "id_animal"}),
    //...animalMapGetters({ tratamientos: "tratamientos" }),
    ...historialMapGetters(["historial"]),
    tratamientosLista(){
      if (this.base_animal_cargado && this.id_visita_prop) {
        return this.historial.listas.tratamientos.filter(
          (obj) => obj.id_visita == this.id_visita_prop
        );
      }
      else return this.historial.listas.tratamientos;
    },
  },
};
</script>