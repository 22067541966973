<template>
  <div>
    <div v-if="animales_activos_dia_prop">
      <h2 class="h5">{{ dia_semana_prop }}</h2>
      <div class="row d-flex justify-content-center">
        <div class=" col-sm-6 col-md-auto col-leg-auto mb-2" 
          v-for="(animal, index) in animales_activos_dia_prop" 
          :key="index">
          <AgendaAnimalActivo
            :id_animal_prop="animal"
            :agenda_prop="agenda_dia_prop"
            :dia_semana_prop="dia_semana_prop"
            :fecha_prop="fecha_prop"
          ></AgendaAnimalActivo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgendaAnimalActivo from "@/components/AgendaAnimalActivo";
export default {
  name: "AgendaDia",
  components: {
    AgendaAnimalActivo,
  },
  props: {
    animales_activos_dia_prop: { fefault: false },
    agenda_dia_prop: { default: false },
    dia_semana_prop: { default: false },
    fecha_prop: { default: false },
  },
};
</script>

<style>
.semana{text-align: left !important;}
</style>