<template>
  <div>
    <form @submit.prevent="upload" method="post" enctype="multipart/form-data">
      <div class="mb-3">
        <label for="formFile" class="form-label"
          >Selecciona un archivo</label
        >
        <input
          class="form-control"
          type="file"
          id="bv_file"
          name="bv_file"
          accept="application/pdf, image/jpeg"
          v-on:change="onChange($event)"
        />
      </div>
      <div class="form-floating mb-4">
        <textarea
          class="form-control"
          placeholder="Descripción"
          id="descripcion"
          name="descripcion_doc"
          v-model="descripcion_doc"
        ></textarea>
        <label for="descripcion">Descripción</label>
      </div>

      <button class="btn btn-info" :disabled="button_inactivo" type="submit">Enviar archivo</button>
    </form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions: animalMapActions } =
  createNamespacedHelpers("animal");
export default {
  name: "FileUpload",
  data() {
    return {
      bv_file: {},
      result: "",
      descripcion_doc: '',
      button_inactivo: true,
    };
  },
  props: {
    id_animal_prop: { default: false },
    id_visita_prop: { default: 0 },
  },
  methods: {
    onChange(event) {
      this.bv_file = event.target.files[0];
      this.button_inactivo = false;
      //console.log(this.bv_file);
    },
    upload() {
     
      var formData = new FormData();
      formData.append("bv_file", this.bv_file);
      formData.append("id_animal", this.id_animal_prop);
      formData.append("id_visita", this.id_visita_prop);
      formData.append("descripcion_doc", this.descripcion_doc);
      // specify Content-Type, with formData as well
      this.$http
        .post(this.$bambuvet + "/file-upload.php", formData, {
          emulateJSON: true,
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          //console.log(res.body);
          if(res){
            this.DOC_INSERT(res.body)
            this.$emit('cerrar')
          }
        });
    },
    ...animalMapActions([
      "DOC_INSERT",
    ]),
  },
};
</script>

