var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"visita"},[_c('div',{staticClass:"bg-light p-3 border border-info mb-3"},[_c('div',{staticClass:"row d-flex justify-content-between"},[_c('div',{staticClass:"col-auto ps-1"},[_c('h4',{class:[_vm.claseRealizada, 'h6']},[(_vm.visita_prop.estado == 'realizada')?_c('b-icon',{attrs:{"icon":"check"}}):_c('b-icon',{staticClass:"text-danger me-1",attrs:{"icon":"calendar3"}}),_c('router-link',{attrs:{"to":{
              name: 'Historial visita',
              params: {
                id_visita: _vm.id_visita,
                id_animal: _vm.id_animal
              },
            }}},[_vm._v(" Visita "+_vm._s(_vm.fechaLocal))]),_vm._v(" [id: "+_vm._s(_vm.id_visita)+"] ")],1)]),(_vm.mostrar_edicion && _vm.puede_cm_visitas)?_c('div',{staticClass:"col-auto align-self-end"},[_c('button',{staticClass:"btn btn-cian btn-sm",on:{"click":function($event){_vm.mostrar_form = true}}},[_c('b-icon-pencil',{attrs:{"font-scale":"1"}})],1)]):_vm._e()]),_c('div',{staticClass:"row bg-cian ms-0 me-0 p-1 visita-info"},[_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"label"},[_vm._v("Estado:")]),_vm._v(" "+_vm._s(_vm.visita_prop.estado)+" ")]),_c('div',{staticClass:"col-auto"},[_vm._v("Visita: "+_vm._s(_vm.visita_prop.tipo_visita))]),_c('div',{staticClass:"col-auto"},[_vm._v("Fecha: "+_vm._s(_vm.visita_prop.fecha_visita))]),(_vm.nombre_veterinario)?_c('div',{staticClass:"col-auto"},[_vm._v(" Veterinario: "+_vm._s(_vm.nombre_veterinario)+" ")]):_vm._e(),(_vm.nombre_centro_vet)?_c('div',{staticClass:"col-auto"},[_vm._v(" Centro vet.: "+_vm._s(_vm.nombre_centro_vet)+" ")]):_vm._e()]),(_vm.visita_prop.diagnostico)?_c('div',[_vm._m(0),_vm._v(" "+_vm._s(_vm.visita_prop.diagnostico)+" ")]):_vm._e(),(_vm.visita_prop.observaciones_visita)?_c('div',{staticClass:"col-auto"},[_vm._m(1),_vm._v(" "+_vm._s(_vm.visita_prop.observaciones_visita)+" ")]):_vm._e(),(_vm.visita_prop.comentarios)?_c('div',{staticClass:"col-auto"},[_vm._m(2),_vm._v(" "+_vm._s(_vm.visita_prop.comentarios)+" ")]):_vm._e(),(_vm.mostrar_actuaciones)?_c('ActuacionesLista',{attrs:{"actuaciones_realizadas_prop":_vm.actuacionesRealizadasVisita,"id_visita_prop":_vm.visita_prop.id_visita,"carga_en_visita_prop":true,"fecha_visita_prop":_vm.visita_prop.fecha_visita,"mostrar_agregar_actuacion_prop":_vm.mostrar_agregar_actuacion_prop,"mostrar_edicion_actuacion_prop":_vm.mostrar_edicion_actuacion_prop}}):_vm._e(),(
        _vm.mostrar_tratamientos_prop 
      )?_c('div',[_c('TratamientosLista',{attrs:{"id_visita_prop":_vm.id_visita,"carga_en_visita_prop":true,"mostrar_tratamientos_prop":_vm.mostrar_tratamientos_prop,"mostrar_agregar_tratamiento_prop":_vm.mostrar_agregar_tratamiento_prop,"mostrar_edicion_tratamiento_prop":_vm.mostrar_edicion_tratamiento_prop}})],1):_vm._e(),_c('div',[_c('DocsLista',{attrs:{"docs_prop":_vm.docsVisita,"id_animal_prop":_vm.id_animal_prop,"id_visita_prop":_vm.id_visita,"mostrar_agregar_doc_prop":_vm.mostrar_agregar_doc_prop}})],1)],1),(_vm.mostrar_form && _vm.puede_cm_visitas)?_c('VisitaForm',{attrs:{"visita_prop":_vm.visita_prop,"id_animal_prop":_vm.id_animal_prop,"id_visita_prop":_vm.id_visita,"visita_modificar_activa_prop":true,"accion_form_prop":"update","titulo_modal_prop":"Modificar visita"},on:{"cerrar":function($event){_vm.mostrar_form = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"mt-3 h6 mb-3"},[_c('span',{staticClass:"bg-cian p-1"},[_vm._v("Diagnóstico")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"mt-3 h6 mb-3"},[_c('span',{staticClass:"bg-cian p-1"},[_vm._v("Observaciones")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"mt-3 h6 mb-3"},[_c('span',{staticClass:"bg-cian p-1"},[_vm._v("Comentarios")])])}]

export { render, staticRenderFns }