var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"d-sm-flex justify-content-between"},[_c('Breadcrumb',{attrs:{"items_prop":_vm.itemsBreadcrumb}}),_c('nav',{staticClass:"nav  justify-content-center"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":'/Agenda'}},[_vm._v(" Presente ")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{
      name: 'Agenda semana',
      params: {
        semana: 'proxima semana',
      },
    }}},[_vm._v(" Próxima ")]),_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
      name: 'Agenda semana',
      params: {
        semana: 'semana pasada',
      },
    }}},[_vm._v(" Pasada ")])],1)],1),_c('h1',[_vm._v(_vm._s(_vm.TituloAMostrar))]),_c('AgendaSemana',{attrs:{"fechas_semana_prop":_vm.SemanaAMostrar}}),_c('div')],1)}
var staticRenderFns = []

export { render, staticRenderFns }