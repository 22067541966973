<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li v-for="(item, index) in itemsLista" :key="index" class="breadcrumb-item">
          <router-link :to="{ name: item.to, params:item.params }" :is="isLast(index) ? 'span' : 'router-link'">{{ item.text}}</router-link>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      items: [
        {
          text: "Inicio",
          to: "Home",
          params: {},
        },
      ],
    };
  },
  props: { items_prop: { default: false } },
  computed:{
    itemsLista(){
      let items_lista = JSON.parse(JSON.stringify(this.items));
      this.items_prop.forEach(element => {
          items_lista.push(element);
        });
        //console.log('lista:' + items_lista.length)
      return items_lista
    },
  },
  methods:{
    isLast(index){
      return this.itemsLista.length == (index+1) ? true : false
    }
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb a {
	color: #42b983 !important;
  a {
	color: #42b983 !important;
  }
}
</style>