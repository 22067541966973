<template>
  <b-container>
    <div class="login pb-5">
      <h1 class="mt-5">Bambú-vet</h1>
      <form @submit.prevent="login">
        <h2 class="h4 text-cian" v-if="usuario.entidad">
          {{ usuario.entidad }}
        </h2>
        <div v-else class="mb-3">
          <label for="login_name" class="form-label">Entidad</label>
          <input
            type="text"
            class="form-control"
            id="login_name"
            v-model="formu.login_name"
            required
          />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">E-mail</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="formu.email"
            required
          />
        </div>
        <div class="mb-3">
          <label for="psswrd" class="form-label">Password</label>
          <input
            type="password"
            class="form-control"
            id="psswrd"
            v-model="formu.password_form"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary">Entrar</button>
      </form>
      
      <div class="mt-5" v-if="usuario.login_name">
        <router-link to="/logout">Salir de sesión de {{ usuario.entidad }}</router-link>
      </div>

    </div>
  </b-container>
</template>

<script>
import { mapGetters,  createNamespacedHelpers } from "vuex";
const { mapGetters: userMapGetters, mapActions: userMapActions } =
  createNamespacedHelpers("user");
export default {
  name: "Login",
  data() {
    return {
      formu: {
        email: "",
        password_form: "",
        login_name: "",
      },
    };
  },
  methods: {
    ...userMapActions(["LOGIN_USER"]),
    login() {
      //console.log(JSON.stringify(this.formu));
      this.$http
        .post(
          this.$bambuvet + "/auth_user.php",
          JSON.stringify(this.formu),
          {
            emulateJSON: true, 
            withCredentials: true
          }
        )
        .then((res) => {
          //console.log(res.body);
          if (res.body) {
             // console.log('sesion iniciada')
             // console.log("ctoken: " + res.body.user.ctoken);
             // this.GET_SESION_INICIADA()
              this.LOGIN_USER(res.body);
          }
        });
    },
  },
  computed: {
    ...mapGetters(["url_site_local"]), 
    ...userMapGetters(["nombre_usuario"]),
    ...userMapGetters(["usuario"]),
    ...userMapGetters(["user_loged_in"]),
  },
  mounted() {
    document.title = "Login";
    this.formu.login_name = this.usuario.login_name;
    //this.GET_SESION_INICIADA()
    if (this.user_loged_in) this.$router.push({ name: "Home" });
  },
  watch: {
    usuario: function () {
      this.formu.login_name = this.usuario.login_name;
      this.formu.session_id = this.usuario.session_id;
    },
    user_loged_in: function () {
      if (this.user_loged_in){
        localStorage.user_loged_in = 1;
        localStorage.login_name = this.usuario.login_name;
        localStorage.ctoken = this.usuario.ctoken;
        this.$router.push({ name: "Home" });
      } 
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  min-height: 600px;
  padding-top: 1rem;
  form {
    text-align: left;
    max-width: 400px;
    margin: auto;
  }
}
</style>