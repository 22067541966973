<template>
  <div>
    <h3 class="h5">historial del tratamiento</h3>
    <Tratamiento
        :tratamiento_prop="historial.tratamiento"
        :id_animal_prop="historial.id_animal"
        :id_med_prop="historial.id_med"
        :id_visita_prop="historial.id_visita"
        :ver_tratamiento_prop="false" 
        :mostrar_edicion_prop="false"
    ></Tratamiento>

    
    <TareaAplicacion
      v-for="(item, index) in historial.listas.aplicaciones"
      :key="index"
      :aplicacion_tratamiento_prop="item"
      :nombre_animal_prop="historial.nombre_animal"
      :src_foto_prop="historial.src_foto_mini"
      :nombre_tratamiento_prop="historial.tratamiento.tratamiento"
      :posologia_prop="historial.tratamiento.posologia"
    >
    </TareaAplicacion>
 
  </div>
</template>

<script>
import TareaAplicacion from "@/components/TareaAplicacion";
import Tratamiento from "@/components/Tratamiento";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: historialMapGetters, mapActions: historialMapActions } =
  createNamespacedHelpers("historial");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");

export default {
  name: "HistorialTratamiento",
  data() {
    return {};
  },
  components: {
    TareaAplicacion,
    Tratamiento
  },
  props: {
    id_med_prop: { defaul: false },
    ver_tratamiento_prop:  { defaul: true },
  },
  methods: {
    ...historialMapActions(["GET_HISTORIAL_TRATAMIENTO", "SET_NOMBRE_ANIMAL"]),
  },
  mounted: function () {
    if (this.id_med_prop) this.GET_HISTORIAL_TRATAMIENTO(this.id_med_prop);
  },
  computed: {
    ...userMapGetters(["user","user_loged_in","usuario", "nombre_usuario"]),
    ...historialMapGetters(["historial"]),
    ...historialMapGetters(["nombre_animal"]),
  },
  watch:{
    nombre_animal: function(){
      this.SET_NOMBRE_ANIMAL(this.nombre_animal)
    }
  }
};
</script>

<style>
</style>