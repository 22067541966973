'use strict'

export default {
  namespaced: true,
  state: {
    /* id_usuario: '1',
     nombre_usuario: 'Helena',
     url_site_rem: 'http://bambu.hp2',
     dir_animales_rem: '/imas/animales/'
    user: false,*/
    nombre_usuario: '',
    url_site_rem: '',
    dir_animales_rem: '',
    usuario: {
      nombre_usuario: '',
      user_loged_in: false,
      login_name: '',
      url_web: '',
      permisos: [],
    },
    es_admin : false,
    cm_usuarios: false, 
    ver_tareas: false,
    aplicar_tareas: false,
    cm_animales: false,
    cm_tratamientos: false,
    cm_visitas: false,
    cm_actuaciones: false,
    cm_actuaciones_tipos: false,
    cm_actuaciones_nombres: false,
    cm_centros_vet: false,
    cm_veterinarios: false,
    upload_docs: false,
  },
  mutations: {
    SET_USER(state, user) {
      state.usuario = user
      //console.log('auth-user: ' + user.user)
      //console.log('auth-user: ' + user)
      state.es_admin = user.es_admin;
      state.cm_usuarios = user.permisos.indexOf('1') > -1 ? true: false;
      state.cm_animales = user.permisos.indexOf('2') > -1 ? true: false;
      state.cm_tratamientos = user.permisos.indexOf('3') > -1 ? true: false;
      state.cm_visitas = user.permisos.indexOf('4') > -1 ? true: false;
      state.cm_actuaciones = user.permisos.indexOf('5') > -1 ? true: false;
      state.cm_actuaciones_tipos = user.permisos.indexOf('6') > -1 ? true: false;
      state.cm_actuaciones_nombres = user.permisos.indexOf('7') > -1 ? true: false;
      state.cm_centros_vet = user.permisos.indexOf('8') > -1 ? true: false;
      state.cm_veterinarios = user.permisos.indexOf('9') > -1 ? true: false;
      state.ver_tareas = user.permisos.indexOf('10') > -1 ? true: false;
      state.aplicar_tareas = user.permisos.indexOf('11') > -1 ? true: false;
      state.upload_docs = user.permisos.indexOf('12') > -1 ? true: false;
    }
  },
  actions: {
    async GET_USER({ commit }) {
      const res = await fetch(this._vm.$bambuvet + `/auth_user.php`, {
        method: 'GET',
        credentials: 'include'
      })
      const { user } = await res.json()
      //console.log('auth-user: ' + user)
      commit('SET_USER', user)
    },
    LOGIN_USER({ commit }, user) {
      commit('SET_USER', user.user)
    },
    async LOG_OUT({ commit }) {
      const res = await fetch(this._vm.$bambuvet + `/auth_logout.php`, {
        method: 'GET',
        credentials: 'include'
      })
      let { user } = await res.json()
      //console.log('loged out: ' + user)
      commit('SET_USER', user)
    },
  },
  getters: {
    user: state => { return state },
    usuario: state => { return state.usuario },
    user_loged_in: state => { return state.usuario.user_loged_in },
    id_usuario: state => { return state.id_usuario },
    nombre_usuario: state => { return state.usuario.nombre_usuario },
    url_site_rem: state => { return state.url_site_rem },
    url_web: state => { return state.usuario.url_web },
    es_admin: state => { return state.es_admin },
    puede_cm_usuarios: state => { return state.cm_usuarios },
    puede_cm_animales: state => { return state.cm_animales },
    puede_cm_tratamientos: state => { return state.cm_tratamientos },
    puede_cm_visitas: state => { return state.cm_visitas },
    puede_cm_actuaciones: state => { return state.cm_actuaciones },
    puede_cm_actuaciones_tipos: state => { return state.cm_actuaciones_tipos },
    puede_cm_actuaciones_nombres: state => { return state.cm_actuaciones_nombres },
    puede_cm_centros_vet: state => { return state.cm_centros_vet },
    puede_cm_veterinarios: state => { return state.cm_veterinarios },
    puede_ver_tareas: state => { return state.ver_tareas },
    puede_aplicar_tareas: state => { return state.aplicar_tareas },
    puede_upload_docs: state => { return state.upload_docs },
    sistema_archivos: state => { return state.usuario.sistema_archivos },
  }

}